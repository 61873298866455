import React from 'react';
import { Link } from 'react-router-dom';

interface SidebarToggleProps {
  toggleSidebar: () => void;
}

const SidebarToggle: React.FC<SidebarToggleProps> = ({ toggleSidebar }) => (
  <Link onClick={toggleSidebar} id="mobile_btn" to="#">
    <span className="bar-icon">
      <span />
      <span />
      <span />
    </span>
  </Link>
);

export default SidebarToggle;