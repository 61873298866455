import { useState } from "react";
import { useForm } from "../../hooks/utils/useForm";
import { apiRepository } from "../../../api/apiRepository";
import { openModal } from "../../utils/modalsUtils";
import { handleError } from "../../utils/errorUtils";
import { validateCode } from "../../form/validationForm";
import { ValidationError } from "../../form/validationForm";
import { UseValidateCodeProps } from "../../Interface/modalInterface";

export const useValidateCode = ({ email }: UseValidateCodeProps) => {
    const initialFormData = { email, code: "" };
    const { formData, handleChange, resetForm: originalResetForm } = useForm(initialFormData);
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState<ValidationError[]>([]);

    const resetForm = () => {
        originalResetForm();
        setServerError([]);
    };

    const handleValidateCode = async (event: React.FormEvent) => {
        event.preventDefault();
        setServerError([]);

        if (!formData) return;

        const validationErrors = validateCode({ email: email, code: formData.code });
        if (validationErrors.length > 0) {
            setServerError(validationErrors);
            return;
        }

        try {
            setLoading(true);
            const response = await apiRepository.forgotValidateCode({ email: email, code: formData.code });
            const { success } = response.data;

            if (success) {
                openModal("reset-password");
            }
        } catch (error: any) {
            handleError(error, setServerError);
        } finally {
            setLoading(false);
        }
    };

    return { formData, handleChange, resetForm, handleValidateCode, loading, serverError };
};
