import { useState } from "react";
import { useForm } from "../../hooks/utils/useForm";
import { apiRepository } from "../../../api/apiRepository";
import { openModal } from "../../utils/modalsUtils";
import { handleError } from "../../utils/errorUtils";
import { validateResetPassword } from "../../form/validationForm";
import { ValidationError } from "../../form/validationForm";
import { ResetPasswordModalProps } from "../../Interface/modalInterface";
import { useLogin } from "./useLogin";

export const useResetPassword = ({ modalId, email, authLoginContext }: ResetPasswordModalProps) => {
    const initialFormData = { email, password: "", confirmPassword: "" };
    const { formData, handleChange, resetForm: originalResetForm } = useForm(initialFormData);
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState<ValidationError[]>([]);
    const { handleLogin } = useLogin(authLoginContext, modalId || "");

    const resetForm = () => {
        originalResetForm();
        setServerError([]);
    };

    const handleResetPassword = async (event: React.FormEvent) => {
        event.preventDefault();
        setServerError([]);
        if (!formData) return;

        const validationErrors = validateResetPassword(formData);
        if (validationErrors.length > 0) {
            setServerError(validationErrors);
            return;
        }

        try {
            setLoading(true);
            const response = await apiRepository.forgotUpdatePassword({
                email: email,
                password: formData.password,
            });
            const { success } = response.data;

            if (success) {
                await handleLogin(event, { email: email, password: formData.password });
                openModal("success-modal");
            }
        } catch (error: any) {
            handleError(error, setServerError);
        } finally {
            setLoading(false);
        }
    };

    return { formData, handleChange, resetForm, handleResetPassword, loading, serverError };
};