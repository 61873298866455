import { useCallback } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { convertDateToLocalDateString } from '../../utils/dateUtils';
import { FetchSchedulesParams } from '../../Interface/hooksInterface';

export const useFetchSchedules = ({ setData, setTotalRecords, setLoading, dateRange, classId, trainerId, currentPage, rows }: FetchSchedulesParams) => {
    const fetchSchedules = useCallback(async (pageNumber = currentPage, pageSize = rows) => {
        try {
            const [startDate, endDate] = dateRange;
            setLoading(true);
            const response = await apiRepository.getSchedules(
                pageNumber,
                pageSize,
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
                classId,
                trainerId
            );
            setData(response.data.data.content);
            setTotalRecords(response.data.data.totalElements);
        } catch (error) {
            console.error('Error al obtener los datos de horarios:', error);
        } finally {
            setLoading(false);
        }
    }, [dateRange, classId, trainerId, currentPage, rows, setData, setTotalRecords, setLoading]);

    return { fetchSchedules };
};
