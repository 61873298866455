import React, { useEffect, useState, useContext } from 'react';
import { formatTimeTo12Hour, formatDateToFull, capitalizeFirstLetter } from '../../../../core/utils/utils'
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';
import ConfirmSuccesClassModal from './succes-class-modal';
import { AuthContext } from '../../../../core/context/AuthContext';
import moment from 'moment-timezone';
import { ConfirmClassModalProps } from '../../../../core/Interface/modalInterface';
import ErrorClassModal from './error-class-modal';
import { openModal } from '../../../../core/utils/modalsUtils';

const ConfirmClassModal: React.FC<ConfirmClassModalProps> = ({ selectedClassData, onConfirmSuccess }) => {
    const [percentage, setPercentage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState<string | null>(null);
    const [progressBarColor, setProgressBarColor] = useState('bg-success');
    const [classData, setClassData] = useState({
        id: -1,
        title: '',
        date: '',
        hourStart: '',
        hourEnd: '',
        quantity: '',
        reservation: '',
        trainerName: '',
        classDescription: '',
    });
    const authContext = useContext(AuthContext);
    if (!authContext) {
        return <div>Error: Auth context not found!</div>;
    }

    const { user } = authContext;

    useEffect(() => {
        if (selectedClassData) {
            const totalSeats = parseInt(selectedClassData.quantity);
            const reservedSeats = parseInt(selectedClassData.reservation);
            const newPercentage = totalSeats > 0 ? (reservedSeats / totalSeats) * 100 : 0;

            setClassData({
                id: selectedClassData.id,
                title: selectedClassData.title,
                date: selectedClassData.date,
                hourStart: selectedClassData.hourStart,
                hourEnd: selectedClassData.hourEnd,
                quantity: selectedClassData.quantity,
                classId: selectedClassData.classId,
                reservation: selectedClassData.reservation,
                trainerName: selectedClassData.trainerName,
                classDescription: selectedClassData.classDescription,
            });

            setPercentage(newPercentage);

            if (newPercentage >= 50 && newPercentage < 80) {
                setProgressBarColor('bg-warning');
            } else if (newPercentage >= 80) {
                setProgressBarColor('bg-danger');
            } else {
                setProgressBarColor('bg-success');
            }
        }
    }, [selectedClassData]);

    const resetFields = () => {
        setServerError('');
        setProgressBarColor('bg-success');
    };

    useEffect(() => {
        const addModalElement = document.getElementById('confirm-class');
        addModalElement?.addEventListener('hide.bs.modal', resetFields);
        return () => {
            addModalElement?.removeEventListener('hide.bs.modal', resetFields);
        };
    }, []);

    const handleConfirmClass = async (event: React.FormEvent) => {
        event.preventDefault();
        
        setLoading(true);
        setServerError('');
        
        try {
            if (!user?.userId) {
                setServerError('No se ha encontrado el identificador del usuario.');
                return;
            }
            
            if (classData.id === -1) {
                setServerError('Clase no válida.');
                return;
            }
            
            const classDateTime = moment.tz(`${classData.date}T${classData.hourStart}`, 'America/Lima');
            const now = moment.tz('America/Lima');
            
            if (classDateTime.isBefore(now)) {
                setServerError('No puedes inscribirte en una clase pasada.');
                return;
            }
            
            const reservationData = {
                scheduleId: classData.id,
                customerId: parseInt(user?.userId),
            };

            await apiRepository.createReservation(reservationData);
            openModal('confirm-succes');

            if (onConfirmSuccess) onConfirmSuccess();

        } catch (error: any) {
            let errorMessage = error.response?.data?.message;
            if (errorMessage.includes('Error interno del servidor')) {
                errorMessage = errorMessage.replace('Error interno del servidor:', '').trim();
            }
            setTimeout(() => {
                setServerError(errorMessage);
            }, 0);
            openModal('error-class');
        } finally {
            setLoading(false);
        }
    };


    return (
        <div>
            <div className="modal fade" id="confirm-class">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Confirmar Clase</h5>
                            <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            />
                        </div>
                        <div className="modal-body pt-0">
                            <div className="text-center">
                                <Icon.CheckCircle size={45} color='#28a745' className="text-success fs-1" />
                                <div className="mt-4">
                                    <h4>{classData.title}</h4>
                                    {classData.date && (
                                        <p className="mt-2 mb-0">
                                            <strong>Fecha:</strong> {capitalizeFirstLetter(formatDateToFull(classData.date))} <br />
                                            <strong>Entrenador:</strong> {classData.trainerName || 'No asignado'} <br />
                                            <strong>Horario:</strong> {formatTimeTo12Hour(classData.hourStart)} - {formatTimeTo12Hour(classData.hourEnd)} <br />
                                            <strong>Cupos Reservados:</strong> {classData.reservation || 0} de {classData.quantity}
                                        </p>
                                    )}
                                    <div className="">
                                        <div className="progress mt-3" style={{ height: '20px' }}>
                                            <div
                                                className={`progress-bar ${progressBarColor}`}
                                                role="progressbar"
                                                style={{ width: `${percentage || 0}%` }}
                                                aria-valuenow={percentage}
                                                aria-valuemin={0}
                                                aria-valuemax={100}
                                            >
                                                {percentage ? `${percentage.toFixed(0)}%` : "0%"}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4">
                                <button
                                    type="button"
                                    className="d-none"
                                    data-bs-toggle="modal"
                                    data-bs-target="#confirm-succes"
                                >
                                </button>
                                <button
                                    type="button"
                                    className="d-none"
                                    data-bs-toggle="modal"
                                    data-bs-target="#error-class"
                                >
                                </button>
                                <button
                                    type="button"
                                    className="btn w-sm btn-secondary"
                                    data-bs-dismiss="modal"
                                >
                                    Cancelar
                                </button>
                                <button
                                    type="button"
                                    className="btn w-sm btn-success"
                                    onClick={handleConfirmClass}
                                >
                                    {loading ? 'Reservando...' : 'Sí, Confirmar Clase'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmSuccesClassModal message="¡Ya tiene una reserva en la clase!" />
            <ErrorClassModal message={serverError} />
        </div>
    );
}

export default ConfirmClassModal;
