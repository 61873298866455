import React, { useEffect, useState } from 'react';
import { ValidationError, validateEnterpriseInformation, validateSecretKeys } from '../../../core/form/validationForm';
import { apiRepository } from '../../../api/apiRepository';
import { EnterpriseInformation as EnterpriseInterface } from '../../../core/Interface/repositoryInterfaces';
import { ImageUploadField, InputField, SwitchField } from '../common/input/inputField';
import { handleChange } from '../../../core/utils/utils';
import { getErrorMessage } from '../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../core/services/formService';
import { decryptResponse, encryptRequest } from '../../../core/utils/encryptUtils';

const EnterpriseInformation = () => {
  const [enterpriseData, setEnterpriseData] = useState<EnterpriseInterface>([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [updatedSecretKeyData, setUpdatedSecretKeyData] = useState<any>([]);
  const [loadingEnterprise, setLoadingEnterprise] = useState(false);
  const [loadingSecretKey, setLoadingSecretKey] = useState(false);
  const [enterpriseSuccess, setEnterpriseSuccess] = useState<string | null>(null);
  const [secretKeySuccess, setSecretKeySuccess] = useState<string | null>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    handleChange(e, setEnterpriseData);
  };

  const fetchEnterpriseInformation = async () => {
    try {
      const response = await apiRepository.getEnterpriseInformation();
      setEnterpriseData(response.data.data);
    } catch (error) {
      console.error("Error al obtener los datos de la empresa:", error);
    }
  };

  const fetchSecretKeyInformation = async () => {
    try {
      const response = await apiRepository.getSecretKeyInformation();
      const encryptedData = response.data.data;
      const secretKey = "encryption-key";
      const decryptedData = decryptResponse(encryptedData, secretKey);
      setUpdatedSecretKeyData(decryptedData);
    } catch (error) {
      console.error("Error al obtener la clave secreta:", error);
    }
  };

  useEffect(() => {
    fetchEnterpriseInformation();
    fetchSecretKeyInformation();
  }, []);

  const handleUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoadingEnterprise(true);
    setErrors([]);
    setEnterpriseSuccess(null);

    const errors = await handleFormSubmit(
      1,
      enterpriseData,
      validateEnterpriseInformation,
      {
        create: apiRepository.updateEnterpriseInformation,
        update: apiRepository.updateEnterpriseInformation,
      },
      () => {
        fetchEnterpriseInformation();
        setEnterpriseSuccess("¡Información actualizada con éxito!");
      },
      (data) => ({
        ...data,
        file: data.image,
      }),
      "Ocurrió un error al intentar guardar la información de la empresa. Inténtalo de nuevo.",
      true
    );

    if (errors?.length > 0) {
      setErrors(errors);
    }

    setLoadingEnterprise(false);
  };

  const handleSecretKeyUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoadingSecretKey(true);
    setErrors([]);
    setSecretKeySuccess(null);

    try {
      const errors = validateSecretKeys(updatedSecretKeyData);
      if (errors.length > 0) {
        setErrors(errors);
        setLoadingSecretKey(false);
        return;
      }

      const secretKey = "encryption-key";
      const encryptedData = encryptRequest(
        Object.fromEntries(
          Object.entries(updatedSecretKeyData).map(([key, value]) => [
            key, 
            typeof value === 'string' ? value.trim() : value
          ])
        ),
        secretKey
      );

      await apiRepository.updateSecretKeyInformation(1, encryptedData);
      fetchSecretKeyInformation();
      setSecretKeySuccess("¡Clave secreta actualizada con éxito!");
    } catch (error) {
      setErrors([{ id: '1', field: 'error', message: 'Ocurrió un error al intentar guardar la clave secreta.' }]);
    }

    setLoadingSecretKey(false);
  };

  const handleSecretKeyInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    handleChange(e, setUpdatedSecretKeyData);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setEnterpriseData({ ...enterpriseData, image: file });
  };

  const handleRemoveImage = () => {
    setEnterpriseData({ ...enterpriseData, image: null });
    const fileInput = document.getElementById("imgInp") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-11 col-sm-12 m-auto">
            <div className="card mb-4">
              <div className="card-header">
                <h5>Información de la Empresa</h5>
              </div>
              <div className="card-body">
                <form onSubmit={handleUpdate}>
                  <div className="row">
                    <div className="col-md-6">
                      <ImageUploadField
                        label="Logo de la empresa"
                        formData={enterpriseData}
                        onFileChange={handleFileChange}
                        onRemoveImage={handleRemoveImage}
                        routeName="enterprise"
                        recommendedSize="500 x 500 píxeles"
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Facebook"
                        name="facebook"
                        required={false}
                        value={enterpriseData.facebook || ''}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'facebook')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Instagram"
                        name="instagram"
                        required={false}
                        value={enterpriseData.instagram || ''}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'instagram')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="YouTube"
                        name="youtube"
                        required={false}
                        value={enterpriseData.youtube || ''}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'youtube')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Teléfono"
                        name="phone"
                        value={enterpriseData.phone || ''}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'phone')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Correo Electrónico"
                        name="email"
                        required={false}
                        value={enterpriseData.email || ''}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'email')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Whatsapp"
                        name="whatsapp"
                        required={false}
                        value={enterpriseData.whatsapp || ''}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'whatsapp')}
                      />
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-end mt-3">
                      <button
                        style={{ maxHeight: '40px' }}
                        className="btn btn-primary-admin mb-3"
                        type="submit"
                      >
                        {loadingEnterprise ? (
                          <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Guardando...
                          </>
                        ) : (
                          'Guardar cambios'
                        )}
                      </button>
                    </div>
                  </div>
                </form>

                {enterpriseSuccess && (
                  <div className="alert alert-success mt-3 mb-3" role="alert">
                    {enterpriseSuccess}
                  </div>
                )}
                {errors?.filter(error => error.field === 'server').length > 0 && (
                  <div className="alert alert-danger mt-3 mb-3" role="alert">
                    {errors.filter(error => error.field === 'server').map((error) => (
                      <p key={error.field}>{error.message}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="card mb-4">
              <div className="card-header">
                <h5>Credenciales de Mercado Pago</h5>
                <small className="text-muted">Configura las credenciales de tu cuenta de Mercado Pago para procesar pagos</small>
              </div>
              <div className="card-body">
                <form onSubmit={handleSecretKeyUpdate}>
                  <div className="row">
                    <div className="col-md-6">
                      <InputField
                        label="Usuario de Mercado Pago (Desarrollo)"
                        name="devUser"
                        value={updatedSecretKeyData.devUser || ''}
                        onChange={handleSecretKeyInputChange}
                        error={getErrorMessage(errors, 'devUser')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Access Token de Mercado Pago (Desarrollo)"
                        name="devToken"
                        value={updatedSecretKeyData.devToken || ''}
                        onChange={handleSecretKeyInputChange}
                        error={getErrorMessage(errors, 'devToken')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Usuario de Mercado Pago (Producción)"
                        name="prodUser"
                        value={updatedSecretKeyData.prodUser || ''}
                        onChange={handleSecretKeyInputChange}
                        error={getErrorMessage(errors, 'prodUser')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Access Token de Mercado Pago (Producción)"
                        name="prodToken"
                        value={updatedSecretKeyData.prodToken || ''}
                        onChange={handleSecretKeyInputChange}
                        error={getErrorMessage(errors, 'prodToken')}
                      />
                    </div>
                    <div className="col-md-6">
                      <SwitchField
                        label="¿Usar credenciales de desarrollo?"
                        name="isDev"
                        checked={updatedSecretKeyData.isDev || ''}
                        onChange={handleSecretKeyInputChange}
                        className="mb-0 mt-1 me-2"
                      />
                      <small className="text-muted d-block mt-1">
                        Activa esta opción para usar el ambiente de pruebas de Mercado Pago
                      </small>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end align-items-end mt-3">
                      <button
                        style={{ maxHeight: '40px' }}
                        className="btn btn-primary-admin mb-3"
                        type="submit"
                      >
                        {loadingSecretKey ? (
                          <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                            Guardando credenciales...
                          </>
                        ) : (
                          'Actualizar credenciales'
                        )}
                      </button>
                    </div>
                  </div>
                </form>
                {secretKeySuccess && (
                  <div className="alert alert-success mt-3 mb-3" role="alert">
                    {secretKeySuccess}
                  </div>
                )}

                {errors?.filter(error => error.field === 'error').length > 0 && (
                  <div className="alert alert-danger mt-3 mb-3" role="alert">
                    {errors.filter(error => error.field === 'error').map((error) => (
                      <p key={error.field}>{error.message}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseInformation;