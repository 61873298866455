import React, { useEffect } from 'react';
import Slider from "react-slick";
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { getCart } from '../../../../core/services/cartService';
import { Package } from '../../../../core/Interface/repositoryInterfaces';
import { ProductCarouselProps } from '../../../../core/Interface/interfaceComponents';
import { getProductCarouselConfig } from '../../../../core/data/config/carouselConfig';

const ProductCarousel: React.FC<ProductCarouselProps> = ({ products, cartContext, authContext }) => {
  const { user } = authContext;
  const { cart, addToCart, setCart, removeFromCart } = cartContext;
  const slidesToShowCount = products.length >= 4 ? 4 : products.length;
  const carouselConfig = getProductCarouselConfig(slidesToShowCount);

  useEffect(() => {
    const currentCart = getCart(user?.userId);
    setCart(currentCart);
  }, [user?.userId]);

  const handleRemoveFromCart = (productId: number) => {
    removeFromCart(productId, setCart);
  };

  const handleAddToCart = (productId: number) => {
    addToCart(productId, setCart);
  };

  const renderPackage = (product: Package, index: number) => (
    <div className="providerset col-md-4" key={index}>
      <div className="providerset-img providerset-img-seven d-flex justify-content-center product-image">
        {product.image && (
          <ImageWithBaseApi routeName="packet" src={product.image} alt={product.title} />
        )}
      </div>
      <div className="providerset-content providerset-content-seven">
        <div className="providerset-price">
          <div className="providerset-name">
            <h4>
              {product.title}
            </h4>
            <span className="text-primary">{product.description}</span>
          </div>
        </div>
        <div className="provider-rating-seven">
          <div className="providerset-prices">
            <h6>
              S/ {product.price}
            </h6>
          </div>
          {cart.some((item: Package) => item.id === product.id) ? (
            (() => {
              const productInCart = cart.find((item: Package) => item.id === product.id);
              return (
                <div className="d-flex align-items-center">
                  <button
                    className="btn btn-light me-2"
                    onClick={() => handleRemoveFromCart(product.id)}
                  >
                    <i className="fa fa-minus" />
                  </button>
                  <button className="btn btn-light me-2">
                    {productInCart?.quantity}
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => handleAddToCart(product.id)}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </div>
              );
            })()
          ) : (
            <button
              className="btn btn-primary-user"
              onClick={() => handleAddToCart(product.id)}
            >
              Agregar al Carrito
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className="row aos">
      <div className="col-md-12">
        <Slider {...carouselConfig} className="product-service">
          {products.map(renderPackage)}
        </Slider>
      </div>
    </div>
  );
};

export default ProductCarousel;
