import { useReducer, useEffect, useCallback } from 'react';
import { bannerReducer } from '../../reducers/bannerReducer';
import { BannerState } from '../../Interface/state';
import { apiRepository } from '../../../api/apiRepository';
import { Banners } from '../../Interface/repositoryInterfaces';

const initialBannerState: BannerState = { activeBanners: [], isLoading: true };

export const useBanners = (filterByActive = true, format = false): { banners: Banners[], isLoading: boolean, setBanners: (banners: Banners[]) => void, refetch: () => Promise<void>, handleFilterChange: (filter: boolean) => void } => {
  const [state, dispatch] = useReducer(bannerReducer, initialBannerState);

  const formatBanners = (banners: Banners[]) => {
    return banners
      .map((banner) => ({
        ...banner,
        id: banner.id.toString(),
        image: banner.image || '',
        status: banner.status,
        upload: true,
      }))
      .sort((a, b) => a.position - b.position);
  };

  const getBanners = (banners: Banners[]) => {
    const filtered = filterByActive
      ? banners.filter((banner) => banner.status)
      : banners;
    return format ? formatBanners(filtered) : filtered;
  };

  const filteredBanners = getBanners(state.activeBanners);

  const setBanners = useCallback((banners: Banners[]) => {
    dispatch({ type: 'SET_BANNERS', payload: banners });
  }, []);

  const refetch = useCallback(async () => {
    dispatch({ type: 'FETCH_START' });
    try {
      const response = await apiRepository.getBanners();
      dispatch({ type: 'FETCH_SUCCESS', payload: response.data.data });
    } catch (error) {
      console.error('Error refetching banners:', error);
      dispatch({ type: 'FETCH_ERROR' });
    }
  }, []);

  const handleFilterChange = useCallback((filter: boolean) => {
    dispatch({ type: 'SET_FILTER', payload: filter });
  }, []);

  useEffect(() => {
    refetch();
  }, [refetch]);

  return {
    banners: filteredBanners,
    isLoading: state.isLoading,
    setBanners,
    refetch,
    handleFilterChange,
  };
};