import React, { useEffect, useState } from 'react';
import DefaultEditor from 'react-simple-wysiwyg';
import { validatePromotion, ValidationError } from '../../../core/form/validationForm';
import { apiRepository } from '../../../api/apiRepository';
import { PromotionSection } from '../../../core/Interface/repositoryInterfaces';
import { ImageUploadField, InputField } from '../common/input/inputField';
import { handleChange } from '../../../core/utils/utils';
import { getErrorMessage } from '../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../core/services/formService'
import { usePromotionSection } from '../../../core/hooks/getData/usePromotionSection';

const EditPromotionSection = () => {
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState<number>();
  const { promotionSectionData, setPromotionSectionData, refetch, handleInputChange } = usePromotionSection();

  const handleUpdate = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = true
    setLoading(true);
    setErrors([]);

    const errors = await handleFormSubmit(
      1,
      promotionSectionData,
      validatePromotion,
      {
        create: apiRepository.updatePromotionSection,
        update: apiRepository.updatePromotionSection,
      },
      () => {
        refetch();
      },
      (data) => ({
        ...data,
        file: data.image,
      }),
      "Ocurrió un error al intentar guardar el contenido. Inténtalo de nuevo.",
      formData
    );

    if (errors?.length > 0) {
      setErrors(errors);
    } else {
      const info = []
      info.push({ field: "success", message: "¡Contenido actualizado con exito!" })
      setErrors(info);
    }

    setLoading(false);
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setPromotionSectionData({ ...promotionSectionData, image: file });
  };

  const handleRemoveImage = () => {
    setPromotionSectionData({ ...promotionSectionData, image: null });
    const fileInput = document.getElementById("imgInp") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="row">
          <div className="col-lg-11 col-sm-12 m-auto">
            <div className="content-page-header">
              <h3>Editar sección de promoción</h3>
            </div>
            <div className="row">
              <form onSubmit={handleUpdate}>

                <div className="col-md-6">
                  <ImageUploadField
                    label="Imagen principal para la sección de promoción"
                    formData={promotionSectionData}
                    onFileChange={handleFileChange}
                    onRemoveImage={handleRemoveImage}
                    required={true}
                    routeName="homesection"
                    recommendedSize="600 x 600 píxeles"
                    error={getErrorMessage(errors, 'image')}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Link"
                    name="link"
                    required={false}
                    value={promotionSectionData?.link || ''}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, 'link')}
                  />
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Información <span className="text-danger"> *</span></label>
                    <div id="editor">
                      <DefaultEditor
                        value={promotionSectionData?.description || ''}
                        name="description"
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.find((value) => value.field === 'description') && (
                      <small className="text-danger">{errors.find((value) => value.field === 'description')?.message}</small>
                    )}
                  </div>
                  {errors.find((value) => value.field === 'success') && (
                    <div className="alert alert-success mt-3 mb-3" role="alert">
                      {errors.find((value) => value.field === 'success')?.message}
                    </div>
                  )}
                  {errors.find((value) => value.field === 'error') && (
                    <div className="alert alert-danger mt-3 mb-3" role="alert">
                      {errors.find((value) => value.field === 'error')?.message}
                    </div>
                  )}
                </div>
                {errors.find(error => error.field === 'server') && (
                  <div className="alert alert-danger" role="alert">
                    {getErrorMessage(errors, "server")}
                  </div>
                )}
                <div className="col-lg-12">
                  <div className="btn-path mb-3">
                    <button
                      className="btn btn-primary-admin"
                      type="submit"
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                          Guardando...
                        </>
                      ) : (
                        'Guardar cambios'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPromotionSection;