import React, { useEffect, useContext } from 'react'
import NavbarLogo from '../../../home/header/navbarLogo';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../../../../core/context/AuthContext';
import { useEnterpriseData } from '../../../../../core/hooks/getData/useEnterpriseData';
import './header.css'; // Add this import

const PagesAuthHeader = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { isAuthenticated } = authContext;
  const navigate = useNavigate();
  const enterpriseData = useEnterpriseData();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <>
      {/* Header */}
      <div className="authentication-header">
        <div className="container">
          <div className="col-md-12">
            <div className="text-center">
              <div className="d-flex justify-content-center align-items-center flex-column">
                <Link to="/" aria-label="Ir al inicio" className="img-fluid auth-logo-wrapper">
                  {enterpriseData?.image && (
                    <>
                      <NavbarLogo imageUrl={String(enterpriseData.image)} />
                    </>
                  )}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Header */}
    </>
  );
}

export default PagesAuthHeader;