import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';

const ListEmail = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const routes = all_routes;

    const fetchEmails = async () => {
        try {
            const response = await apiRepository.getCampaignss();
            setData(response.data.data);
        } catch (error) {
            console.error('Error al obtener los datos de suscripciones:', error);
        }
    };

    useEffect(() => {
        fetchEmails();
    }, []);

    const handleNewEmail = () => {
        navigate(routes.addEmail);
    }


    const columns = [
        { field: 'name', header: 'Nombre' },
        { field: 'subject', header: 'Titulo' },
        { field: 'date', sortable: true, header: 'Fecha de Creación' },
        { field: 'createdAt', sortable: true, header: 'Fecha de Creación', hidden: true },
        {
            field: 'status',
            header: 'Estado del Correo',
            body: (rowData: any) => (
                <span className={`badge ${rowData.status ? 'badge-success' : 'badge-warning'}`}>
                    {rowData.status ? 'Enviado' : 'Pendiente'}
                </span>
            )
        },
    ];

    return (
        <div className="page-wrapper page-settings">
            <div className="content mb-5">
                <div className="content-page-header mb-2 content-page-headersplit">
                    <h5>Correos</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleNewEmail}>
                            <i className="fa fa-plus me-2" /> Agregar Suscripción
                        </button>
                    </div>
                </div>

                <DataTableWrapper
                    data={data || []}
                    columns={columns}
                    paginator
                    showGridlines
                    lazy={false}
                    emptyMessage="No se encontraron suscripciones."
                    sortField="createdAt"
                    sortOrder={-1}
                />
            </div>
        </div>
    );
};

export default ListEmail;