import { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Users } from '../../Interface/repositoryInterfaces';

export const useUsersData = (fullName: string, email: string, profile: string, active: boolean | null, birthdate: Date | null) => {
  const [data, setData] = useState<Users[]>([]);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [totalUsers, setTotalUsers] = useState<number>(-1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);

  const fetchUsers = async (pageNumber = currentPage, pageSize = rows) => {
    try {
      setLoading(true);
      const filters = {
        email: email || '',
        fullName: fullName || '',
        profile: profile || '',
        active: active !== null ? active : '',
        birthdate: birthdate ? birthdate.toISOString().split('T')[0] : '',
      };
      const response = await apiRepository.getUsers(pageNumber, pageSize, filters);
      const totalRecords = response.data.data.totalElements;
      setData(response.data.data.content);
      setTotalUsers(totalRecords);
    } catch (error) {
      console.error("Error al obtener los datos de usuarios:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage, rows, email, fullName, profile, active, birthdate]);

  return {
    data,
    selectedUser,
    totalUsers,
    first,
    rows,
    loading,
    currentPage,
    filtersVisible,
    setSelectedUser,
    setFirst,
    setRows,
    setLoading,
    setCurrentPage,
    setFiltersVisible,
    fetchUsers,
  };
};
