import { useState } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetProfitabilityClassesExelFile = (dateRange?: [Date, Date], customerId?: string, minAge?: string, maxAge?: string) => {
    const [profitabilityClassesExelFile, setProfitabilityClassesExelFile] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchProfitabilityClasses = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getClassProfitabilityReportExcelFile(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
                customerId,
                minAge,
                maxAge
            );
            const profitabilityClassesExelFile = response.data;
            setProfitabilityClassesExelFile(profitabilityClassesExelFile);
            return profitabilityClassesExelFile;
        } catch (err) {
            console.error("Error al obtener los datos de reservas:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        profitabilityClassesExelFile,
        isLoading,
        error,
        refetchProfitabilityClasses: fetchProfitabilityClasses,
    };
};