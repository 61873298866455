import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useDateSchedules = (dateRange: [Date, Date]) => {
  const [schedules, setSchedules] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const fetchSchedules = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const [startDate, endDate] = dateRange;
      const response = await apiRepository.getdateSchedules(
        convertDateToLocalDateString(startDate),
        convertDateToLocalDateString(endDate),
        1
      );
      const schedules = response.data.data;

      setSchedules(schedules);
      setTotalRecords(schedules.length);
    } catch (err) {
      console.error("Error al obtener los datos de horarios:", err);
      setError(err instanceof Error ? err : new Error("Error desconocido"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      fetchSchedules();
    }
  }, [dateRange]);

  return {
    schedules,
    isLoading,
    error,
    totalRecords,
    refetchSchedules: fetchSchedules,
  };
};