import React, { useState, useEffect, useContext } from 'react'
import { all_routes } from '../../../../core/data/routes/all_routes';
import { AuthContext } from '../../../../core/context/AuthContext';
import { useLocation } from 'react-router-dom';
import LoginModal from '../../common/modals/loginModal';
import RegisterModal from '../../common/modals/registerModal';
import ForgotPasswordModal from '../../common/modals/forgotPasswordModal';
import EmailOtpModal from '../../common/modals/emailOtpModal';
import ResetPasswordModal from '../../common/modals/resetPasswordModal';
import SuccessRecoverModal from '../../common/modals/successRecoverModal';
import { openModal } from '../../../../core/utils/modalsUtils';
import { useForgotPassword } from "../../../../core/hooks/auth/useForgotPassword";

const AuthModals = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { login, isAuthenticated } = authContext;
  const location = useLocation();
  const routes = all_routes;
  const [email, setEmail] = useState<string>();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const recover = urlParams.get('recover');

    if (recover === 'true') {
      openModal("forgot-modal");
    }
  }, [location]);

  const { formData, handleChange, resetForm, handleForgotPassword, loading, serverError } =
  useForgotPassword();

  useEffect(() => {
    if (formData?.email && formData?.email !== "") {
      setEmail(formData?.email);
    }
  }, [formData]);

  if (isAuthenticated) {
    return null;
  }

  return (
    <>
      <LoginModal authLoginContext={login} />
      <RegisterModal authLoginContext={login} routes={routes} />
      <ForgotPasswordModal
        formData={formData}
        loading={loading}
        handleChange={handleChange}
        forgotPassword={handleForgotPassword}
        serverError={serverError}
        resetForm={resetForm}
      />
      <EmailOtpModal email={email || ""} />
      <ResetPasswordModal modalId="reset-password" email={email || ""} authLoginContext={login} />
      <SuccessRecoverModal/>
    </>
  )
}

export default AuthModals