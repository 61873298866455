import React, { useState, useEffect } from 'react';
import { Schedules } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import SchedulesModal from '../common/modals/schedules-modal';
import DeleteSchedulesModal from '../common/modals/delete-schedules-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsSchedule';
import { formatTimeTo12Hour } from '../../../core/utils/utils';
import { useNavigate } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import * as Icon from 'react-feather';
import { CalendarRangeField, AutoCompleteDropdown } from '../common/input/inputField';
import { useTrainers } from '../../../core/hooks/getData/useTrainersUsers';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { useSubTypeClasses } from '../../../core/hooks/getData/useSubTypeClasses';
import { useFetchSchedules } from '../../../core/hooks/getData/useFetchSchedules';
import { useFetchTrainers } from '../../../core/hooks/getData/useFetchTrainers';
import { useSearchHandlers } from '../../../core/hooks/utils/useSearchHandlers';
import { useQueryParams } from '../../../core/hooks/utils/useQueryParams';
import { Column } from '../../../core/Interface/interfaceComponents';

const ClassSchedules = () => {
    const [data, setData] = useState<Schedules[]>([]);
    const [selectedScheduleId, setSelectedScheduleId] = useState<number | null>(null);
    const [selectedSchedule, setSelectedSchedule] = useState<Schedules | undefined>(undefined);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [trainers, setTrainers] = useState<any>();
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number | string>(0);
    const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange());
    const [classId, setClassId] = useState<string>('');
    const [trainerId, setTrainerId] = useState<string>('');
    const { subTypeClassesData = [] } = useSubTypeClasses({ shouldOptions: true });
    const { trainersData } = useTrainers({ shouldOptions: true });
    const [classSuggestions, setClassSuggestions] = useState<{ label: string; value: string }[]>([]);
    const [selectedClassLabel, setSelectedClassLabel] = useState<string>('');
    const [trainerSuggestions, setTrainerSuggestions] = useState<{ label: string; value: string }[]>([]);
    const [selectedTrainerLabel, setSelectedTrainerLabel] = useState<string>('');
    const navigate = useNavigate();
    const routes = all_routes;

    const { fetchSchedules } = useFetchSchedules({
        setData,
        setTotalRecords,
        setLoading,
        dateRange,
        classId,
        trainerId,
        currentPage,
        rows
    });

    const { fetchTrainers } = useFetchTrainers({ setTrainers });

    const { handleClassSearch, handleClassChange, handleTrainerSearch, handleTrainerChange } = useSearchHandlers({
        subTypeClassesData,
        trainersData,
        setClassSuggestions,
        setTrainerSuggestions,
        setClassId,
        setTrainerId,
        setSelectedClassLabel,
        setSelectedTrainerLabel
    });

    useQueryParams({
        setDateRange,
        setClassId,
        setTrainerId,
        setSelectedClassLabel,
        setSelectedTrainerLabel,
        setCurrentPage,
        setRows
    });

    useEffect(() => {
        const [startDate, endDate] = dateRange;
        if (startDate && endDate) {
            fetchSchedules();
            fetchTrainers();
        }
    }, [currentPage, dateRange, classId, trainerId]);

    const handleEditClick = (rowData: Schedules) => {
        setSelectedSchedule(undefined);
        setTimeout(() => setSelectedSchedule(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedScheduleId(null);
        setTimeout(() => setSelectedScheduleId(rowData.id), 0);
    };

    const handleCustomActionClick = (rowData: any) => {
        const queryParams = new URLSearchParams({
            id: rowData.id,
            dateRange: dateRange ? JSON.stringify(dateRange.map(date => date.toISOString())) : '',
            classId: classId || '',
            trainerId: trainerId || '',
            selectedClassLabel: selectedClassLabel || '',
            selectedTrainerLabel: selectedTrainerLabel || '',
            currentPage: currentPage !== undefined ? currentPage.toString() : '0',
            rows: rows !== undefined ? rows.toString() : '10'
        });
        navigate(`${routes.classScheduleAssits}?${queryParams.toString()}`);
    };

    const formatReservation = (reservation: number): string => {
        return reservation > 0 ? reservation.toString() : 'Sin reservas';
    };

    const columns: Column[] = [
        { field: "title", header: "Nombre" },
        {
            field: "reservation",
            header: "Reservas",
            body: (rowData: Schedules) => formatReservation(rowData.reservation),
        },
        {
            field: "date",
            header: "Fecha y Horario",
            body: (rowData: Schedules) => {
                return (
                <span>
                    {rowData.date} - {formatTimeTo12Hour(rowData.hourStart)} a {formatTimeTo12Hour(rowData.hourEnd)}
                </span>
                );
            }
        },
        {
        field: "status",
        header: "Estado",
        body: (rowData: Schedules) => {
            let label = '';
            let className = '';

            switch (rowData.status) {
            case 1:
                label = 'Pendiente';
                className = 'badge-pending';
                break;
            case 2:
                label = 'En Atención';
                className = 'badge-active';
                break;
            case 3:
                label = 'Finalizada';
                className = 'badge-default';
                break;
            case 4:
                label = 'Cancelado';
                className = 'badge-delete';
                break;
                case 5:
                label = 'Asistencia finalizada';
                className = 'badge-active';
                break;
            default:
                label = 'Desconocido';
                className = 'badge-default';
            }

            return <span className={className}>{label}</span>;
        }
        },
        { field: "classTypeDescription", header: "Tipo de Clase" },
        { field: "trainerName", header: "Entrenador" },
        { field: "quantity", header: "Limite de Cupos" },
    ];

    const getColumns = useResponsiveColumns(columns);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        const pageNumber = event.first / event.rows;
        setCurrentPage(pageNumber);
        fetchSchedules(pageNumber, event.rows);
    };1

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Horarios</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar Horario
                        </button>
                    </div>
                </div>
                <div className="card card-status mb-3">
                    <div className="card-head-blk border-bottom-0 pb-0">
                        <div className="row align-items-center">
                            <div className="col-md-12 d-flex justify-content-end">
                                <button
                                    className="btn btn-outline-secondary rounded-3 mb-3 me-2"
                                    onClick={() => {
                                        setDateRange(getDefaultMonthRange());
                                        setClassId('');
                                        setTrainerId('');
                                        setSelectedClassLabel('');
                                        setSelectedTrainerLabel('');
                                    }}
                                >
                                    <i className="fa fa-eraser me-2" />
                                    Limpiar
                                </button>
                            </div>
                            <div className="col-xl-12 col-md-12 d-flex">
                                <div className="earning-group gap-3 w-100">
                                    <CalendarRangeField
                                        value={dateRange || [new Date(), new Date()]}
                                        onChange={(e: any) => setDateRange(e.value)}
                                    />
                                    <AutoCompleteDropdown
                                        value={selectedClassLabel}
                                        onChange={handleClassChange}
                                        onSearch={handleClassSearch}
                                        suggestions={classSuggestions}
                                        placeholder="Selecciona un tipo de clase"
                                        className="mb-3"
                                        disabled={false}
                                    />
                                    <AutoCompleteDropdown
                                        value={selectedTrainerLabel}
                                        onChange={handleTrainerChange}
                                        onSearch={handleTrainerSearch}
                                        suggestions={trainerSuggestions}
                                        placeholder="Selecciona un entrenador"
                                        className="mb-3"
                                        disabled={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    onCustomAction={handleCustomActionClick}
                    customIcon={Icon.Eye}
                    loading={loading}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalRecords}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron horarios."
                />
            </div>
            <SchedulesModal selectedSchedule={selectedSchedule} onUpdateSuccess={fetchSchedules} trainerList={trainers} />
            {selectedScheduleId !== null && selectedScheduleId !== undefined && (
                <DeleteSchedulesModal selectedScheduleId={selectedScheduleId} onDeleteSuccess={fetchSchedules} />
            )}
        </div>
    );
};

export default ClassSchedules;