export const systemBackUpData = [
    {
        id: 1,
        fileName: "Sed perspiciatis omni_File.sql",
        date: "27 Sep 2023 10:11AM",
        action: ""
    },
    {
        id: 2,
        fileName: "Sed perspiciatis omni_File.sql",
        date: "27 Sep 2023 10:11AM",
        action: ""
    },
    {
        id: 3,
        fileName: "Sed perspiciatis omni_File.sql",
        date: "27 Sep 2023 10:11AM",
        action: ""
    },
    {
        id: 4,
        fileName: "Sed perspiciatis omni_File.sql",
        date: "27 Sep 2023 10:11AM",
        action: ""
    },
    {
        id: 5,
        fileName: "Sed perspiciatis omni_File.sql",
        date: "27 Sep 2023 10:11AM",
        action: ""
    }
]

