import { useCallback } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { UseFetchTrainersProps } from '../../Interface/hooksInterface';

export const useFetchTrainers = ({ setTrainers }: UseFetchTrainersProps) => {
    const fetchTrainers = useCallback(async () => {
        try {
            const filters = {
                email: '',
                fullName: '',
                profile: 'trainer',
            };
            const response = await apiRepository.getUsers(0, 10, filters);
            const formattedTrainers = response.data.data.content.map(user => ({
                label: `${user.name} ${user.lastname}`,
                value: user.id
            }));
            setTrainers(formattedTrainers);
        } catch (error) {
            console.error('Error al obtener los datos de entrenadores:', error);
        }
    }, [setTrainers]);

    return { fetchTrainers };
};
