import React, { useState, useEffect } from 'react'
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';
import BaseModal from './baseModalDelete';
import { getErrorMessage  } from '../../../../core/utils/errorUtils';
import { ValidationError } from '../../../../core/form/validationForm';
import { DeleteCouponModalProps } from '../../../../core/Interface/modalInterface';

const DeleteCouponsModal: React.FC<DeleteCouponModalProps> = ({ selectedCouponId, onDeleteSuccess }) => {
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ValidationError[]>([]);

    useEffect(() => {
        if (selectedCouponId) {
            setShow(true);
        }
    }, [selectedCouponId]);

    const handleClose = () => {
        setShow(false);
        setErrors([]);
    };

    const handleConfirmDelete = async () => {
        if (selectedCouponId === -1) return;

        try {
            setLoading(true)
            await apiRepository.deleteCoupon(selectedCouponId);

            if (onDeleteSuccess) {
                setShow(false);
                onDeleteSuccess();
            }
        } catch (error) {
            errors.push({ field: "server", message: "Error al eliminar cupón." });
            console.error("Error al eliminar categoría:", error);
        } finally {
            setLoading(false)
        }
    };
    return (
        <>
            <BaseModal
                show={show}
                title="Cupón"
                onClose={handleClose}
                onConfirm={handleConfirmDelete}
                loading={loading}
            >
                <div className="text-center">
                    <Icon.Trash2 size={45} color='#f05050 ' className="text-danger fs-1" />
                    <div className="mt-4">
                        <h4>¿Eliminar Cupón?</h4>
                        <p className="text-muted mb-0">¿Estás seguro de que quieres eliminar este cupón?</p>
                        {errors.find(error => error.field === 'server') && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {getErrorMessage(errors, "server")}
                            </div>
                        )}
                    </div>
                </div>
            </BaseModal>
        </>
    )
}

export default DeleteCouponsModal
