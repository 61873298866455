import React from "react";
import * as Icon from 'react-feather';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { UserAvatarProps } from "../../../../core/Interface/modalInterface";

const UserAvatar: React.FC<UserAvatarProps> = ({ image, onUpdate }) => {
    const isValidImage = image && typeof image === "string";
    return (
        <span
            className="user-avatar mb-2 profile-picture-container"
            onClick={onUpdate}
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
                borderRadius: "50%",
                backgroundColor: !isValidImage ? "#eeeeee" : "transparent",
                overflow: "hidden",
            }}
        >
            {image ? (
                <>
                    <ImageWithBaseApi
                        src={image}
                        routeName="user"
                        className="img-fluid profile-picture"
                        alt="Perfil de Usuario"
                        isExternalLink={false}
                        loadingIcon={
                            <i className="fa fa-spinner fa-spin" style={{ fontSize: '24px', color: '#b0b0b0' }}></i>
                        }
                    />
                    <div className="profile-overlay">
                        <span className="update-text">Actualizar</span>
                    </div>
                </>
            ) : (
                <Icon.Camera size={50} className="react-feather-icon" />
            )}

        </span>
    );
};

export default UserAvatar;