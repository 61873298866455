import { Locations } from "../Interface/repositoryInterfaces";

type ValidationError = { field: string; message: string };

type ApiRepositoryType<T> = {
  create: (data: T) => Promise<void>;
  update: (id: number, data: T) => Promise<void>;
};

export async function handleFormSubmit<T>(
  id: number,
  data: T,
  validate: (data: T) => ValidationError[],
  apiRepository: ApiRepositoryType<T>,
  onSuccess: () => void,
  formatData?: (data: T) => T,
  errorMessage = "Ocurrió un error al intentar guardar los datos. Inténtalo de nuevo.",
  useFormData = false
) {
  const trimmedData = Object.fromEntries(
    Object.entries(data).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value])
  );

  const validationErrors = validate(trimmedData);
  if (validationErrors.length > 0) {
    return validationErrors;
  }

  const formattedData = formatData ? formatData(trimmedData) : trimmedData;

  let dataToSend = formattedData;
  if (useFormData) {
    dataToSend = new FormData();
    for (const key in formattedData) {
      if (Object.prototype.hasOwnProperty.call(formattedData, key)) {
        if (formattedData[key] instanceof File) {
          dataToSend.append(key, formattedData[key]);
        } else {
          dataToSend.append(key, formattedData[key]);
        }
      }
    }
  }

  try {
    if (id == null || id === -1) {
      await apiRepository.create(dataToSend);
    } else {
      await apiRepository.update(id, dataToSend);
    }
    onSuccess();
  } catch (error) {
    const errors: ValidationError[] = [];
    if (error.response && error.response.data && error.response.data.message) {
      const cleanedMessage = error.response.data.message.replace(/^Internal server error: /, '');
      errors.push({ field: "server", message: cleanedMessage });
    } else {
      errors.push({ field: "server", message: errorMessage });
    }
    return errors;
  }
}
export async function getLocationCode(district: any, getLocations: () => Promise<any>) {
  const locations = await getLocations();

  let location = locations.data.data.find((location: Locations) =>
    location.district.toLowerCase() === district.toLowerCase()
  );

  if (!location) {
    const district_formatted = district
      .split(' ')
      .map((word: string) =>
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(' ')
      .replace(/ñ/g, 'Ã±');

    location = locations.data.data.find((location: Locations) =>
      location.district === district_formatted
    );
  }

  return location?.id;
}