import { useState, useEffect } from "react";

export const useProgressiveRender = (delay: number) => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        const timer = setTimeout(() => setIsLoaded(true), delay);
        return () => clearTimeout(timer);
    }, [delay]);

    return isLoaded;
};