export const offer = [
  {
    id: '1',
    img1: 'assets/admin/img/services/service-03.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'Computer Repair',
    user: 'John Smith',
    amount: '$80',
    offer: '25%',
    offerprice: '$80',
    date: '28 Sep 2023',
    enddate: '28 Sep 2023',
  },
  {
    id: '2',
    img1: 'assets/admin/img/services/service-02.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'Car Repair Services',
    user: 'Johnny',
    amount: '$50',
    offer: '10%',
    offerprice: '$80',
    date: '17 Sep 2023',
    enddate: '17 Sep 2023',
  },
  {
    id: '3',
    img1: 'assets/admin/img/services/service-04.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'Steam Car Wash',
    user: 'Robert',
    amount: '$50',
    offerprice: '$80',
    offer: '10%',
    date: '17 Sep 2023',
    enddate: '17 Sep 2023',
  },
  {
    id: '4',
    img1: 'assets/admin/img/services/service-09.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'House Cleaning',
    user: 'Sharonda',
    amount: '$150',
    offerprice: '$80',
    offer: '20%',
    date: '23 Sep 2023',
    enddate: '23 Sep 2023',
  },
  {
    id: '5',
    img1: 'assets/admin/img/services/service-04.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'Computer Repair',
    user: 'John Smith',
    amount: '$80',
    offerprice: '$80',
    offer: '25%',
    date: '03 Sep 2023',
    enddate: '03 Sep 2023',
  },
  {
    id: '6',
    img1: 'assets/admin/img/services/service-09.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'Computer Repair',
    user: 'John Smith',
    amount: '$80',
    offerprice: '$80',
    offer: '25%',
    date: '28 Sep 2023',
    enddate: '28 Sep 2023',
  },
  {
    id: '7',
    img1: 'assets/admin/img/services/service-04.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'Computer Repair',
    user: 'John Smith',
    amount: '$80',
    offerprice: '$80',
    offer: '25%',
    date: '28 Sep 2023',
    enddate: '28 Sep 2023',
  },
  {
    id: '8',
    img1: 'assets/admin/img/services/service-03.jpg',
    img2: 'assets/admin/img/customer/user-01.jpg',
    service: 'Computer Repair',
    user: 'John Smith',
    amount: '$80',
    offerprice: '$80',
    offer: '25%',
    date: '28 Sep 2023',
    enddate: '28 Sep 2023',
  },
];
