import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetClassFreePurchase = (dateRange?: [Date, Date]) => {
    const [classFreePurchase, setClassFreePurchase] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchClassFreePurchase = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getClassFreePurchaseReport(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate)
            );
            const classFreePurchase = response.data.data;
            setClassFreePurchase(classFreePurchase);
        } catch (err) {
            console.error("Error al obtener los datos de compras:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
            fetchClassFreePurchase();
        }
    }, [dateRange]);

    return {
        classFreePurchase,
        isLoading,
        error,
        refetchClassFreePurchase: fetchClassFreePurchase,
    };
};