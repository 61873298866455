import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsCoupons';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import PaymentDaysModal from '../common/modals/users-payment-day-modal';
import { InputField } from '../../admin/common/input/inputField';

const PaymentDays = () => {
  const [data, setData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedPaymentDays, setSelectedPaymentDays] = useState<string | null>(null);
  const [totalRecords, setTotalRecords] = useState<number>(-1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const [fullName, setFullName] = useState<string>('');
  const [userId, setUserId] = useState<string>('');

  const filterUsersByName = () => {
    if (!fullName.trim()) {
      setUserId('');
      return;
    }

    const filtered = userOptions.filter(user =>
      `${user.name.toLowerCase()} ${user.lastname.toLowerCase()}`.includes(fullName.toLowerCase())
    );

    if (filtered.length > 0) {
      setUserId(filtered[0].id);
    } else {
      setUserId('');
    }
  };

  useEffect(() => {
    filterUsersByName();
  }, [fullName]);

  const fetchPaymentDays = async (pageNumber = currentPage, pageSize = rows) => {
    try {
      setLoading(true);
      const paymentDaysResponse = await apiRepository.getPaymentDays(pageNumber, pageSize, userId);
      const paymentDays = paymentDaysResponse.data.data.content;

      setData(paymentDays);
      setTotalRecords(paymentDaysResponse.data.data.totalElements);
    } catch (error) {
      console.error("Error al obtener los días de pago:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const filters = {
        email: '',
        fullName: '',
        profile: 'customer',
      };
      const response = await apiRepository.getUsers(0, 10000, filters);
      setUserOptions(response.data.data.content);
    } catch (error) {
      console.error("Error al obtener los datos de usuarios:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPaymentDays();
  }, [currentPage, userId]);

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEditClick = (rowData: any) => {
    setSelectedPaymentDays(null);
    setTimeout(() => setSelectedPaymentDays(rowData), 0);
  };

  const columns = [
    { field: "id", header: "ID" },
    { field: "fullName", header: "Usuario" },
    { field: "quantity", header: "Cantidad de Clases" },
    {
      field: "endDate",
      header: "Fecha de Vencimiento",
      body: (rowData) => (
        rowData.endDate && rowData.endDate !== ''
          ? rowData.endDate.split('T')[0]
          : 'Fecha sin definir'
      )
    }, { field: "type", header: "Tipo" },
    {
      field: "active",
      header: "Estado",
      body: (rowData) => (
        <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
          {rowData.status ? 'Activo' : 'Inactivo'}
        </span>
      )
    },
  ];

  const getColumns = useResponsiveColumns(columns);

  const onPageChange = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    const pageNumber = event.first / event.rows;
    setCurrentPage(pageNumber);
    fetchPaymentDays(pageNumber, event.rows);
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Clases Asignadas</h5>
          <div className="list-btn">
            <ul>
              <li>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => setFiltersVisible(!filtersVisible)}
                >
                  {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
                </button>
              </li>

            </ul>
          </div>
        </div>

        {filtersVisible && (
          <div className="search-filters card p-3 mb-4 shadow-sm">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h6 className="text-dark">Filtros de búsqueda</h6>
              <button
                className="btn btn-outline-primary btn-sm rounded-3"
                onClick={() => {
                  setFullName('');
                }}
                title="Limpiar filtros"
              >
                <i className="fa fa-eraser me-2" />
                Limpiar
              </button>
            </div>
            <div className="d-flex align-items-center gap-3">
              <div className="flex-grow-1 position-relative">
                <InputField
                  label="Nombre"
                  name="fullName"
                  placeholder="Ejemplo: Juan"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className="form-control form-control-sm shadow-sm rounded-2"
                />
              </div>
            </div>
          </div>

        )}

        <DataTableWrapper
          data={data}
          columns={getColumns()}
          onEdit={handleEditClick}
          loading={loading}
          paginator
          showGridlines
          rowsPerPage={rows}
          totalRecords={totalRecords}
          first={first}
          onPageChange={onPageChange}
          emptyMessage="No se encontraron días de pago."
        />
      </div>
      <PaymentDaysModal selectedPaymentDays={selectedPaymentDays} onUpdateSuccess={fetchPaymentDays} />
    </div>
  );
};

export default PaymentDays;