import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import BaseModal from './baseModal';

const ShowDetailClassModal: React.FC<any> = ({ selectedClassData }) => {
    const [show, setShow] = useState<boolean>(false);
    const [classData, setClassData] = useState<any>();

    useEffect(() => {
        if (selectedClassData) {
            setClassData(selectedClassData);
            setShow(true);
        }
    }, [selectedClassData]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <BaseModal
            show={show || false}
            title="Detalle de Clase"
            onClose={handleClose}
            showButtons={false}
        >
            <div className="modal-body pt-0">
                <div className="text-center col-md-12 m-auto">
                    <div className="text-center mb-2">
                        <Icon.CheckCircle size={50} color="#28a745" className="text-success" />
                        <h4 className="mt-0">{classData?.userName}</h4>
                        <p className="text-muted mb-0">{classData?.className}</p>
                        <p className="text-muted mb-0">{classData?.trainerName}</p>
                    </div>
                    <div className="mt-2">
                        {classData?.classDate && (
                            <table className="table table-bordered mt-2">
                                <tbody>
                                    <tr>
                                        <th>Fecha de Clase</th>
                                        <td>{classData?.classDate}</td>
                                    </tr>
                                    <tr>
                                        <th>Hora de Clase</th>
                                        <td>{classData?.classHour}</td>
                                    </tr>
                                    <tr>
                                        <th>Edad</th>
                                        <td>{classData?.age}</td>
                                    </tr>
                                    <tr>
                                        <th>Usuario</th>
                                        <td>{classData?.userName}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn w-sm btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </BaseModal>
    );
}

export default ShowDetailClassModal;