export const faqData = [
    {
        id: 1,
        title: "¿Cómo puedo reservar un servicio?",
        description: "Para reservar un servicio, selecciona el servicio que deseas y sigue las instrucciones en pantalla para completar la reserva."
    },
    {
        id: 2,
        title: "¿Cuáles son los métodos de pago disponibles?",
        description: "Aceptamos pagos con tarjetas de crédito, débito, y transferencias bancarias. También puedes optar por pagos en efectivo en el momento de la entrega."
    },
    {
        id: 3,
        title: "¿Puedo cancelar mi reserva?",
        description: "Sí, puedes cancelar tu reserva hasta 24 horas antes del inicio del servicio sin cargo adicional. Consulta nuestras políticas de cancelación para más detalles."
    },
    {
        id: 4,
        title: "¿Cuánto tiempo dura cada servicio?",
        description: "La duración del servicio depende del tipo de servicio seleccionado. Puedes ver una estimación al hacer tu reserva."
    },
    {
        id: 5,
        title: "¿Ofrecen servicios de emergencia?",
        description: "Sí, contamos con un servicio de emergencia disponible las 24 horas. Consulta los detalles en nuestra sección de servicios."
    },
    {
        id: 6,
        title: "¿Cómo puedo contactar al servicio de atención al cliente?",
        description: "Puedes contactarnos a través del chat en nuestra página web o enviando un correo electrónico a soporte@nuestrositio.com."
    },
    {
        id: 7,
        title: "¿Ofrecen algún tipo de garantía?",
        description: "Sí, todos nuestros servicios están cubiertos por una garantía de satisfacción. Consulta las condiciones específicas en la página de cada servicio."
    },
    {
        id: 8,
        title: "¿Puedo modificar mi reserva después de haberla confirmado?",
        description: "Sí, puedes modificar tu reserva a través de tu cuenta en nuestro sitio web, siempre que sea con al menos 12 horas de anticipación."
    },
    {
        id: 9,
        title: "¿Los precios incluyen impuestos?",
        description: "Sí, todos los precios mostrados en nuestro sitio incluyen los impuestos aplicables."
    },
    {
        id: 10,
        title: "¿Tienen promociones o descuentos?",
        description: "Ofrecemos promociones y descuentos en distintos servicios. Visita nuestra sección de promociones para más información."
    }
];