import { useState } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetCancellationRateReportExelFile = (dateRange?: [Date, Date]) => {
    const [cancellationRateReportExelFile, setCancellationRateReportExelFile] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchCancellationRateReport = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getCancellationRateReportExcelFile(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
            );
            const cancellationRateReportExelFile = response.data;
            setCancellationRateReportExelFile(cancellationRateReportExelFile);
            return cancellationRateReportExelFile;
        } catch (err) {
            console.error("Error al obtener los datos de cancelaciones:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        cancellationRateReportExelFile,
        isLoading,
        error,
        refetchCancellationRateReport: fetchCancellationRateReport,
    };
};