import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface DeleteBannerModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
}

const DeleteBannerModal: React.FC<DeleteBannerModalProps> = ({ show, onHide, onConfirm }) => {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmar Eliminación</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        ¿Estás seguro de que deseas eliminar este banner?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="danger" onClick={onConfirm}>
          Eliminar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteBannerModal;
