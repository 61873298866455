import React from 'react';
import Slider from 'react-slick';
import { Banner } from './bannerMain';
import TrialSection from './trialSection';
import PackageSection from './packageSection';
import AuthModals from '../header/authModals';
import FeatureSection from './feature-section';
import MainFooter from '../footer/main-footer';
import HomeHeader from '../header/main-header';
import { sliderOptions } from '../../../../core/data/config/slider';
import { useBanners } from '../../../../core/hooks/getData/useBanners';
import { useProgressiveRender } from '../../../../core/hooks/loadPage/useProgressiveRender';

const NewHome = () => {
  const { banners, isLoading } = useBanners(true, true);
  const isSecondaryLoaded = useProgressiveRender(500);

  if (isLoading) {
    return (
      <div className="loading-screen">
      </div>
    );
  }

  if (!banners.length) {
    return <div className="no-banners">No hay banners disponibles.</div>;
  }

  return (
    <>
      <HomeHeader />
      <Slider {...sliderOptions} className="img-slider nav-center hero-section slick-slider slide-arrows-hero bg-section-light">
        {banners.map((banner, index) => (
          <Banner key={index} banner={banner} />
        ))}
      </Slider>
      {isSecondaryLoaded && (
        <>
          <TrialSection />
          <PackageSection />
          <FeatureSection />
          <MainFooter />
          <AuthModals />
        </>
      )}
    </>
  );
};

export default NewHome;