import { BannerState } from '../Interface/state';

export const bannerReducer = (state: BannerState, action: { type: string; payload?: any }): BannerState => {
    switch (action.type) {
        case 'FETCH_SUCCESS':
            return { activeBanners: action.payload, isLoading: false };
        case 'FETCH_ERROR':
            return { ...state, isLoading: false };
        default:
            return state;
    }
};