export const UBIGEO_DATA = [
    {
        "department": "AMAZONAS",
        "provinces": [
            {
                "province": "CHACHAPOYAS",
                "districts": [
                    "CHACHAPOYAS",
                    "ASUNCION",
                    "BALSAS",
                    "CHETO",
                    "CHILIQUIN",
                    "CHUQUIBAMBA",
                    "GRANADA",
                    "HUANCAS",
                    "LA JALCA",
                    "LEIMEBAMBA",
                    "LEVANTO",
                    "MAGDALENA",
                    "MARISCAL CASTILLA",
                    "MOLINOPAMPA",
                    "MONTEVIDEO",
                    "OLLEROS",
                    "QUINJALCA",
                    "SAN FRANCISCO DE DAGUAS",
                    "SAN ISIDRO DE MAINO",
                    "SOLOCO",
                    "SONCHE"
                ]
            },
            {
                "province": "BAGUA",
                "districts": [
                    "BAGUA",
                    "ARAMANGO",
                    "COPALLIN",
                    "EL PARCO",
                    "IMAZA",
                    "LA PECA"
                ]
            },
            {
                "province": "BONGARA",
                "districts": [
                    "JUMBILLA",
                    "CHISQUILLA",
                    "CHURUJA",
                    "COROSHA",
                    "CUISPES",
                    "FLORIDA",
                    "JAZAN",
                    "RECTA",
                    "SAN CARLOS",
                    "SHIPASBAMBA",
                    "VALERA",
                    "YAMBRASBAMBA"
                ]
            },
            {
                "province": "CONDORCANQUI",
                "districts": [
                    "NIEVA",
                    "EL CENEPA",
                    "RIO SANTIAGO"
                ]
            },
            {
                "province": "LUYA",
                "districts": [
                    "LAMUD",
                    "CAMPORREDONDO",
                    "COCABAMBA",
                    "COLCAMAR",
                    "CONILA",
                    "INGUILPATA",
                    "LONGUITA",
                    "LONYA CHICO",
                    "LUYA",
                    "LUYA VIEJO",
                    "MARIA",
                    "OCALLI",
                    "OCUMAL",
                    "PISUQUIA",
                    "PROVIDENCIA",
                    "SAN CRISTOBAL",
                    "SAN FRANCISCO DEL YESO",
                    "SAN JERONIMO",
                    "SAN JUAN DE LOPECANCHA",
                    "SANTA CATALINA",
                    "SANTO TOMAS",
                    "TINGO",
                    "TRITA"
                ]
            },
            {
                "province": "RODRIGUEZ DE MENDOZA",
                "districts": [
                    "SAN NICOLAS",
                    "CHIRIMOTO",
                    "COCHAMAL",
                    "HUAMBO",
                    "LIMABAMBA",
                    "LONGAR",
                    "MARISCAL BENAVIDES",
                    "MILPUC",
                    "OMIA",
                    "SANTA ROSA",
                    "TOTORA",
                    "VISTA ALEGRE"
                ]
            },
            {
                "province": "UTCUBAMBA",
                "districts": [
                    "BAGUA GRANDE",
                    "CAJARURO",
                    "CUMBA",
                    "EL MILAGRO",
                    "JAMALCA",
                    "LONYA GRANDE",
                    "YAMON"
                ]
            }
        ]
    },
    {
        "department": "ANCASH",
        "provinces": [
            {
                "province": "HUARAZ",
                "districts": [
                    "HUARAZ",
                    "COCHABAMBA",
                    "COLCABAMBA",
                    "HUANCHAY",
                    "INDEPENDENCIA",
                    "JANGAS",
                    "LA LIBERTAD",
                    "OLLEROS",
                    "PAMPAS",
                    "PARIACOTO",
                    "PIRA",
                    "TARICA"
                ]
            },
            {
                "province": "AIJA",
                "districts": [
                    "AIJA",
                    "CORIS",
                    "HUACLLAN",
                    "LA MERCED",
                    "SUCCHA"
                ]
            },
            {
                "province": "ANTONIO RAYMONDI",
                "districts": [
                    "LLAMELLIN",
                    "ACZO",
                    "CHACCHO",
                    "CHINGAS",
                    "MIRGAS",
                    "SAN JUAN DE RONTOY"
                ]
            },
            {
                "province": "ASUNCION",
                "districts": [
                    "CHACAS",
                    "ACOCHACA"
                ]
            },
            {
                "province": "BOLOGNESI",
                "districts": [
                    "CHIQUIAN",
                    "ABELARDO PARDO LEZAMETA",
                    "ANTONIO RAYMONDI",
                    "AQUIA",
                    "CAJACAY",
                    "CANIS",
                    "COLQUIOC",
                    "HUALLANCA",
                    "HUASTA",
                    "HUAYLLACAYAN",
                    "LA PRIMAVERA",
                    "MANGAS",
                    "PACLLON",
                    "SAN MIGUEL DE CORPANQUI",
                    "TICLLOS"
                ]
            },
            {
                "province": "CARHUAZ",
                "districts": [
                    "CARHUAZ",
                    "ACOPAMPA",
                    "AMASHCA",
                    "ANTA",
                    "ATAQUERO",
                    "MARCARA",
                    "PARIAHUANCA",
                    "SAN MIGUEL DE ACO",
                    "SHILLA",
                    "TINCO",
                    "YUNGAR"
                ]
            },
            {
                "province": "CARLOS FERMIN FITZCARRALD",
                "districts": [
                    "SAN LUIS",
                    "SAN NICOLAS",
                    "YAUYA"
                ]
            },
            {
                "province": "CASMA",
                "districts": [
                    "CASMA",
                    "BUENA VISTA ALTA",
                    "COMANDANTE NOEL",
                    "YAUTAN"
                ]
            },
            {
                "province": "CORONGO",
                "districts": [
                    "CORONGO",
                    "ACO",
                    "BAMBAS",
                    "CUSCA",
                    "LA PAMPA",
                    "YANAC",
                    "YUPAN"
                ]
            },
            {
                "province": "HUARI",
                "districts": [
                    "HUARI",
                    "ANRA",
                    "CAJAY",
                    "CHAVIN DE HUANTAR",
                    "HUACACHI",
                    "HUACCHIS",
                    "HUACHIS",
                    "HUANTAR",
                    "MASIN",
                    "PAUCAS",
                    "PONTO",
                    "RAHUAPAMPA",
                    "RAPAYAN",
                    "SAN MARCOS",
                    "SAN PEDRO DE CHANA",
                    "UCO"
                ]
            },
            {
                "province": "HUARMEY",
                "districts": [
                    "HUARMEY",
                    "COCHAPETI",
                    "CULEBRAS",
                    "HUAYAN",
                    "MALVAS"
                ]
            },
            {
                "province": "HUAYLAS",
                "districts": [
                    "CARAZ",
                    "HUALLANCA",
                    "HUATA",
                    "HUAYLAS",
                    "MATO",
                    "PAMPAROMAS",
                    "PUEBLO LIBRE",
                    "SANTA CRUZ",
                    "SANTO TORIBIO",
                    "YURACMARCA"
                ]
            },
            {
                "province": "MARISCAL LUZURIAGA",
                "districts": [
                    "PISCOBAMBA",
                    "CASCA",
                    "ELEAZAR GUZMAN BARRON",
                    "FIDEL OLIVAS ESCUDERO",
                    "LLAMA",
                    "LLUMPA",
                    "LUCMA",
                    "MUSGA"
                ]
            },
            {
                "province": "OCROS",
                "districts": [
                    "OCROS",
                    "ACAS",
                    "CAJAMARQUILLA",
                    "CARHUAPAMPA",
                    "COCHAS",
                    "CONGAS",
                    "LLIPA",
                    "SAN CRISTOBAL DE RAJAN",
                    "SAN PEDRO",
                    "SANTIAGO DE CHILCAS"
                ]
            },
            {
                "province": "PALLASCA",
                "districts": [
                    "CABANA",
                    "BOLOGNESI",
                    "CONCHUCOS",
                    "HUACASCHUQUE",
                    "HUANDOVAL",
                    "LACABAMBA",
                    "LLAPO",
                    "PALLASCA",
                    "PAMPAS",
                    "SANTA ROSA",
                    "TAUCA"
                ]
            },
            {
                "province": "POMABAMBA",
                "districts": [
                    "POMABAMBA",
                    "HUAYLLAN",
                    "PAROBAMBA",
                    "QUINUABAMBA"
                ]
            },
            {
                "province": "RECUAY",
                "districts": [
                    "RECUAY",
                    "CATAC",
                    "COTAPARACO",
                    "HUAYLLAPAMPA",
                    "LLACLLIN",
                    "MARCA",
                    "PAMPAS CHICO",
                    "PARARIN",
                    "TAPACOCHA",
                    "TICAPAMPA"
                ]
            },
            {
                "province": "SANTA",
                "districts": [
                    "CHIMBOTE",
                    "CACERES DEL PERU",
                    "COISHCO",
                    "MACATE",
                    "MORO",
                    "NEPEÑA",
                    "SAMANCO",
                    "SANTA",
                    "NUEVO CHIMBOTE"
                ]
            },
            {
                "province": "SIHUAS",
                "districts": [
                    "SIHUAS",
                    "ACOBAMBA",
                    "ALFONSO UGARTE",
                    "CASHAPAMPA",
                    "CHINGALPO",
                    "HUAYLLABAMBA",
                    "QUICHES",
                    "RAGASH",
                    "SAN JUAN",
                    "SICSIBAMBA"
                ]
            },
            {
                "province": "YUNGAY",
                "districts": [
                    "YUNGAY",
                    "CASCAPARA",
                    "MANCOS",
                    "MATACOTO",
                    "QUILLO",
                    "RANRAHIRCA",
                    "SHUPLUY",
                    "YANAMA"
                ]
            }
        ]
    },
    {
        "department": "APURIMAC",
        "provinces": [
            {
                "province": "ABANCAY",
                "districts": [
                    "ABANCAY",
                    "CHACOCHE",
                    "CIRCA",
                    "CURAHUASI",
                    "HUANIPACA",
                    "LAMBRAMA",
                    "PICHIRHUA",
                    "SAN PEDRO DE CACHORA",
                    "TAMBURCO"
                ]
            },
            {
                "province": "ANDAHUAYLAS",
                "districts": [
                    "ANDAHUAYLAS",
                    "ANDARAPA",
                    "CHIARA",
                    "HUANCARAMA",
                    "HUANCARAY",
                    "HUAYANA",
                    "KISHUARA",
                    "PACOBAMBA",
                    "PACUCHA",
                    "PAMPACHIRI",
                    "POMACOCHA",
                    "SAN ANTONIO DE CACHI",
                    "SAN JERONIMO",
                    "SAN MIGUEL DE CHACCRAMPA",
                    "SANTA MARIA DE CHICMO",
                    "TALAVERA",
                    "TUMAY HUARACA",
                    "TURPO",
                    "KAQUIABAMBA",
                    "JOSE MARIA ARGUEDAS"
                ]
            },
            {
                "province": "ANTABAMBA",
                "districts": [
                    "ANTABAMBA",
                    "EL ORO",
                    "HUAQUIRCA",
                    "JUAN ESPINOZA MEDRANO",
                    "OROPESA",
                    "PACHACONAS",
                    "SABAINO"
                ]
            },
            {
                "province": "AYMARAES",
                "districts": [
                    "CHALHUANCA",
                    "CAPAYA",
                    "CARAYBAMBA",
                    "CHAPIMARCA",
                    "COLCABAMBA",
                    "COTARUSE",
                    "HUAYLLO",
                    "JUSTO APU SAHUARAURA",
                    "LUCRE",
                    "POCOHUANCA",
                    "SAN JUAN DE CHACÑA",
                    "SAÑAYCA",
                    "SORAYA",
                    "TAPAIRIHUA",
                    "TINTAY",
                    "TORAYA",
                    "YANACA"
                ]
            },
            {
                "province": "COTABAMBAS",
                "districts": [
                    "TAMBOBAMBA",
                    "COTABAMBAS",
                    "COYLLURQUI",
                    "HAQUIRA",
                    "MARA",
                    "CHALLHUAHUACHO"
                ]
            },
            {
                "province": "CHINCHEROS",
                "districts": [
                    "CHINCHEROS",
                    "ANCO-HUALLO",
                    "COCHARCAS",
                    "HUACCANA",
                    "OCOBAMBA",
                    "ONGOY",
                    "URANMARCA",
                    "RANRACANCHA",
                    "ROCCHACC",
                    "EL PORVENIR",
                    "LOS CHANKAS",
                    "AHUAYRO"
                ]
            },
            {
                "province": "GRAU",
                "districts": [
                    "CHUQUIBAMBILLA",
                    "CURPAHUASI",
                    "GAMARRA",
                    "HUAYLLATI",
                    "MAMARA",
                    "MICAELA BASTIDAS",
                    "PATAYPAMPA",
                    "PROGRESO",
                    "SAN ANTONIO",
                    "SANTA ROSA",
                    "TURPAY",
                    "VILCABAMBA",
                    "VIRUNDO",
                    "CURASCO"
                ]
            }
        ]
    },
    {
        "department": "AREQUIPA",
        "provinces": [
            {
                "province": "AREQUIPA",
                "districts": [
                    "AREQUIPA",
                    "ALTO SELVA ALEGRE",
                    "CAYMA",
                    "CERRO COLORADO",
                    "CHARACATO",
                    "CHIGUATA",
                    "JACOBO HUNTER",
                    "LA JOYA",
                    "MARIANO MELGAR",
                    "MIRAFLORES",
                    "MOLLEBAYA",
                    "PAUCARPATA",
                    "POCSI",
                    "POLOBAYA",
                    "QUEQUEÑA",
                    "SABANDIA",
                    "SACHACA",
                    "SAN JUAN DE SIGUAS",
                    "SAN JUAN DE TARUCANI",
                    "SANTA ISABEL DE SIGUAS",
                    "SANTA RITA DE SIGUAS",
                    "SOCABAYA",
                    "TIABAYA",
                    "UCHUMAYO",
                    "VITOR",
                    "YANAHUARA",
                    "YARABAMBA",
                    "YURA",
                    "JOSE LUIS BUSTAMANTE Y RIVERO"
                ]
            },
            {
                "province": "CAMANA",
                "districts": [
                    "CAMANA",
                    "JOSE MARIA QUIMPER",
                    "MARIANO NICOLAS VALCARCEL",
                    "MARISCAL CACERES",
                    "NICOLAS DE PIEROLA",
                    "OCOÑA",
                    "QUILCA",
                    "SAMUEL PASTOR"
                ]
            },
            {
                "province": "CARAVELI",
                "districts": [
                    "CARAVELI",
                    "ACARI",
                    "ATICO",
                    "ATIQUIPA",
                    "BELLA UNION",
                    "CAHUACHO",
                    "CHALA",
                    "CHAPARRA",
                    "HUANUHUANU",
                    "JAQUI",
                    "LOMAS",
                    "QUICACHA",
                    "YAUCA"
                ]
            },
            {
                "province": "CASTILLA",
                "districts": [
                    "APLAO",
                    "ANDAGUA",
                    "AYO",
                    "CHACHAS",
                    "CHILCAYMARCA",
                    "CHOCO",
                    "HUANCARQUI",
                    "MACHAGUAY",
                    "ORCOPAMPA",
                    "PAMPACOLCA",
                    "TIPAN",
                    "UÑON",
                    "URACA",
                    "VIRACO"
                ]
            },
            {
                "province": "CAYLLOMA",
                "districts": [
                    "CHIVAY",
                    "ACHOMA",
                    "CABANACONDE",
                    "CALLALLI",
                    "CAYLLOMA",
                    "COPORAQUE",
                    "HUAMBO",
                    "HUANCA",
                    "ICHUPAMPA",
                    "LARI",
                    "LLUTA",
                    "MACA",
                    "MADRIGAL",
                    "SAN ANTONIO DE CHUCA",
                    "SIBAYO",
                    "TAPAY",
                    "TISCO",
                    "TUTI",
                    "YANQUE",
                    "MAJES"
                ]
            },
            {
                "province": "CONDESUYOS",
                "districts": [
                    "CHUQUIBAMBA",
                    "ANDARAY",
                    "CAYARANI",
                    "CHICHAS",
                    "IRAY",
                    "RIO GRANDE",
                    "SALAMANCA",
                    "YANAQUIHUA"
                ]
            },
            {
                "province": "ISLAY",
                "districts": [
                    "MOLLENDO",
                    "COCACHACRA",
                    "DEAN VALDIVIA",
                    "ISLAY",
                    "MEJIA",
                    "PUNTA DE BOMBON"
                ]
            },
            {
                "province": "LA UNION",
                "districts": [
                    "COTAHUASI",
                    "ALCA",
                    "CHARCANA",
                    "HUAYNACOTAS",
                    "PAMPAMARCA",
                    "PUYCA",
                    "QUECHUALLA",
                    "SAYLA",
                    "TAURIA",
                    "TOMEPAMPA",
                    "TORO"
                ]
            }
        ]
    },
    {
        "department": "AYACUCHO",
        "provinces": [
            {
                "province": "HUAMANGA",
                "districts": [
                    "AYACUCHO",
                    "ACOCRO",
                    "ACOS VINCHOS",
                    "CARMEN ALTO",
                    "CHIARA",
                    "OCROS",
                    "PACAYCASA",
                    "QUINUA",
                    "SAN JOSE DE TICLLAS",
                    "SAN JUAN BAUTISTA",
                    "SANTIAGO DE PISCHA",
                    "SOCOS",
                    "TAMBILLO",
                    "VINCHOS",
                    "JESUS NAZARENO",
                    "ANDRES AVELINO CACERES DORREGARAY"
                ]
            },
            {
                "province": "CANGALLO",
                "districts": [
                    "CANGALLO",
                    "CHUSCHI",
                    "LOS MOROCHUCOS",
                    "MARIA PARADO DE BELLIDO",
                    "PARAS",
                    "TOTOS"
                ]
            },
            {
                "province": "HUANCA SANCOS",
                "districts": [
                    "SANCOS",
                    "CARAPO",
                    "SACSAMARCA",
                    "SANTIAGO DE LUCANAMARCA"
                ]
            },
            {
                "province": "HUANTA",
                "districts": [
                    "HUANTA",
                    "AYAHUANCO",
                    "HUAMANGUILLA",
                    "IGUAIN",
                    "LURICOCHA",
                    "SANTILLANA",
                    "SIVIA",
                    "LLOCHEGUA",
                    "CANAYRE",
                    "UCHURACCAY",
                    "PUCACOLPA",
                    "CHACA",
                    "PUTIS"
                ]
            },
            {
                "province": "LA MAR",
                "districts": [
                    "SAN MIGUEL",
                    "ANCO",
                    "AYNA",
                    "CHILCAS",
                    "CHUNGUI",
                    "LUIS CARRANZA",
                    "SANTA ROSA",
                    "TAMBO",
                    "SAMUGARI",
                    "ANCHIHUAY",
                    "ORONCCOY",
                    "UNION PROGRESO",
                    "RIO MAGDALENA",
                    "NINABAMBA",
                    "PATIBAMBA"
                ]
            },
            {
                "province": "LUCANAS",
                "districts": [
                    "PUQUIO",
                    "AUCARA",
                    "CABANA",
                    "CARMEN SALCEDO",
                    "CHAVIÑA",
                    "CHIPAO",
                    "HUAC-HUAS",
                    "LARAMATE",
                    "LEONCIO PRADO",
                    "LLAUTA",
                    "LUCANAS",
                    "OCAÑA",
                    "OTOCA",
                    "SAISA",
                    "SAN CRISTOBAL",
                    "SAN JUAN",
                    "SAN PEDRO",
                    "SAN PEDRO DE PALCO",
                    "SANCOS",
                    "SANTA ANA DE HUAYCAHUACHO",
                    "SANTA LUCIA"
                ]
            },
            {
                "province": "PARINACOCHAS",
                "districts": [
                    "CORACORA",
                    "CHUMPI",
                    "CORONEL CASTAÑEDA",
                    "PACAPAUSA",
                    "PULLO",
                    "PUYUSCA",
                    "SAN FRANCISCO DE RAVACAYCO",
                    "UPAHUACHO"
                ]
            },
            {
                "province": "PAUCAR DEL SARA SARA",
                "districts": [
                    "PAUSA",
                    "COLTA",
                    "CORCULLA",
                    "LAMPA",
                    "MARCABAMBA",
                    "OYOLO",
                    "PARARCA",
                    "SAN JAVIER DE ALPABAMBA",
                    "SAN JOSE DE USHUA",
                    "SARA SARA"
                ]
            },
            {
                "province": "SUCRE",
                "districts": [
                    "QUEROBAMBA",
                    "BELEN",
                    "CHALCOS",
                    "CHILCAYOC",
                    "HUACAÑA",
                    "MORCOLLA",
                    "PAICO",
                    "SAN PEDRO DE LARCAY",
                    "SAN SALVADOR DE QUIJE",
                    "SANTIAGO DE PAUCARAY",
                    "SORAS"
                ]
            },
            {
                "province": "VICTOR FAJARDO",
                "districts": [
                    "HUANCAPI",
                    "ALCAMENCA",
                    "APONGO",
                    "ASQUIPATA",
                    "CANARIA",
                    "CAYARA",
                    "COLCA",
                    "HUAMANQUIQUIA",
                    "HUANCARAYLLA",
                    "HUAYA",
                    "SARHUA",
                    "VILCANCHOS"
                ]
            },
            {
                "province": "VILCAS HUAMAN",
                "districts": [
                    "VILCAS HUAMAN",
                    "ACCOMARCA",
                    "CARHUANCA",
                    "CONCEPCION",
                    "HUAMBALPA",
                    "INDEPENDENCIA",
                    "SAURAMA",
                    "VISCHONGO"
                ]
            }
        ]
    },
    {
        "department": "CAJAMARCA",
        "provinces": [
            {
                "province": "CAJAMARCA",
                "districts": [
                    "CAJAMARCA",
                    "ASUNCION",
                    "CHETILLA",
                    "COSPAN",
                    "ENCAÑADA",
                    "JESUS",
                    "LLACANORA",
                    "LOS BAÑOS DEL INCA",
                    "MAGDALENA",
                    "MATARA",
                    "NAMORA",
                    "SAN JUAN"
                ]
            },
            {
                "province": "CAJABAMBA",
                "districts": [
                    "CAJABAMBA",
                    "CACHACHI",
                    "CONDEBAMBA",
                    "SITACOCHA"
                ]
            },
            {
                "province": "CELENDIN",
                "districts": [
                    "CELENDIN",
                    "CHUMUCH",
                    "CORTEGANA",
                    "HUASMIN",
                    "JORGE CHAVEZ",
                    "JOSE GALVEZ",
                    "MIGUEL IGLESIAS",
                    "OXAMARCA",
                    "SOROCHUCO",
                    "SUCRE",
                    "UTCO",
                    "LA LIBERTAD DE PALLAN"
                ]
            },
            {
                "province": "CHOTA",
                "districts": [
                    "CHOTA",
                    "ANGUIA",
                    "CHADIN",
                    "CHIGUIRIP",
                    "CHIMBAN",
                    "CHOROPAMPA",
                    "COCHABAMBA",
                    "CONCHAN",
                    "HUAMBOS",
                    "LAJAS",
                    "LLAMA",
                    "MIRACOSTA",
                    "PACCHA",
                    "PION",
                    "QUEROCOTO",
                    "SAN JUAN DE LICUPIS",
                    "TACABAMBA",
                    "TOCMOCHE",
                    "CHALAMARCA"
                ]
            },
            {
                "province": "CONTUMAZA",
                "districts": [
                    "CONTUMAZA",
                    "CHILETE",
                    "CUPISNIQUE",
                    "GUZMANGO",
                    "SAN BENITO",
                    "SANTA CRUZ DE TOLEDO",
                    "TANTARICA",
                    "YONAN"
                ]
            },
            {
                "province": "CUTERVO",
                "districts": [
                    "CUTERVO",
                    "CALLAYUC",
                    "CHOROS",
                    "CUJILLO",
                    "LA RAMADA",
                    "PIMPINGOS",
                    "QUEROCOTILLO",
                    "SAN ANDRES DE CUTERVO",
                    "SAN JUAN DE CUTERVO",
                    "SAN LUIS DE LUCMA",
                    "SANTA CRUZ",
                    "SANTO DOMINGO DE LA CAPILLA",
                    "SANTO TOMAS",
                    "SOCOTA",
                    "TORIBIO CASANOVA"
                ]
            },
            {
                "province": "HUALGAYOC",
                "districts": [
                    "BAMBAMARCA",
                    "CHUGUR",
                    "HUALGAYOC"
                ]
            },
            {
                "province": "JAEN",
                "districts": [
                    "JAEN",
                    "BELLAVISTA",
                    "CHONTALI",
                    "COLASAY",
                    "HUABAL",
                    "LAS PIRIAS",
                    "POMAHUACA",
                    "PUCARA",
                    "SALLIQUE",
                    "SAN FELIPE",
                    "SAN JOSE DEL ALTO",
                    "SANTA ROSA"
                ]
            },
            {
                "province": "SAN IGNACIO",
                "districts": [
                    "SAN IGNACIO",
                    "CHIRINOS",
                    "HUARANGO",
                    "LA COIPA",
                    "NAMBALLE",
                    "SAN JOSE DE LOURDES",
                    "TABACONAS"
                ]
            },
            {
                "province": "SAN MARCOS",
                "districts": [
                    "PEDRO GALVEZ",
                    "CHANCAY",
                    "EDUARDO VILLANUEVA",
                    "GREGORIO PITA",
                    "ICHOCAN",
                    "JOSE MANUEL QUIROZ",
                    "JOSE SABOGAL"
                ]
            },
            {
                "province": "SAN MIGUEL",
                "districts": [
                    "SAN MIGUEL",
                    "BOLIVAR",
                    "CALQUIS",
                    "CATILLUC",
                    "EL PRADO",
                    "LA FLORIDA",
                    "LLAPA",
                    "NANCHOC",
                    "NIEPOS",
                    "SAN GREGORIO",
                    "SAN SILVESTRE DE COCHAN",
                    "TONGOD",
                    "UNION AGUA BLANCA"
                ]
            },
            {
                "province": "SAN PABLO",
                "districts": [
                    "SAN PABLO",
                    "SAN BERNARDINO",
                    "SAN LUIS",
                    "TUMBADEN"
                ]
            },
            {
                "province": "SANTA CRUZ",
                "districts": [
                    "SANTA CRUZ",
                    "ANDABAMBA",
                    "CATACHE",
                    "CHANCAYBAÑOS",
                    "LA ESPERANZA",
                    "NINABAMBA",
                    "PULAN",
                    "SAUCEPAMPA",
                    "SEXI",
                    "UTICYACU",
                    "YAUYUCAN"
                ]
            }
        ]
    },
    {
        "department": "CALLAO",
        "provinces": [
            {
                "province": "CALLAO",
                "districts": [
                    "CALLAO",
                    "BELLAVISTA",
                    "CARMEN DE LA LEGUA REYNOSO",
                    "LA PERLA",
                    "LA PUNTA",
                    "VENTANILLA",
                    "MI PERU"
                ]
            }
        ]
    },
    {
        "department": "CUSCO",
        "provinces": [
            {
                "province": "CUSCO",
                "districts": [
                    "CUSCO",
                    "CCORCA",
                    "POROY",
                    "SAN JERONIMO",
                    "SAN SEBASTIAN",
                    "SANTIAGO",
                    "SAYLLA",
                    "WANCHAQ"
                ]
            },
            {
                "province": "ACOMAYO",
                "districts": [
                    "ACOMAYO",
                    "ACOPIA",
                    "ACOS",
                    "MOSOC LLACTA",
                    "POMACANCHI",
                    "RONDOCAN",
                    "SANGARARA"
                ]
            },
            {
                "province": "ANTA",
                "districts": [
                    "ANTA",
                    "ANCAHUASI",
                    "CACHIMAYO",
                    "CHINCHAYPUJIO",
                    "HUAROCONDO",
                    "LIMATAMBO",
                    "MOLLEPATA",
                    "PUCYURA",
                    "ZURITE"
                ]
            },
            {
                "province": "CALCA",
                "districts": [
                    "CALCA",
                    "COYA",
                    "LAMAY",
                    "LARES",
                    "PISAC",
                    "SAN SALVADOR",
                    "TARAY",
                    "YANATILE"
                ]
            },
            {
                "province": "CANAS",
                "districts": [
                    "YANAOCA",
                    "CHECCA",
                    "KUNTURKANKI",
                    "LANGUI",
                    "LAYO",
                    "PAMPAMARCA",
                    "QUEHUE",
                    "TUPAC AMARU"
                ]
            },
            {
                "province": "CANCHIS",
                "districts": [
                    "SICUANI",
                    "CHECACUPE",
                    "COMBAPATA",
                    "MARANGANI",
                    "PITUMARCA",
                    "SAN PABLO",
                    "SAN PEDRO",
                    "TINTA"
                ]
            },
            {
                "province": "CHUMBIVILCAS",
                "districts": [
                    "SANTO TOMAS",
                    "CAPACMARCA",
                    "CHAMACA",
                    "COLQUEMARCA",
                    "LIVITACA",
                    "LLUSCO",
                    "QUIÑOTA",
                    "VELILLE"
                ]
            },
            {
                "province": "ESPINAR",
                "districts": [
                    "ESPINAR",
                    "CONDOROMA",
                    "COPORAQUE",
                    "OCORURO",
                    "PALLPATA",
                    "PICHIGUA",
                    "SUYCKUTAMBO",
                    "ALTO PICHIGUA"
                ]
            },
            {
                "province": "LA CONVENCION",
                "districts": [
                    "SANTA ANA",
                    "ECHARATE",
                    "HUAYOPATA",
                    "MARANURA",
                    "OCOBAMBA",
                    "QUELLOUNO",
                    "QUIMBIRI",
                    "SANTA TERESA",
                    "VILCABAMBA",
                    "PICHARI",
                    "INKAWASI",
                    "VILLA VIRGEN",
                    "VILLA KINTIARINA",
                    "MEGANTONI",
                    "KUMPIRUSHIATO",
                    "CIELO PUNCO",
                    "MANITEA",
                    "UNION ASHÁNINKA"
                ]
            },
            {
                "province": "PARURO",
                "districts": [
                    "PARURO",
                    "ACCHA",
                    "CCAPI",
                    "COLCHA",
                    "HUANOQUITE",
                    "OMACHA",
                    "PACCARITAMBO",
                    "PILLPINTO",
                    "YAURISQUE"
                ]
            },
            {
                "province": "PAUCARTAMBO",
                "districts": [
                    "PAUCARTAMBO",
                    "CAICAY",
                    "CHALLABAMBA",
                    "COLQUEPATA",
                    "HUANCARANI",
                    "KOSÑIPATA"
                ]
            },
            {
                "province": "QUISPICANCHI",
                "districts": [
                    "URCOS",
                    "ANDAHUAYLILLAS",
                    "CAMANTI",
                    "CCARHUAYO",
                    "CCATCA",
                    "CUSIPATA",
                    "HUARO",
                    "LUCRE",
                    "MARCAPATA",
                    "OCONGATE",
                    "OROPESA",
                    "QUIQUIJANA"
                ]
            },
            {
                "province": "URUBAMBA",
                "districts": [
                    "URUBAMBA",
                    "CHINCHERO",
                    "HUAYLLABAMBA",
                    "MACHUPICCHU",
                    "MARAS",
                    "OLLANTAYTAMBO",
                    "YUCAY"
                ]
            }
        ]
    },
    {
        "department": "HUANCAVELICA",
        "provinces": [
            {
                "province": "HUANCAVELICA",
                "districts": [
                    "HUANCAVELICA",
                    "ACOBAMBILLA",
                    "ACORIA",
                    "CONAYCA",
                    "CUENCA",
                    "HUACHOCOLPA",
                    "HUAYLLAHUARA",
                    "IZCUCHACA",
                    "LARIA",
                    "MANTA",
                    "MARISCAL CACERES",
                    "MOYA",
                    "NUEVO OCCORO",
                    "PALCA",
                    "PILCHACA",
                    "VILCA",
                    "YAULI",
                    "ASCENSION",
                    "HUANDO"
                ]
            },
            {
                "province": "ACOBAMBA",
                "districts": [
                    "ACOBAMBA",
                    "ANDABAMBA",
                    "ANTA",
                    "CAJA",
                    "MARCAS",
                    "PAUCARA",
                    "POMACOCHA",
                    "ROSARIO"
                ]
            },
            {
                "province": "ANGARAES",
                "districts": [
                    "LIRCAY",
                    "ANCHONGA",
                    "CALLANMARCA",
                    "CCOCHACCASA",
                    "CHINCHO",
                    "CONGALLA",
                    "HUANCA-HUANCA",
                    "HUAYLLAY GRANDE",
                    "JULCAMARCA",
                    "SAN ANTONIO DE ANTAPARCO",
                    "SANTO TOMAS DE PATA",
                    "SECCLLA"
                ]
            },
            {
                "province": "CASTROVIRREYNA",
                "districts": [
                    "CASTROVIRREYNA",
                    "ARMA",
                    "AURAHUA",
                    "CAPILLAS",
                    "CHUPAMARCA",
                    "COCAS",
                    "HUACHOS",
                    "HUAMATAMBO",
                    "MOLLEPAMPA",
                    "SAN JUAN",
                    "SANTA ANA",
                    "TANTARA",
                    "TICRAPO"
                ]
            },
            {
                "province": "CHURCAMPA",
                "districts": [
                    "CHURCAMPA",
                    "ANCO",
                    "CHINCHIHUASI",
                    "EL CARMEN",
                    "LA MERCED",
                    "LOCROJA",
                    "PAUCARBAMBA",
                    "SAN MIGUEL DE MAYOCC",
                    "SAN PEDRO DE CORIS",
                    "PACHAMARCA",
                    "COSME"
                ]
            },
            {
                "province": "HUAYTARA",
                "districts": [
                    "HUAYTARA",
                    "AYAVI",
                    "CORDOVA",
                    "HUAYACUNDO ARMA",
                    "LARAMARCA",
                    "OCOYO",
                    "PILPICHACA",
                    "QUERCO",
                    "QUITO-ARMA",
                    "SAN ANTONIO DE CUSICANCHA",
                    "SAN FRANCISCO DE SANGAYAICO",
                    "SAN ISIDRO",
                    "SANTIAGO DE CHOCORVOS",
                    "SANTIAGO DE QUIRAHUARA",
                    "SANTO DOMINGO DE CAPILLAS",
                    "TAMBO"
                ]
            },
            {
                "province": "TAYACAJA",
                "districts": [
                    "PAMPAS",
                    "ACOSTAMBO",
                    "ACRAQUIA",
                    "AHUAYCHA",
                    "COLCABAMBA",
                    "DANIEL HERNANDEZ",
                    "HUACHOCOLPA",
                    "HUARIBAMBA",
                    "ÑAHUIMPUQUIO",
                    "PAZOS",
                    "QUISHUAR",
                    "SALCABAMBA",
                    "SALCAHUASI",
                    "SAN MARCOS DE ROCCHAC",
                    "SURCUBAMBA",
                    "TINTAY PUNCU",
                    "QUICHUAS",
                    "ANDAYMARCA",
                    "ROBLE",
                    "PICHOS",
                    "SANTIAGO DE TUCUMA",
                    "LAMBRAS",
                    "COCHABAMBA"
                ]
            }
        ]
    },
    {
        "department": "HUANUCO",
        "provinces": [
            {
                "province": "HUANUCO",
                "districts": [
                    "HUANUCO",
                    "AMARILIS",
                    "CHINCHAO",
                    "CHURUBAMBA",
                    "MARGOS",
                    "QUISQUI",
                    "SAN FRANCISCO DE CAYRAN",
                    "SAN PEDRO DE CHAULAN",
                    "SANTA MARIA DEL VALLE",
                    "YARUMAYO",
                    "PILLCO MARCA",
                    "YACUS",
                    "SAN PABLO DE PILLAO"
                ]
            },
            {
                "province": "AMBO",
                "districts": [
                    "AMBO",
                    "CAYNA",
                    "COLPAS",
                    "CONCHAMARCA",
                    "HUACAR",
                    "SAN FRANCISCO",
                    "SAN RAFAEL",
                    "TOMAY KICHWA"
                ]
            },
            {
                "province": "DOS DE MAYO",
                "districts": [
                    "LA UNION",
                    "CHUQUIS",
                    "MARIAS",
                    "PACHAS",
                    "QUIVILLA",
                    "RIPAN",
                    "SHUNQUI",
                    "SILLAPATA",
                    "YANAS"
                ]
            },
            {
                "province": "HUACAYBAMBA",
                "districts": [
                    "HUACAYBAMBA",
                    "CANCHABAMBA",
                    "COCHABAMBA",
                    "PINRA"
                ]
            },
            {
                "province": "HUAMALIES",
                "districts": [
                    "LLATA",
                    "ARANCAY",
                    "CHAVIN DE PARIARCA",
                    "JACAS GRANDE",
                    "JIRCAN",
                    "MIRAFLORES",
                    "MONZON",
                    "PUNCHAO",
                    "PUÑOS",
                    "SINGA",
                    "TANTAMAYO"
                ]
            },
            {
                "province": "LEONCIO PRADO",
                "districts": [
                    "RUPA-RUPA",
                    "DANIEL ALOMIAS ROBLES",
                    "HERMILIO VALDIZAN",
                    "JOSE CRESPO Y CASTILLO",
                    "LUYANDO",
                    "MARIANO DAMASO BERAUN",
                    "PUCAYACU",
                    "CASTILLO GRANDE",
                    "PUEBLO NUEVO",
                    "SANTO DOMINGO DE ANDA"
                ]
            },
            {
                "province": "MARAÑON",
                "districts": [
                    "HUACRACHUCO",
                    "CHOLON",
                    "SAN BUENAVENTURA",
                    "LA MORADA",
                    "SANTA ROSA DE ALTO YANAJANCA"
                ]
            },
            {
                "province": "PACHITEA",
                "districts": [
                    "PANAO",
                    "CHAGLLA",
                    "MOLINO",
                    "UMARI"
                ]
            },
            {
                "province": "PUERTO INCA",
                "districts": [
                    "PUERTO INCA",
                    "CODO DEL POZUZO",
                    "HONORIA",
                    "TOURNAVISTA",
                    "YUYAPICHIS"
                ]
            },
            {
                "province": "LAURICOCHA",
                "districts": [
                    "JESUS",
                    "BAÑOS",
                    "JIVIA",
                    "QUEROPALCA",
                    "RONDOS",
                    "SAN FRANCISCO DE ASIS",
                    "SAN MIGUEL DE CAURI"
                ]
            },
            {
                "province": "YAROWILCA",
                "districts": [
                    "CHAVINILLO",
                    "CAHUAC",
                    "CHACABAMBA",
                    "APARICIO POMARES",
                    "JACAS CHICO",
                    "OBAS",
                    "PAMPAMARCA",
                    "CHORAS"
                ]
            }
        ]
    },
    {
        "department": "ICA",
        "provinces": [
            {
                "province": "ICA",
                "districts": [
                    "ICA",
                    "LA TINGUIÑA",
                    "LOS AQUIJES",
                    "OCUCAJE",
                    "PACHACUTEC",
                    "PARCONA",
                    "PUEBLO NUEVO",
                    "SALAS",
                    "SAN JOSE DE LOS MOLINOS",
                    "SAN JUAN BAUTISTA",
                    "SANTIAGO",
                    "SUBTANJALLA",
                    "TATE",
                    "YAUCA DEL ROSARIO"
                ]
            },
            {
                "province": "CHINCHA",
                "districts": [
                    "CHINCHA ALTA",
                    "ALTO LARAN",
                    "CHAVIN",
                    "CHINCHA BAJA",
                    "EL CARMEN",
                    "GROCIO PRADO",
                    "PUEBLO NUEVO",
                    "SAN JUAN DE YANAC",
                    "SAN PEDRO DE HUACARPANA",
                    "SUNAMPE",
                    "TAMBO DE MORA"
                ]
            },
            {
                "province": "NAZCA",
                "districts": [
                    "NAZCA",
                    "CHANGUILLO",
                    "EL INGENIO",
                    "MARCONA",
                    "VISTA ALEGRE"
                ]
            },
            {
                "province": "PALPA",
                "districts": [
                    "PALPA",
                    "LLIPATA",
                    "RIO GRANDE",
                    "SANTA CRUZ",
                    "TIBILLO"
                ]
            },
            {
                "province": "PISCO",
                "districts": [
                    "PISCO",
                    "HUANCANO",
                    "HUMAY",
                    "INDEPENDENCIA",
                    "PARACAS",
                    "SAN ANDRES",
                    "SAN CLEMENTE",
                    "TUPAC AMARU INCA"
                ]
            }
        ]
    },
    {
        "department": "JUNIN",
        "provinces": [
            {
                "province": "HUANCAYO",
                "districts": [
                    "HUANCAYO",
                    "CARHUACALLANGA",
                    "CHACAPAMPA",
                    "CHICCHE",
                    "CHILCA",
                    "CHONGOS ALTO",
                    "CHUPURO",
                    "COLCA",
                    "CULLHUAS",
                    "EL TAMBO",
                    "HUACRAPUQUIO",
                    "HUALHUAS",
                    "HUANCAN",
                    "HUASICANCHA",
                    "HUAYUCACHI",
                    "INGENIO",
                    "PARIAHUANCA",
                    "PILCOMAYO",
                    "PUCARA",
                    "QUICHUAY",
                    "QUILCAS",
                    "SAN AGUSTIN",
                    "SAN JERONIMO DE TUNAN",
                    "SAÑO",
                    "SAPALLANGA",
                    "SICAYA",
                    "SANTO DOMINGO DE ACOBAMBA",
                    "VIQUES"
                ]
            },
            {
                "province": "CONCEPCION",
                "districts": [
                    "CONCEPCION",
                    "ACO",
                    "ANDAMARCA",
                    "CHAMBARA",
                    "COCHAS",
                    "COMAS",
                    "HEROINAS TOLEDO",
                    "MANZANARES",
                    "MARISCAL CASTILLA",
                    "MATAHUASI",
                    "MITO",
                    "NUEVE DE JULIO",
                    "ORCOTUNA",
                    "SAN JOSE DE QUERO",
                    "SANTA ROSA DE OCOPA"
                ]
            },
            {
                "province": "CHANCHAMAYO",
                "districts": [
                    "CHANCHAMAYO",
                    "PERENE",
                    "PICHANAQUI",
                    "SAN LUIS DE SHUARO",
                    "SAN RAMON",
                    "VITOC"
                ]
            },
            {
                "province": "JAUJA",
                "districts": [
                    "JAUJA",
                    "ACOLLA",
                    "APATA",
                    "ATAURA",
                    "CANCHAYLLO",
                    "CURICACA",
                    "EL MANTARO",
                    "HUAMALI",
                    "HUARIPAMPA",
                    "HUERTAS",
                    "JANJAILLO",
                    "JULCAN",
                    "LEONOR ORDOÑEZ",
                    "LLOCLLAPAMPA",
                    "MARCO",
                    "MASMA",
                    "MASMA CHICCHE",
                    "MOLINOS",
                    "MONOBAMBA",
                    "MUQUI",
                    "MUQUIYAUYO",
                    "PACA",
                    "PACCHA",
                    "PANCAN",
                    "PARCO",
                    "POMACANCHA",
                    "RICRAN",
                    "SAN LORENZO",
                    "SAN PEDRO DE CHUNAN",
                    "SAUSA",
                    "SINCOS",
                    "TUNAN MARCA",
                    "YAULI",
                    "YAUYOS"
                ]
            },
            {
                "province": "JUNIN",
                "districts": [
                    "JUNIN",
                    "CARHUAMAYO",
                    "ONDORES",
                    "ULCUMAYO"
                ]
            },
            {
                "province": "SATIPO",
                "districts": [
                    "SATIPO",
                    "COVIRIALI",
                    "LLAYLLA",
                    "MAZAMARI",
                    "PAMPA HERMOSA",
                    "PANGOA",
                    "RIO NEGRO",
                    "RIO TAMBO",
                    "VIZCATAN DEL ENE"
                ]
            },
            {
                "province": "TARMA",
                "districts": [
                    "TARMA",
                    "ACOBAMBA",
                    "HUARICOLCA",
                    "HUASAHUASI",
                    "LA UNION",
                    "PALCA",
                    "PALCAMAYO",
                    "SAN PEDRO DE CAJAS",
                    "TAPO"
                ]
            },
            {
                "province": "YAULI",
                "districts": [
                    "LA OROYA",
                    "CHACAPALPA",
                    "HUAY-HUAY",
                    "MARCAPOMACOCHA",
                    "MOROCOCHA",
                    "PACCHA",
                    "SANTA BARBARA DE CARHUACAYAN",
                    "SANTA ROSA DE SACCO",
                    "SUITUCANCHA",
                    "YAULI"
                ]
            },
            {
                "province": "CHUPACA",
                "districts": [
                    "CHUPACA",
                    "AHUAC",
                    "CHONGOS BAJO",
                    "HUACHAC",
                    "HUAMANCACA CHICO",
                    "SAN JUAN DE YSCOS",
                    "SAN JUAN DE JARPA",
                    "TRES DE DICIEMBRE",
                    "YANACANCHA"
                ]
            }
        ]
    },
    {
        "department": "LA LIBERTAD",
        "provinces": [
            {
                "province": "TRUJILLO",
                "districts": [
                    "TRUJILLO",
                    "EL PORVENIR",
                    "FLORENCIA DE MORA",
                    "HUANCHACO",
                    "LA ESPERANZA",
                    "LAREDO",
                    "MOCHE",
                    "POROTO",
                    "SALAVERRY",
                    "SIMBAL",
                    "VICTOR LARCO HERRERA"
                ]
            },
            {
                "province": "ASCOPE",
                "districts": [
                    "ASCOPE",
                    "CHICAMA",
                    "CHOCOPE",
                    "MAGDALENA DE CAO",
                    "PAIJAN",
                    "RAZURI",
                    "SANTIAGO DE CAO",
                    "CASA GRANDE"
                ]
            },
            {
                "province": "BOLIVAR",
                "districts": [
                    "BOLIVAR",
                    "BAMBAMARCA",
                    "CONDORMARCA",
                    "LONGOTEA",
                    "UCHUMARCA",
                    "UCUNCHA"
                ]
            },
            {
                "province": "CHEPEN",
                "districts": [
                    "CHEPEN",
                    "PACANGA",
                    "PUEBLO NUEVO"
                ]
            },
            {
                "province": "JULCAN",
                "districts": [
                    "JULCAN",
                    "CALAMARCA",
                    "CARABAMBA",
                    "HUASO"
                ]
            },
            {
                "province": "OTUZCO",
                "districts": [
                    "OTUZCO",
                    "AGALLPAMPA",
                    "CHARAT",
                    "HUARANCHAL",
                    "LA CUESTA",
                    "MACHE",
                    "PARANDAY",
                    "SALPO",
                    "SINSICAP",
                    "USQUIL"
                ]
            },
            {
                "province": "PACASMAYO",
                "districts": [
                    "SAN PEDRO DE LLOC",
                    "GUADALUPE",
                    "JEQUETEPEQUE",
                    "PACASMAYO",
                    "SAN JOSE"
                ]
            },
            {
                "province": "PATAZ",
                "districts": [
                    "TAYABAMBA",
                    "BULDIBUYO",
                    "CHILLIA",
                    "HUANCASPATA",
                    "HUAYLILLAS",
                    "HUAYO",
                    "ONGON",
                    "PARCOY",
                    "PATAZ",
                    "PIAS",
                    "SANTIAGO DE CHALLAS",
                    "TAURIJA",
                    "URPAY"
                ]
            },
            {
                "province": "SANCHEZ CARRION",
                "districts": [
                    "HUAMACHUCO",
                    "CHUGAY",
                    "COCHORCO",
                    "CURGOS",
                    "MARCABAL",
                    "SANAGORAN",
                    "SARIN",
                    "SARTIMBAMBA"
                ]
            },
            {
                "province": "SANTIAGO DE CHUCO",
                "districts": [
                    "SANTIAGO DE CHUCO",
                    "ANGASMARCA",
                    "CACHICADAN",
                    "MOLLEBAMBA",
                    "MOLLEPATA",
                    "QUIRUVILCA",
                    "SANTA CRUZ DE CHUCA",
                    "SITABAMBA"
                ]
            },
            {
                "province": "GRAN CHIMU",
                "districts": [
                    "CASCAS",
                    "LUCMA",
                    "MARMOT",
                    "SAYAPULLO"
                ]
            },
            {
                "province": "VIRU",
                "districts": [
                    "VIRU",
                    "CHAO",
                    "GUADALUPITO"
                ]
            }
        ]
    },
    {
        "department": "LAMBAYEQUE",
        "provinces": [
            {
                "province": "CHICLAYO",
                "districts": [
                    "CHICLAYO",
                    "CHONGOYAPE",
                    "ETEN",
                    "ETEN PUERTO",
                    "JOSE LEONARDO ORTIZ",
                    "LA VICTORIA",
                    "LAGUNAS",
                    "MONSEFU",
                    "NUEVA ARICA",
                    "OYOTUN",
                    "PICSI",
                    "PIMENTEL",
                    "REQUE",
                    "SANTA ROSA",
                    "SAÑA",
                    "CAYALTI",
                    "PATAPO",
                    "POMALCA",
                    "PUCALA",
                    "TUMAN"
                ]
            },
            {
                "province": "FERREÑAFE",
                "districts": [
                    "FERREÑAFE",
                    "CAÑARIS",
                    "INCAHUASI",
                    "MANUEL ANTONIO MESONES MURO",
                    "PITIPO",
                    "PUEBLO NUEVO"
                ]
            },
            {
                "province": "LAMBAYEQUE",
                "districts": [
                    "LAMBAYEQUE",
                    "CHOCHOPE",
                    "ILLIMO",
                    "JAYANCA",
                    "MOCHUMI",
                    "MORROPE",
                    "MOTUPE",
                    "OLMOS",
                    "PACORA",
                    "SALAS",
                    "SAN JOSE",
                    "TUCUME"
                ]
            }
        ]
    },
    {
        "department": "LIMA",
        "provinces": [
            {
                "province": "LIMA",
                "districts": [
                    "LIMA",
                    "ANCÓN",
                    "ATE",
                    "BARRANCO",
                    "BREÑA",
                    "CARABAYLLO",
                    "CHACLACAYO",
                    "CHORRILLOS",
                    "CIENEGUILLA",
                    "COMAS",
                    "EL AGUSTINO",
                    "INDEPENDENCIA",
                    "JESUS MARIA",
                    "LA MOLINA",
                    "LA VICTORIA",
                    "LINCE",
                    "LOS OLIVOS",
                    "LURIGANCHO",
                    "LURIN",
                    "MAGDALENA DEL MAR",
                    "PUEBLO LIBRE",
                    "MIRAFLORES",
                    "PACHACAMAC",
                    "PUCUSANA",
                    "PUENTE PIEDRA",
                    "PUNTA HERMOSA",
                    "PUNTA NEGRA",
                    "RIMAC",
                    "SAN BARTOLO",
                    "SAN BORJA",
                    "SAN ISIDRO",
                    "SAN JUAN DE LURIGANCHO",
                    "SAN JUAN DE MIRAFLORES",
                    "SAN LUIS",
                    "SAN MARTIN DE PORRES",
                    "SAN MIGUEL",
                    "SANTA ANITA",
                    "SANTA MARIA DEL MAR",
                    "SANTA ROSA",
                    "SANTIAGO DE SURCO",
                    "SURQUILLO",
                    "VILLA EL SALVADOR",
                    "VILLA MARIA DEL TRIUNFO",
                    "SANTA MARIA DE HUACHIPA"
                ]
            },
            {
                "province": "BARRANCA",
                "districts": [
                    "BARRANCA",
                    "PARAMONGA",
                    "PATIVILCA",
                    "SUPE",
                    "SUPE PUERTO"
                ]
            },
            {
                "province": "CAJATAMBO",
                "districts": [
                    "CAJATAMBO",
                    "COPA",
                    "GORGOR",
                    "HUANCAPON",
                    "MANAS"
                ]
            },
            {
                "province": "CANTA",
                "districts": [
                    "CANTA",
                    "ARAHUAY",
                    "HUAMANTANGA",
                    "HUAROS",
                    "LACHAQUI",
                    "SAN BUENAVENTURA",
                    "SANTA ROSA DE QUIVES"
                ]
            },
            {
                "province": "CAÑETE",
                "districts": [
                    "SAN VICENTE DE CAÑETE",
                    "ASIA",
                    "CALANGO",
                    "CERRO AZUL",
                    "CHILCA",
                    "COAYLLO",
                    "IMPERIAL",
                    "LUNAHUANA",
                    "MALA",
                    "NUEVO IMPERIAL",
                    "PACARAN",
                    "QUILMANA",
                    "SAN ANTONIO",
                    "SAN LUIS",
                    "SANTA CRUZ DE FLORES",
                    "ZUÑIGA"
                ]
            },
            {
                "province": "HUARAL",
                "districts": [
                    "HUARAL",
                    "ATAVILLOS ALTO",
                    "ATAVILLOS BAJO",
                    "AUCALLAMA",
                    "CHANCAY",
                    "IHUARI",
                    "LAMPIAN",
                    "PACARAOS",
                    "SAN MIGUEL DE ACOS",
                    "SANTA CRUZ DE ANDAMARCA",
                    "SUMBILCA",
                    "VEINTISIETE DE NOVIEMBRE"
                ]
            },
            {
                "province": "HUAROCHIRI",
                "districts": [
                    "MATUCANA",
                    "ANTIOQUIA",
                    "CALLAHUANCA",
                    "CARAMPOMA",
                    "CHICLA",
                    "CUENCA",
                    "HUACHUPAMPA",
                    "HUANZA",
                    "HUAROCHIRI",
                    "LAHUAYTAMBO",
                    "LANGA",
                    "LARAOS",
                    "MARIATANA",
                    "RICARDO PALMA",
                    "SAN ANDRES DE TUPICOCHA",
                    "SAN ANTONIO",
                    "SAN BARTOLOME",
                    "SAN DAMIAN",
                    "SAN JUAN DE IRIS",
                    "SAN JUAN DE TANTARANCHE",
                    "SAN LORENZO DE QUINTI",
                    "SAN MATEO",
                    "SAN MATEO DE OTAO",
                    "SAN PEDRO DE CASTA",
                    "SAN PEDRO DE HUANCAYRE",
                    "SANGALLAYA",
                    "SANTA CRUZ DE COCACHACRA",
                    "SANTA EULALIA",
                    "SANTIAGO DE ANCHUCAYA",
                    "SANTIAGO DE TUNA",
                    "SANTO DOMINGO DE LOS OLLEROS",
                    "SURCO"
                ]
            },
            {
                "province": "HUAURA",
                "districts": [
                    "HUACHO",
                    "AMBAR",
                    "CALETA DE CARQUIN",
                    "CHECRAS",
                    "HUALMAY",
                    "HUAURA",
                    "LEONCIO PRADO",
                    "PACCHO",
                    "SANTA LEONOR",
                    "SANTA MARIA",
                    "SAYAN",
                    "VEGUETA"
                ]
            },
            {
                "province": "OYON",
                "districts": [
                    "OYON",
                    "ANDAJES",
                    "CAUJUL",
                    "COCHAMARCA",
                    "NAVAN",
                    "PACHANGARA"
                ]
            },
            {
                "province": "YAUYOS",
                "districts": [
                    "YAUYOS",
                    "ALIS",
                    "AYAUCA",
                    "AYAVIRI",
                    "AZANGARO",
                    "CACRA",
                    "CARANIA",
                    "CATAHUASI",
                    "CHOCOS",
                    "COCHAS",
                    "COLONIA",
                    "HONGOS",
                    "HUAMPARA",
                    "HUANCAYA",
                    "HUANGASCAR",
                    "HUANTAN",
                    "HUAÑEC",
                    "LARAOS",
                    "LINCHA",
                    "MADEAN",
                    "MIRAFLORES",
                    "OMAS",
                    "PUTINZA",
                    "QUINCHES",
                    "QUINOCAY",
                    "SAN JOAQUIN",
                    "SAN PEDRO DE PILAS",
                    "TANTA",
                    "TAURIPAMPA",
                    "TOMAS",
                    "TUPE",
                    "VIÑAC",
                    "VITIS"
                ]
            }
        ]
    },
    {
        "department": "LORETO",
        "provinces": [
            {
                "province": "MAYNAS",
                "districts": [
                    "IQUITOS",
                    "ALTO NANAY",
                    "FERNANDO LORES",
                    "INDIANA",
                    "LAS AMAZONAS",
                    "MAZAN",
                    "NAPO",
                    "PUNCHANA",
                    "TORRES CAUSANA",
                    "BELEN",
                    "SAN JUAN BAUTISTA",
                    "PUTUMAYO",
                    "TENIENTE MANUEL CLAVERO"
                ]
            },
            {
                "province": "ALTO AMAZONAS",
                "districts": [
                    "YURIMAGUAS",
                    "BALSAPUERTO",
                    "JEBEROS",
                    "LAGUNAS",
                    "SANTA CRUZ",
                    "TENIENTE CESAR LOPEZ ROJAS"
                ]
            },
            {
                "province": "LORETO",
                "districts": [
                    "NAUTA",
                    "PARINARI",
                    "TIGRE",
                    "TROMPETEROS",
                    "URARINAS"
                ]
            },
            {
                "province": "MARISCAL RAMON CASTILLA",
                "districts": [
                    "RAMON CASTILLA",
                    "PEBAS",
                    "YAVARI",
                    "SAN PABLO"
                ]
            },
            {
                "province": "REQUENA",
                "districts": [
                    "REQUENA",
                    "ALTO TAPICHE",
                    "CAPELO",
                    "EMILIO SAN MARTIN",
                    "MAQUIA",
                    "PUINAHUA",
                    "SAQUENA",
                    "SOPLIN",
                    "TAPICHE",
                    "JENARO HERRERA",
                    "YAQUERANA"
                ]
            },
            {
                "province": "UCAYALI",
                "districts": [
                    "CONTAMANA",
                    "INAHUAYA",
                    "PADRE MARQUEZ",
                    "PAMPA HERMOSA",
                    "SARAYACU",
                    "VARGAS GUERRA"
                ]
            },
            {
                "province": "DATEM DEL MARAÑÓN",
                "districts": [
                    "BARRANCA",
                    "CAHUAPANAS",
                    "MANSERICHE",
                    "MORONA",
                    "PASTAZA",
                    "ANDOAS"
                ]
            },
            {
                "province": "PUTUMAYO",
                "districts": [
                    "PUTUMAYO",
                    "ROSA PANDURO",
                    "TENIENTE MANUEL CLAVERO",
                    "YAGUAS"
                ]
            }
        ]
    },
    {
        "department": "TUMBES",
        "provinces": [
            {
                "province": "CONTRALMIRANTE VILLAR",
                "districts": [
                    "ZORRITOS",
                    "CASITAS",
                    "CANOAS DE PUNTA SAL"
                ]
            },
            {
                "province": "ZARUMILLA",
                "districts": [
                    "ZARUMILLA",
                    "AGUAS VERDES",
                    "MATAPALO",
                    "PAPAYAL"
                ]
            },
            {
                "province": "TUMBES",
                "districts": [
                    "TUMBES",
                    "CORRALES",
                    "LA CRUZ",
                    "PAMPAS DE HOSPITAL",
                    "SAN JACINTO",
                    "SAN JUAN DE LA VIRGEN"
                ]
            }
        ]
    },
    {
        "department": "UCAYALI",
        "provinces": [
            {
                "province": "CORONEL PORTILLO",
                "districts": [
                    "CALLERIA",
                    "CAMPOVERDE",
                    "IPARIA",
                    "MASISEA",
                    "YARINACOCHA",
                    "NUEVA REQUENA",
                    "MANANTAY"
                ]
            },
            {
                "province": "ATALAYA",
                "districts": [
                    "RAYMONDI",
                    "SEPAHUA",
                    "TAHUANIA",
                    "YURUA"
                ]
            },
            {
                "province": "PADRE ABAD",
                "districts": [
                    "PADRE ABAD",
                    "IRAZOLA",
                    "CURIMANA",
                    "NESHUYA",
                    "ALEXANDER VON HUMBOLDT",
                    "HUIPOCA",
                    "BOQUERON"
                ]
            },
            {
                "province": "PURUS",
                "districts": [
                    "PURUS"
                ]
            }
        ]
    },
    {
        "department": "MOQUEGUA",
        "provinces": [
            {
                "province": "MARISCAL NIETO",
                "districts": [
                    "SAN ANTONIO",
                    "MOQUEGUA",
                    "CARUMAS",
                    "CUCHUMBAYA",
                    "SAMEGUA",
                    "SAN CRISTOBAL",
                    "TORATA"
                ]
            },
            {
                "province": "GENERAL SANCHEZ CERRO",
                "districts": [
                    "OMATE",
                    "CHOJATA",
                    "COALAQUE",
                    "ICHUÑA",
                    "LA CAPILLA",
                    "LLOQUE",
                    "MATALAQUE",
                    "PUQUINA",
                    "QUINISTAQUILLAS",
                    "UBINAS",
                    "YUNGA"
                ]
            },
            {
                "province": "ILO",
                "districts": [
                    "ILO",
                    "EL ALGARROBAL",
                    "PACOCHA"
                ]
            }
        ]
    },
    {
        "department": "SAN MARTIN",
        "provinces": [
            {
                "province": "TOCACHE",
                "districts": [
                    "SANTA LUCIA",
                    "TOCACHE",
                    "NUEVO PROGRESO",
                    "POLVORA",
                    "SHUNTE",
                    "UCHIZA"
                ]
            },
            {
                "province": "MOYOBAMBA",
                "districts": [
                    "MOYOBAMBA",
                    "CALZADA",
                    "HABANA",
                    "JEPELACIO",
                    "SORITOR",
                    "YANTALO"
                ]
            },
            {
                "province": "BELLAVISTA",
                "districts": [
                    "BELLAVISTA",
                    "ALTO BIAVO",
                    "BAJO BIAVO",
                    "HUALLAGA",
                    "SAN PABLO",
                    "SAN RAFAEL"
                ]
            },
            {
                "province": "EL DORADO",
                "districts": [
                    "SAN JOSE DE SISA",
                    "AGUA BLANCA",
                    "SAN MARTIN",
                    "SANTA ROSA",
                    "SHATOJA"
                ]
            },
            {
                "province": "HUALLAGA",
                "districts": [
                    "SAPOSOA",
                    "ALTO SAPOSOA",
                    "EL ESLABON",
                    "PISCOYACU",
                    "SACANCHE",
                    "TINGO DE SAPOSOA"
                ]
            },
            {
                "province": "LAMAS",
                "districts": [
                    "LAMAS",
                    "ALONSO DE ALVARADO",
                    "BARRANQUITA",
                    "CAYNARACHI",
                    "CUÑUMBUQUI",
                    "PINTO RECODO",
                    "RUMISAPA",
                    "SAN ROQUE DE CUMBAZA",
                    "SHANAO",
                    "TABALOSOS",
                    "ZAPATERO"
                ]
            },
            {
                "province": "MARISCAL CACERES",
                "districts": [
                    "JUANJUI",
                    "CAMPANILLA",
                    "HUICUNGO",
                    "PACHIZA",
                    "PAJARILLO"
                ]
            },
            {
                "province": "PICOTA",
                "districts": [
                    "PICOTA",
                    "BUENOS AIRES",
                    "CASPISAPA",
                    "PILLUANA",
                    "PUCACACA",
                    "SAN CRISTOBAL",
                    "SAN HILARION",
                    "SHAMBOYACU",
                    "TINGO DE PONASA",
                    "TRES UNIDOS"
                ]
            },
            {
                "province": "RIOJA",
                "districts": [
                    "RIOJA",
                    "AWAJUN",
                    "ELIAS SOPLIN VARGAS",
                    "NUEVA CAJAMARCA",
                    "PARDO MIGUEL",
                    "POSIC",
                    "SAN FERNANDO",
                    "YORONGOS",
                    "YURACYACU"
                ]
            },
            {
                "province": "SAN MARTIN",
                "districts": [
                    "TARAPOTO",
                    "ALBERTO LEVEAU",
                    "CACATACHI",
                    "CHAZUTA",
                    "CHIPURANA",
                    "EL PORVENIR",
                    "HUIMBAYOC",
                    "JUAN GUERRA",
                    "LA BANDA DE SHILCAYO",
                    "MORALES",
                    "PAPAPLAYA",
                    "SAN ANTONIO",
                    "SAUCE",
                    "SHAPAJA"
                ]
            }
        ]
    },
    {
        "department": "MADRE DE DIOS",
        "provinces": [
            {
                "province": "TAMBOPATA",
                "districts": [
                    "TAMBOPATA",
                    "INAMBARI",
                    "LAS PIEDRAS",
                    "LABERINTO"
                ]
            },
            {
                "province": "MANU",
                "districts": [
                    "MANU",
                    "FITZCARRALD",
                    "MADRE DE DIOS",
                    "HUEPETUHE"
                ]
            },
            {
                "province": "TAHUAMANU",
                "districts": [
                    "IÑAPARI",
                    "IBERIA",
                    "TAHUAMANU"
                ]
            }
        ]
    },
    {
        "department": "PASCO",
        "provinces": [
            {
                "province": "PASCO",
                "districts": [
                    "CHAUPIMARCA",
                    "HUACHON",
                    "HUARIACA",
                    "HUAYLLAY",
                    "NINACACA",
                    "PALLANCHACRA",
                    "PAUCARTAMBO",
                    "SAN FRANCISCO DE ASIS DE YARUSYACAN",
                    "SIMON BOLIVAR",
                    "TICLACAYAN",
                    "TINYAHUARCO",
                    "VICCO",
                    "YANACANCHA"
                ]
            },
            {
                "province": "DANIEL ALCIDES CARRION",
                "districts": [
                    "YANAHUANCA",
                    "CHACAYAN",
                    "GOYLLARISQUIZGA",
                    "PAUCAR",
                    "SAN PEDRO DE PILLAO",
                    "SANTA ANA DE TUSI",
                    "TAPUC",
                    "VILCABAMBA"
                ]
            },
            {
                "province": "OXAPAMPA",
                "districts": [
                    "OXAPAMPA",
                    "CHONTABAMBA",
                    "HUANCABAMBA",
                    "PALCAZU",
                    "POZUZO",
                    "PUERTO BERMUDEZ",
                    "VILLA RICA",
                    "CONSTITUCION"
                ]
            }
        ]
    },
    {
        "department": "PIURA",
        "provinces": [
            {
                "province": "PIURA",
                "districts": [
                    "PIURA",
                    "CASTILLA",
                    "CATACAOS",
                    "CURA MORI",
                    "EL TALLAN",
                    "LA ARENA",
                    "LA UNION",
                    "LAS LOMAS",
                    "TAMBO GRANDE",
                    "VEINTISEIS DE OCTUBRE"
                ]
            },
            {
                "province": "AYABACA",
                "districts": [
                    "AYABACA",
                    "FRIAS",
                    "JILILI",
                    "LAGUNAS",
                    "MONTERO",
                    "PACAIPAMPA",
                    "PAIMAS",
                    "SAPILLICA",
                    "SICCHEZ",
                    "SUYO"
                ]
            },
            {
                "province": "HUANCABAMBA",
                "districts": [
                    "HUANCABAMBA",
                    "CANCHAQUE",
                    "EL CARMEN DE LA FRONTERA",
                    "HUARMACA",
                    "LALAQUIZ",
                    "SAN MIGUEL DE EL FAIQUE",
                    "SONDOR",
                    "SONDORILLO"
                ]
            },
            {
                "province": "MORROPON",
                "districts": [
                    "CHULUCANAS",
                    "BUENOS AIRES",
                    "CHALACO",
                    "LA MATANZA",
                    "MORROPON",
                    "SALITRAL",
                    "SAN JUAN DE BIGOTE",
                    "SANTA CATALINA DE MOSSA",
                    "SANTO DOMINGO",
                    "YAMANGO"
                ]
            },
            {
                "province": "PAITA",
                "districts": [
                    "PAITA",
                    "AMOTAPE",
                    "ARENAL",
                    "COLAN",
                    "LA HUACA",
                    "TAMARINDO",
                    "VICHAYAL"
                ]
            },
            {
                "province": "SULLANA",
                "districts": [
                    "SULLANA",
                    "BELLAVISTA",
                    "IGNACIO ESCUDERO",
                    "LANCONES",
                    "MARCAVELICA",
                    "MIGUEL CHECA",
                    "QUERECOTILLO",
                    "SALITRAL"
                ]
            },
            {
                "province": "TALARA",
                "districts": [
                    "PARIÑAS",
                    "EL ALTO",
                    "LA BREA",
                    "LOBITOS",
                    "LOS ORGANOS",
                    "MANCORA"
                ]
            },
            {
                "province": "SECHURA",
                "districts": [
                    "SECHURA",
                    "BELLAVISTA DE LA UNION",
                    "BERNAL",
                    "CRISTO NOS VALGA",
                    "VICE",
                    "RINCONADA LLICUAR"
                ]
            }
        ]
    },
    {
        "department": "PUNO",
        "provinces": [
            {
                "province": "PUNO",
                "districts": [
                    "PUNO",
                    "ACORA",
                    "AMANTANI",
                    "ATUNCOLLA",
                    "CAPACHICA",
                    "CHUCUITO",
                    "COATA",
                    "HUATA",
                    "MAÑAZO",
                    "PAUCARCOLLA",
                    "PICHACANI",
                    "PLATERIA",
                    "SAN ANTONIO",
                    "TIQUILLACA",
                    "VILQUE"
                ]
            },
            {
                "province": "AZANGARO",
                "districts": [
                    "AZANGARO",
                    "ACHAYA",
                    "ARAPA",
                    "ASILLO",
                    "CAMINACA",
                    "CHUPA",
                    "JOSE DOMINGO CHOQUEHUANCA",
                    "MUÑANI",
                    "POTONI",
                    "SAMAN",
                    "SAN ANTON",
                    "SAN JOSE",
                    "SAN JUAN DE SALINAS",
                    "SANTIAGO DE PUPUJA",
                    "TIRAPATA"
                ]
            },
            {
                "province": "CARABAYA",
                "districts": [
                    "MACUSANI",
                    "AJOYANI",
                    "AYAPATA",
                    "COASA",
                    "CORANI",
                    "CRUCERO",
                    "ITUATA",
                    "OLLACHEA",
                    "SAN GABAN",
                    "USICAYOS"
                ]
            },
            {
                "province": "CHUCUITO",
                "districts": [
                    "JULI",
                    "DESAGUADERO",
                    "HUACULLANI",
                    "KELLUYO",
                    "PISACOMA",
                    "POMATA",
                    "ZEPITA"
                ]
            },
            {
                "province": "EL COLLAO",
                "districts": [
                    "ILAVE",
                    "CAPAZO",
                    "PILCUYO",
                    "SANTA ROSA",
                    "CONDURIRI"
                ]
            },
            {
                "province": "HUANCANE",
                "districts": [
                    "HUANCANE",
                    "COJATA",
                    "HUATASANI",
                    "INCHUPALLA",
                    "PUSI",
                    "ROSASPATA",
                    "TARACO",
                    "VILQUE CHICO"
                ]
            },
            {
                "province": "LAMPA",
                "districts": [
                    "LAMPA",
                    "CABANILLA",
                    "CALAPUJA",
                    "NICASIO",
                    "OCUVIRI",
                    "PALCA",
                    "PARATIA",
                    "PUCARA",
                    "SANTA LUCIA",
                    "VILAVILA"
                ]
            },
            {
                "province": "MELGAR",
                "districts": [
                    "AYAVIRI",
                    "ANTAUTA",
                    "CUPI",
                    "LLALLI",
                    "MACARI",
                    "NUÑOA",
                    "ORURILLO",
                    "SANTA ROSA",
                    "UMACHIRI"
                ]
            },
            {
                "province": "MOHO",
                "districts": [
                    "MOHO",
                    "CONIMA",
                    "HUAYRAPATA",
                    "TILALI"
                ]
            },
            {
                "province": "SAN ANTONIO DE PUTINA",
                "districts": [
                    "PUTINA",
                    "ANANEA",
                    "PEDRO VILCA APAZA",
                    "QUILCAPUNCU",
                    "SINA"
                ]
            },
            {
                "province": "SAN ROMAN",
                "districts": [
                    "JULIACA",
                    "CABANA",
                    "CABANILLAS",
                    "CARACOTO",
                    "SAN MIGUEL"
                ]
            },
            {
                "province": "SANDIA",
                "districts": [
                    "SANDIA",
                    "CUYOCUYO",
                    "LIMBANI",
                    "PATAMBUCO",
                    "PHARA",
                    "QUIACA",
                    "SAN JUAN DEL ORO",
                    "YANAHUAYA",
                    "ALTO INAMBARI",
                    "SAN PEDRO DE PUTINA PUNCO"
                ]
            },
            {
                "province": "YUNGUYO",
                "districts": [
                    "YUNGUYO",
                    "ANAPIA",
                    "COPANI",
                    "CUTURAPI",
                    "OLLARAYA",
                    "TINICACHI",
                    "UNICACHI"
                ]
            }
        ]
    },
    {
        "department": "TACNA",
        "provinces": [
            {
                "province": "TACNA",
                "districts": [
                    "TACNA",
                    "ALTO DE LA ALIANZA",
                    "CALANA",
                    "CIUDAD NUEVA",
                    "INCLAN",
                    "PACHIA",
                    "PALCA",
                    "POCOLLAY",
                    "SAMA",
                    "CORONEL GREGORIO ALBARRACIN LANCHIP",
                    "LA YARADA LOS PALOS"
                ]
            },
            {
                "province": "CANDARAVE",
                "districts": [
                    "CANDARAVE",
                    "CAIRANI",
                    "CAMILACA",
                    "CURIBAYA",
                    "HUANUARA",
                    "QUILAHUANI"
                ]
            },
            {
                "province": "JORGE BASADRE",
                "districts": [
                    "LOCUMBA",
                    "ILABAYA",
                    "ITE"
                ]
            },
            {
                "province": "TARATA",
                "districts": [
                    "TARATA",
                    "CHUCATAMANI",
                    "ESTIQUE",
                    "ESTIQUE-PAMPA",
                    "SITAJARA",
                    "SUSAPAYA",
                    "TARUCACHI",
                    "TICACO"
                ]
            }
        ]
    }
];