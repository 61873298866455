import React, { useEffect, useState, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { apiRepository } from '../../../../api/apiRepository';
import { AuthContext } from '../../../../core/context/AuthContext';
import { handleFormSubmit } from '../../../../core/services/formService';
import { validateUser } from '../../../../core/form/validationForm';
import UserAvatar from '../../common/userAvatar/userAvatar';
import UpdatePhotoModal from '../../common/modals/confirm-update-photo-modal';
import { customerSidebarData, trainerSidebarData } from './sidebarData';
import { useUserData } from '../../../../core/hooks/getData/useUserData';
import { usePaymentDays } from '../../../../core/hooks/getData/usePaymentDays';
import { convertDateToLocalDateString, convertStringToLocalDate, getCurrentLocalDate } from "../../../../core/utils/dateUtils";
import * as Icon from 'react-feather';

const CustomerSideBar = (updateDays: any) => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const location = useLocation();
  const { formData: userData, refetchUserData: fetchUser } = useUserData(user?.userId || 0);
  const { paymentDays: classesQuantity, refetch: fetchDays } = usePaymentDays(user?.userId || 0);
  const [userImage, setUserImage] = useState<File | string>();
  const [subdroptoggle, setsubdroptoggle] = useState();
  const [showModal, setShowModal] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const [loading, setLoading] = useState();
  const [filteredClasses, setFilteredClasses] = useState([]);

  useEffect(() => {
    if (userData?.image) {
      setUserImage(userData.image);
    }
  }, [userData]);

  useEffect(() => {
    if (updateDays.service === true || updateDays.service === false) {
      fetchDays();
    }
  }, [updateDays]); 

  useEffect(() => {
    return () => {
      location.pathname.includes('settings') ? setsubdroptoggle(true) : setsubdroptoggle(false);
    }
  }, [location.pathname]);

  const activeRouterPath = (link: string) => {
    return link === location.pathname;
  };

  const sidebarData = () => {
    switch (user?.role) {
      case 'admin':
        return [...customerSidebarData, ...trainerSidebarData];
      case 'trainer':
        return trainerSidebarData;
      default:
        return customerSidebarData;
    }
  };

  const handleUpdatePhoto = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event) => {
      const file = event.target.files[0];
      if (file) {
        setSelectedFile(file);
        setShowModal(true);
      }
    };
    input.click();
  };

  const handleConfirmUpdate = async () => {
    if (selectedFile) {
      setLoading(true);

      const handleClose = () => {
        setShowModal(false);
        setSelectedFile(null);
      };

      const onUpdateSuccess = () => {
        fetchUser();
      };

      try {
        const errors = await handleFormSubmit(
          userData.id,
          userData,
          validateUser,
          {
            create: apiRepository.createUser,
            update: apiRepository.updateUser,
          },
          () => {
            if (onUpdateSuccess) onUpdateSuccess();
            handleClose();
          },
          (data) => ({
            ...data,
            image: selectedFile,
            birthdate: convertDateToLocalDateString(data.birthdate),
            password: '',
          }),
          "Ocurrió un error al intentar guardar la foto de perfil. Inténtalo de nuevo.",
          true
        );

        if (errors) {
          console.error(errors);
        }
      } catch (error) {
        console.error("Error al procesar la foto de perfil:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!classesQuantity) return;
    const currentDate = getCurrentLocalDate();
    const filtered = classesQuantity.filter(item => {
      const isMembership = item.type === 'Membresias Premium' || item.type === 'Membresias';
      
      if (isMembership) {
        // Para membresias, solo verificar la fecha
        return new Date(item.endDate) >= currentDate;
      } else {
        // Para otros tipos, verificar tanto fecha como cantidad
        return item.quantity > 0 && new Date(item.endDate) >= currentDate;
      }
    });
    setFilteredClasses(filtered);
  }, [classesQuantity]);

  return (
    <>
      <StickyBox>
        <div className="card card-shadow slider-min user-sidebar mb-4">
          <div className="card-header user-sidebar-header mb-3">
            <div className="d-flex justify-content-center align-items-center flex-column">
              <UserAvatar image={userImage || ''} onUpdate={handleUpdatePhoto} />
              <h6 className="mb-2">{user?.username || 'Inicia tu Sesión'}</h6>
            </div>
          </div>
          {user?.role == 'customer' && (
            <div className="card-body p-0 mt-1 mb-1">
              <h5 className="mb-3">Clases Disponibles</h5>
              {filteredClasses.length > 0 && (
                filteredClasses.map((item) => (
                  <div key={item.id} className="class-card mb-3 p-3 border rounded shadow-sm">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <span className="badge bg-primary">{item.type}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p className="m-0">
                        {item.type === 'Membresias Premium' ? (
                          <>Cantidad: <i className="fas fa-crown text-warning" /></>
                        ) : item.type === 'Membresias' ? (
                          <>Cantidad: <i className="fas fa-infinity text-primary" /></>
                        ) : (
                          <>Cantidad: {item.quantity}</>
                        )}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-0">
                      <span className="">Vence:</span>
                      <span className={`badge ${item.endDate ? 'bg-success' : 'bg-secondary'} text-white`}>
                        {item.endDate ? convertStringToLocalDate(item.endDate).toLocaleDateString() : 'N/A'}
                      </span>
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
          <div className="card-body user-sidebar-body p-0">
            <ul>
              {sidebarData().map((item, index) => (
                <li className="mb-4" key={index}>
                  <Link
                    to={item.link}
                    className={`d-flex align-items-center ${activeRouterPath(item.link) && 'active'}`}
                  >
                    {item.icon}
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <UpdatePhotoModal
          show={showModal}
          onClose={() => setShowModal(false)}
          onConfirm={handleConfirmUpdate}
          loading={loading}
        />
      </StickyBox>

    </>
  );
};

export default CustomerSideBar;