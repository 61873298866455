import { useState } from "react";
import { handleError } from '../../../core/utils/errorUtils';
import { createPreference } from '../../../core/services/mercadoPagoService';
import { isUserLoggedIn, validateCartCombinations } from "../../utils/cartUtils";
import { apiRepository } from "../../../api/apiRepository";
import { openModal } from "../../utils/modalsUtils";
import { createPaymentInfo, preparePaymentDetails } from "../../utils/paymentUtils";

export const useConfirmPay = (user: any, cart: any, customerCart: any, couponDiscount: any, token: string) => {
  const [loadingPay, setLoadingPay] = useState<boolean>(false);
  const [info, setInfo] = useState<any[]>([]);

  const validateAndRedirect = async (paymentInfo: any, token: string) => {
    try {
      setInfo([]);
      await apiRepository.validatePackages(paymentInfo);
      const link = await createPreference(customerCart, couponDiscount, token, user);
      window.location.href = link;
    } catch (error) {
      handleError(error, setInfo, "error");
    } finally {
      setLoadingPay(false);
    }
  };

  const confirmPay = async () => {
    setInfo([]);
    const informationSucces: string[] = [];

    if (!isUserLoggedIn(user, informationSucces)) {
      openModal("login-modal");
      return;
    }

    setLoadingPay(true);
    validateCartCombinations(cart, informationSucces);

    if (informationSucces.length > 0) {
      setInfo(informationSucces);
      setLoadingPay(false);
      return;
    }

    const paymentDetails = preparePaymentDetails(customerCart.items);
    const paymentInfo = createPaymentInfo(user, paymentDetails, customerCart.codesCoupons);
    await validateAndRedirect(paymentInfo, token);
  };

  return { confirmPay, loadingPay, info, setInfo };
};