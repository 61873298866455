import { useState } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { ValidationError } from '../../form/validationForm';
import { findProductInCart } from '../../utils/couponUtils';
import { UseCouponValidationOptions } from '../../Interface/hooksInterface';

export const useCouponValidation = ({ cart, setCouponDiscount, generalCouponUsed, setGeneralCouponUsed, couponCode }: UseCouponValidationOptions) => {
  const [info, setInfo] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const validateCode = async () => {
    try {
      setLoading(true);
      setInfo([]);

      const response = await apiRepository.validateCouponCode(couponCode);
      const couponData = response.data.data;

      if (!couponData) {
        setInfo([{ field: 'error', message: "El cupón no es válido." }]);
        return;
      }

      const isGeneral = !couponData.typeGeneralProduct;
      const discountType = couponData.typeDiscount;

      if (isGeneral) {
        if (generalCouponUsed) {
          setInfo([{ field: 'error', message: "Solo se puede aplicar un cupón de tipo general." }]);
          return;
        }

        const discount = discountType
          ? couponData.amount / 100
          : couponData.amount;

        setCouponDiscount((prev) => ({
          ...prev,
          generalDiscount: {
            couponName: couponData.code,
            couponDiscount: discount,
            type: discountType ? "percentage" : "fixed",
          },
        }));

        setGeneralCouponUsed(true);
        setInfo([{ field: 'success', message: "¡Cupón general válido!" }]);
      } else {
        if (!couponData.packetId) {
          setInfo([{ field: 'error', message: "El cupón no es válido para productos específicos." }]);
          return;
        }

        const alreadyUsed = (setCouponDiscount.specificDiscount || []).some(
          (discount: any) => discount.packageId === couponData.packetId
        );

        if (alreadyUsed) {
          setInfo([{ field: 'error', message: "Este cupón ya ha sido aplicado al producto seleccionado." }]);
          return;
        }

        const productInCart = findProductInCart(cart, couponData.packetId);

        if (!productInCart) {
          setInfo([{ field: 'error', message: "El cupón no es válido para los productos seleccionados." }]);
          return;
        }

        const discount = discountType
          ? couponData.amount / 100
          : couponData.amount;

        if (discount > productInCart.price) {
          setInfo([{ field: 'error', message: "El descuento es mayor al precio del producto." }]);
          return;
        }

        setCouponDiscount((prev) => ({
          ...prev,
          specificDiscount: [
            ...(prev.specificDiscount || []),
            {
              couponName: couponData.packetName,
              couponDiscount: discount,
              type: discountType ? "percentage" : "fixed",
              packageId: couponData.packetId,
              packetName: couponData.packetName,
              couponCode: couponData.code,
            },
          ],
        }));

        setInfo([{ field: 'success', message: `¡Cupón válido para el producto ${couponData.packetName || "seleccionado"}!` }]);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.message?.replace(/^Internal server error: /, '') ||
        "Ocurrió un error al intentar validar el cupón. Inténtalo de nuevo.";
      setInfo([{ field: 'error', message: errorMessage }]);
    } finally {
      setLoading(false);
    }
  };

  const removeCoupon = (itemId: string) => {
    setInfo([]);
    setCouponDiscount((prev) => {
      const specificDiscount = prev.specificDiscount?.filter((discount) => discount.packageId !== itemId) || [];
      const generalDiscount = itemId === "general" ? null : prev.generalDiscount;

      return {
        ...prev,
        specificDiscount,
        generalDiscount,
      };
    });

    if (itemId === "general") {
      setGeneralCouponUsed(false);
    }

    setInfo([{ field: 'success', message: "Cupón retirado con éxito." }]);
  };

  return { validateCode, info, loading, removeCoupon, setInfo };
};