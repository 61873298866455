import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { GiftCard } from '../../../../core/Interface/repositoryInterfaces';
import { validateGiftCard, ValidationError } from '../../../../core/form/validationForm';
import { InputField, CalendarField, DropdownField, AutoCompleteDropdown, SwitchField } from '../input/inputField';
import { handleChange, formatDatetoString, dateStandard } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import { TYPE_SUB_SERVICE } from '../../../../core/data/options/options';
import BaseModal from './baseModal';

export interface UpdateGiftCardModalProps {
    selectedGiftCard: GiftCard;
    onUpdateSuccess?: () => void;
}

const GiftCardModal: React.FC<UpdateGiftCardModalProps> = ({ selectedGiftCard, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedGiftCard ? selectedGiftCard.id : -1);
    const [giftCardData, setGiftCardData] = useState<GiftCard>({} as GiftCard);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [searchQuery, setSearchQuery] = useState("");

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setGiftCardData);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setGiftCardData({} as GiftCard);
        setId(-1);
        setSuggestions([]);
        setSearchQuery("");
    };

    const fetchUsersId = async (userId) => {
        try {
            const response = await apiRepository.getIdUsers(userId);
            const userData = response.data.data
            const name = { "label": userData.name, "value": userData.id }
            setSearchQuery(name);
        } catch (error) {
            console.error("Error al obtener los datos de usuarios:", error);
        }
    };

    const fetchUsers = async (query: string) => {
        try {
            const response = await apiRepository.getUsers(0, 20, { fullName: query, profile: 'customer', email: '' });
            const formattedSuggestions = response.data.data.content.map((user) => ({
                label: `${user.name} ${user.lastname}`,
                value: user.id
            }));

            setSuggestions(formattedSuggestions);
        } catch (error) {
            console.error("Error al obtener los datos de usuarios:", error);
        }
    };

    const handleSearch = (query: string) => {
        setSearchQuery(query);
        fetchUsers(query);
    };

    useEffect(() => {
        if (selectedGiftCard) {
            setGiftCardData({
                id: selectedGiftCard.id,
                userId: selectedGiftCard.userId,
                code: selectedGiftCard.code,
                quantityDate: selectedGiftCard.quantityDate,
                endDate: selectedGiftCard.endDate && !isNaN(new Date(selectedGiftCard.endDate).getTime())
                    ? dateStandard(selectedGiftCard.endDate)
                    : dateStandard(formatDatetoString(new Date())),
                type: selectedGiftCard.type,
                quantity: selectedGiftCard.quantity,
                status: selectedGiftCard.status !== undefined && selectedGiftCard.status !== null ? selectedGiftCard.status : true,
            });
            setId(selectedGiftCard.id);
            setShow(true);
            if (selectedGiftCard.userId) {
                fetchUsersId(selectedGiftCard.userId);
            } else {
                fetchUsers('');
            }
        }
    }, [selectedGiftCard]);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const errors = await handleFormSubmit(
            id,
            giftCardData,
            validateGiftCard,
            {
                create: apiRepository.createGiftCard,
                update: apiRepository.updateGiftCard,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => ({
                ...data,
                endDate: formatDatetoString(data.endDate),
                userId: searchQuery?.value,
                code: '',
            }),
            "Ocurrió un error al intentar guardar la tarjeta regalo. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <>
            <BaseModal
                show={show}
                title="Gift Card"
                onClose={handleClose}
                onConfirm={handleConfirmCreate}
                loading={loading}
            >
                <form onSubmit={handleConfirmCreate}>
                    <div className="row">
                        <div className={giftCardData.userId ? "col-md-6" : "col-md-12"}>

                            <AutoCompleteDropdown
                                label="Usuario"
                                value={searchQuery.label || searchQuery}
                                suggestions={suggestions}
                                placeholder="Escribe el nombre de usuario"
                                name="userId"
                                onChange={(val) => {
                                    const valueToSet = typeof searchQuery === 'object' ? searchQuery.label : val;
                                    setSearchQuery(valueToSet);
                                    const selectedSuggestion = suggestions.find(s => s.label === val);
                                    if (selectedSuggestion) {
                                        setSearchQuery(selectedSuggestion);
                                    }
                                }}
                                onSearch={handleSearch}
                                error={getErrorMessage(errors, 'userId')}
                                disabled={!!giftCardData.userId}
                            />
                        </div>
                        {giftCardData.userId && (
                            <div className="col-md-6">
                                <InputField
                                    label="Código"
                                    name="code"
                                    value={giftCardData.code || ''}
                                    onChange={handleInputChange}
                                    error={getErrorMessage(errors, "code")}
                                    disabled={!!giftCardData.userId}
                                />
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div
                            className={`col-md-${['Clases', 'Clases Reformer'].includes(giftCardData.type) ? '6' : '12'
                                }`}>
                        <DropdownField
                                label="Tipo"
                                name="type"
                                options={TYPE_SUB_SERVICE}
                                value={giftCardData.type}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "type")}
                                disabled={giftCardData.status && giftCardData.id != null && giftCardData.id !== ""}
                            />
                        </div>
                        {['Clases', 'Clases Reformer'].includes(giftCardData.type) && (
                            <div className="col-md-6">
                                <InputField
                                    label="Cantidad de Clases"
                                    name="quantity"
                                    value={giftCardData.quantity || ''}
                                    onChange={handleInputChange}
                                    error={getErrorMessage(errors, "quantity")}
                                    disabled={giftCardData.status && giftCardData.id != null && giftCardData.id !== ""}
                                />
                            </div>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputField
                                label="Cantidad de Días Validos"
                                name="quantityDate"
                                value={giftCardData.quantityDate || ''}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "quantityDate")}
                                disabled={giftCardData.status && giftCardData.id != null && giftCardData.id !== ""}
                            />
                        </div>
                        <div className="col-md-6">
                            <CalendarField
                                label="Fecha de Expiración"
                                name="endDate"
                                value={giftCardData.endDate}
                                onChange={(e) => setGiftCardData({ ...giftCardData, endDate: e.value as Date })}
                                error={getErrorMessage(errors, "endDate")}
                                disabled={giftCardData.status && giftCardData.id != null && giftCardData.id !== ""}
                            />
                        </div>
                    </div>
                    {errors.find(error => error.field === 'server') && (
                        <div className="alert alert-danger" role="alert">
                            {getErrorMessage(errors, "server")}
                        </div>
                    )}
                </form>
            </BaseModal>
        </>
    );
};

export default GiftCardModal;