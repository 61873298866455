import { CartProduct, CouponData, ValidationError, ErrorResponse } from '../Interface/interface';

export const findProductInCart = (cart: CartProduct[], packetId: number): CartProduct | undefined => {
    return cart.find(product => product.id === packetId);
};

export const calculateDiscount = (couponData: CouponData, productPrice: number): number => {
    if (couponData.typeDiscount) {
        return parseFloat(((productPrice * couponData.amount) / 100).toFixed(2));
    } else {
        return couponData.amount;
    }
};

export const handleValidCoupon = (
    setCouponDiscount: (discount: number) => void,
    setInfo: (info: ValidationError[]) => void,
    discount: number,
    message: string
): void => {
    setCouponDiscount(parseFloat(discount.toString()));
    setInfo([{ field: 'success', message }]);
};

export const handleInvalidCoupon = (
    setInfo: (info: ValidationError[]) => void,
    message: string
): void => {
    setInfo([{ field: 'error', message }]);
};

export const handleCouponError = (
    setCouponDiscount: (discount: number) => void,
    setInfo: (info: ValidationError[]) => void,
    error: ErrorResponse
): void => {
    setCouponDiscount(0);
    const errorMessage =
        error.response?.data?.message?.replace(/^Bad request: /, '') ||
        "Ocurrió un error al intentar validar el cupón. Inténtalo de nuevo.";
    setInfo([{ field: 'error', message: errorMessage }]);
};