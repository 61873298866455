import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { AuthContext } from '../../../../core/context/AuthContext';
import { usePromotionSection } from '../../../../core/hooks/getData/usePromotionSection';

const TrialSection = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const { user } = authContext;
  const userProfile = user?.role;
  const { promotionSectionData, isLoading } = usePromotionSection();

  return (
    <>
      <section className="service-section">
        {userProfile !== 'customer' && userProfile !== 'trainer' && (
          <div className="container pb-lg-5 pt-lg-5 col-11" style={{ height: isLoading ? '800px' : 'auto' }}>
            <div className="row mt-0 align-items-center">
              <div className="col-md-6 col-12">
                <div className="appimg aos d-flex justify-content-center">
                  {promotionSectionData?.image && (
                    <ImageWithBaseApi
                    loadingIcon={
                      <i className="fa fa-spinner fa-spin" style={{ fontSize: '84px', color: '#5c5c5c', margin: '160px' }}></i>
                    }
                    src={promotionSectionData.image}
                    style={{ maxWidth: 'auto', maxHeight: '600px' }}
                    className="img-fluid"
                    alt="Promoción"
                    routeName="homesection"
                    />
                  )}
                </div>
              </div>
              <div className="col-md-6 col-12">
                <div className="download-sec trial-section mt-3 mt-lg-0 mt-md-0">
                  <div dangerouslySetInnerHTML={{ __html: promotionSectionData?.description || '' }} />
                  {!isLoading && (
                  <div className="downlaod-btn aos mt-3">
                    <div className="pricing-plans price-new">
                      <Link to={promotionSectionData?.link || '/'} className="btn">
                        Reserva Gratis
                      </Link>
                    </div>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default TrialSection;