import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { PaymentDays } from '../../../../core/Interface/repositoryInterfaces';
import { validatePaymentDays, ValidationError } from '../../../../core/form/validationForm';
import { InputField, CalendarField, DropdownField, SwitchField } from '../input/inputField';
import { handleChange, formatDate, dateStandard } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import BaseModal from './baseModal';
import { TYPE_SUB_SERVICE } from '../../../../core/data/options/options';
import { UpdateCouponModalProps } from '../../../../core/Interface/modalInterface';

const PaymentDaysModal: React.FC<UpdateCouponModalProps> = ({ selectedPaymentDays, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedPaymentDays ? selectedPaymentDays.id : -1);
    const [paymentDaysData, setPaymentDaysData] = useState<PaymentDays>({} as PaymentDays);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setPaymentDaysData);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setPaymentDaysData({} as PaymentDays);
        setId(-1);
    };

    useEffect(() => {
        if (selectedPaymentDays) {
            setPaymentDaysData({
                userId: selectedPaymentDays.userId,
                fullName: selectedPaymentDays.fullName,
                quantity: selectedPaymentDays.quantity,
                endDate: selectedPaymentDays.endDate ? dateStandard(selectedPaymentDays.endDate) : null,
                type: selectedPaymentDays.type,
                status: selectedPaymentDays.status,
            });
            setId(selectedPaymentDays.id);
            setShow(true);
        }
    }, [selectedPaymentDays]);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const errors = await handleFormSubmit(
            id,
            paymentDaysData,
            validatePaymentDays,
            {
                create: apiRepository.createPaymentDay,
                update: apiRepository.updatePaymentDay,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            (data) => ({
                ...data,
                endDate: formatDate(data.endDate),
                days: data.quantity,
                quantity: Number(data.quantity),
            }),
            "Ocurrió un error al intentar asignar la cantidad de días. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <>
            <BaseModal
                show={show}
                title="Actualizar Cantidad de Clases"
                onClose={handleClose}
                onConfirm={handleConfirmCreate}
                loading={loading}
            >
                <form onSubmit={handleConfirmCreate}>
                    <div className="row">
                        <div className={`col-md-${['Clases', 'Clases Reformer'].includes(paymentDaysData.type) ? '6' : '12'}`}>
                            <InputField
                                label="Usuario"
                                value={paymentDaysData.fullName || ''}
                                onChange={handleInputChange}
                                error={getErrorMessage(errors, "userId")}
                                disabled={true}
                            />
                        </div>
                        {['Clases', 'Clases Reformer'].includes(paymentDaysData.type) && (
                            <div className="col-md-6">
                                <InputField
                                    label="Cantidad de Clases"
                                    name="quantity"
                                    value={paymentDaysData.quantity || ''}
                                    onChange={handleInputChange}
                                    error={getErrorMessage(errors, "quantity")}
                                />
                            </div>
                        )}
                    </div>
                    <CalendarField
                        label="Fecha de Vencimiento"
                        name="endDate"
                        value={paymentDaysData.endDate || ''}
                        onChange={(e) => setPaymentDaysData({ ...paymentDaysData, endDate: e.value })}
                        error={getErrorMessage(errors, "endDate")}
                    />
                    <DropdownField
                        label="Tipo"
                        name="type"
                        disabled={true}
                        options={TYPE_SUB_SERVICE}
                        value={paymentDaysData.type || ''}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, "type")}
                    />
                    {errors.find(error => error.field === 'server') && (
                        <div className="alert alert-danger" role="alert">
                            {getErrorMessage(errors, "server")}
                        </div>
                    )}
                </form>
            </BaseModal>
        </>
    );
};

export default PaymentDaysModal;