import React from 'react';
import * as Icon from 'react-feather';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { ConfirmEmailModalProps } from '../../../../core/Interface/modalInterface';

const ConfirmEmailModal: React.FC<ConfirmEmailModalProps> = ({
  onConfirm,
  onCancel,
  error,
  loading = false,
  message = '¿Estás seguro de que deseas enviar los correos electrónicos seleccionados?',
}) => {
  return (
    <div
      className="modal fade"
      id="confirmEmailModal"
      tabIndex={-1}
      aria-labelledby="confirmEmailModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="confirmEmailModalLabel">
              Confirmar Acción
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body text-center pt-1">
          <Icon.Info size={95} color='#ffd100' className="text-success fs-1" />
            <p className="mt-3">{message}</p>
              {
                error.find((err: { field: string }) => err.field === 'confirm-email') && (
                  <div className="alert alert-danger" role="alert">
                    {getErrorMessage(error, "confirm-email")}
                  </div>
                )
              }
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={onCancel}
            >
              Cancelar
            </button>
            <button
              type="button"
              className="btn btn-primary-admin"
              onClick={onConfirm}
            >
              {loading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Enviando...
                </>
              ) : (
                'Confirmar'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmailModal;