import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";

export const useSubTypeByClassId = (classId: number | null) => {
    const [subTypeClassesData, setSubTypeClassesData] = useState<{ label: string; value: any }[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSubTypeClasses = async () => {
        if (!classId) return;
        try {
            setIsLoading(true);
            const response = await apiRepository.getSubTypeByClasses(classId);
            const optionsData = response.data.data.map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setSubTypeClassesData(optionsData);
        } catch (error) {
            console.error("Error al obtener los datos de los tipos de clases:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSubTypeClasses();
    }, [classId]);

    return { subTypeClassesData, isLoading, fetchSubTypeClasses };
};
