import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetClassCapacityReportExelFile = (dateRange?: [Date, Date], classId?: string, trainerId?: string, hourStart?: string, hourEnd?: string) => {
    const [classCapacityExelFile, setClassCapacityExelFile] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchClassCapacity = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getClassCapacityReportExelFile(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
                classId,
                trainerId,
                hourStart,
                hourEnd
            );
            const classCapacityExelFile = response.data;
            setClassCapacityExelFile(classCapacityExelFile);
            return classCapacityExelFile;
        } catch (err) {
            console.error("Error al obtener los datos de horarios:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        classCapacityExelFile,
        isLoading,
        error,
        refetchClassCapacity: fetchClassCapacity,
    };
};