export const getErrorMessage = (errors: { field: string; message: string }[], field: string) => {
    return errors.find((err) => err.field === field)?.message;
};

export const handleError = (error: any, setServerError: React.Dispatch<React.SetStateAction<any[]>>, field = "server") => {
    if (error.response && error.response.data && error.response.data.message) {
        const cleanedMessage = error.response.data.message
            .replace(/^Bad request: /, '')
            .replace(/^Internal server error: /, '')
            .replace(/^Error interno del servidor: /, '')
            .replace(/^Error de validación: /, '');
        setServerError((prevErrors: any[]) => [
            ...prevErrors,
            { field, message: cleanedMessage }
        ]);
    } else {
        setServerError((prevErrors: any[]) => [
            ...prevErrors,
            { field, message: 'Ocurrió un error inesperado. Inténtalo de nuevo.' }
        ]);
    }
};