import React from 'react';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { serviceSlideData } from '../../../../core/data/json/services-slide';
import Slider from 'react-slick';
import { useClasses } from '../../../../core/hooks/getData/useClasses';
import { getFeatureSliderConfig } from '../../../../core/data/config/carouselConfig';

const FeatureSection = () => {
  const routes = all_routes;
  const { classesData: classes = [] } = useClasses({ shouldTransform: true });
  const slidesToShowCount = Math.max(classes.length >= 3 ? 3 : classes.length, 1);
  const imgslideroption = getFeatureSliderConfig(slidesToShowCount);
  const sliderClassName = `img-slider ${classes.length < 3 ? 'product-service' : ' service-slider nav-center hero-section  slick-slider  slide-arrows-hero'}`;

  return (
    <>
      {/* Service Section */}
      <section className="section p-0">
        <div className="container col-11">
          <div className="row justify-content-center">
            <div
              className="col-lg-6 text-center wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <div className="section-header text-center">
                <h2 className="mb-1">
                  Nuestras{' '}
                  <span className="text-linear-primary">
                    Sesiones Destacadas
                  </span>
                </h2>
                <p className="sub-title">
                  Cada sesión está diseñada para maximizar tu potencial físico y
                  apoyar tus objetivos de estética y salud.
                </p>
              </div>
            </div>
          </div>
          <Slider {...imgslideroption} className={sliderClassName}>
            {classes.map((res: any, index: number) => {
              return (
                <div
                  className="service-item wow fadeInUp"
                  data-wow-delay="0.2s"
                  key={index}
                >
                  <div className="service-img">
                    <div className="slide-images  feature_image">
                      {/* <Link to={res.path}> */}
                        <ImageWithBaseApi
                          src={res.image}
                          routeName="class"
                          className="img-fluid"
                          alt="Clases"
                        />
                      {/* </Link> */}
                    </div>
                  </div>
                  <div className="service-content">
                    <div className="d-flex justify-content-between">
                      <h6 className="text-truncate mb-1">
                        <Link to={res.image}>{res.title}</Link>
                      </h6>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="fw-medium fs-14 mb-0">{res.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {serviceSlideData.length > 3 && (
            <div className="row">
              <div className="col-md-12">
                <div className="text-center wow fadeInUp" data-wow-delay="0.2s">
                  <Link to={routes.search} className="btn btn-dark">
                    View All
                    <i className="ti ti-arrow-right ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default FeatureSection;