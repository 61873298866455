import React, { useEffect, useState } from 'react';
import * as Icon from 'react-feather';
import BaseModal from './baseModal';

const ShowDetailClassFreePurchaseModal: React.FC<any> = ({ selectedPurchaseData }) => {
    const [show, setShow] = useState<boolean>(false);
    const [purchaseData, setPurchaseData] = useState<any>();

    useEffect(() => {
        if (selectedPurchaseData) {
            setPurchaseData(selectedPurchaseData);
            setShow(true);
        }
    }, [selectedPurchaseData]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <BaseModal
            show={show || false}
            title="Detalle de Compra de Clase Gratis"
            onClose={handleClose}
            showButtons={false}
        >
            <div className="modal-body pt-0">
                <div className="text-center col-md-12 m-auto">
                    <Icon.CheckCircle size={45} color='#28a745' className="text-success fs-1" />
                    <div className="mt-4">
                        <h4>{purchaseData?.userName}</h4>
                        {purchaseData && (
                            <table className="table table-bordered mt-2">
                                <tbody>
                                    <tr>
                                        <th>ID de Usuario</th>
                                        <td>{purchaseData.userId}</td>
                                    </tr>
                                    <tr>
                                        <th>Correo Electrónico</th>
                                        <td>{purchaseData.userEmail}</td>
                                    </tr>
                                    <tr>
                                        <th>Fecha de Activación</th>
                                        <td>{new Date(purchaseData.activationDateTime).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <th>Fecha de Compra</th>
                                        <td>{new Date(purchaseData.purchaseDateTime).toLocaleString()}</td>
                                    </tr>
                                    <tr>
                                        <th>Tiene Compra Después de Activación</th>
                                        <td>{purchaseData.hasPurchaseAfterActivation ? 'Sí' : 'No'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn w-sm btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </BaseModal>
    );
}

export default ShowDetailClassFreePurchaseModal;
