import { useState, useEffect } from 'react';

export const useResponsiveColumns = (columns) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getColumns = () => {
    let displayedColumns = [];

    if (screenWidth < 600) {
      displayedColumns = columns.slice(0, 1);
    } else if (screenWidth < 1200) {
      displayedColumns = columns.slice(0, 3);
    } else if (screenWidth < 1300) {
      displayedColumns = columns.slice(0, 4);
    } else if (screenWidth < 1450) {
      displayedColumns = columns.slice(0, 5);
    } else if (screenWidth < 1850) {
      displayedColumns = columns.slice(0, 7);
    } else {
      displayedColumns = columns;
    }

    if (screenWidth >= 600 && columns.length > 1 && !displayedColumns.includes(columns[columns.length - 1])) {
      displayedColumns.push(columns[columns.length - 1]);
    }

    return displayedColumns;
  };

  return getColumns;
};
