import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import SuscriptionsModal from '../common/modals/user-suscriptions-modal';
import DeleteUserModal from '../common/modals/delete-user-suscriptions-modal';
import { InputField } from '../../admin/common/input/inputField';

const UserSuscriptions = () => {
    const [data, setData] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState<any>(null);
    const [selectedUser, setSelectedUser] = useState<any>(null);
    const [totalUsers, setTotalUsers] = useState(-1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [fullName, setFullName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [filtersVisible, setFiltersVisible] = useState<boolean>(false); // Estado para controlar la visibilidad de los filtros

    const fetchUsers = async (pageNumber = currentPage, pageSize = rows) => {
        try {
            setLoading(true);
            const response = await apiRepository.getSubscriptions(
                pageNumber,
                pageSize,
                fullName,
                email
            );
            const totalRecords = response.data.data.totalElements;
            setData(response.data.data.content);
            setTotalUsers(totalRecords);
        } catch (error) {
            console.error('Error al obtener los datos de suscripciones:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [currentPage, rows, fullName, email]);

    const handleEditClick = (rowData: any) => {
        setSelectedUser(null);
        setTimeout(() => setSelectedUser(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedUserId(null);
        setTimeout(() => setSelectedUserId(rowData.id), 0);
    };

    const columns = [
        { field: 'fullName', header: 'Nombre Completo' },
        { field: 'email', header: 'Correo Electrónico' },
    ];

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        const pageNumber = event.first / event.rows;
        setCurrentPage(pageNumber);
    };

    return (
        <div className="page-wrapper page-settings">
            <div className="content mb-5">
                <div className="content-page-header mb-2 content-page-headersplit">
                    <h5>Suscripciones</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar Suscripción
                        </button>
                    </div>
                </div>

                <button
                    className="btn btn-outline-secondary mb-4"
                    onClick={() => setFiltersVisible(!filtersVisible)}
                >
                    {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
                </button>

                {filtersVisible && (
                    <div className="search-filters card p-3 mb-4 shadow-sm">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h6 className="text-dark">Filtros de búsqueda</h6>
                            <button
                                className="btn btn-outline-primary btn-sm rounded-3"
                                onClick={() => {
                                    setFullName('');
                                    setEmail('');
                                }}
                                title="Limpiar filtros"
                            >
                                <i className="fa fa-eraser me-2" />
                                Limpiar
                            </button>
                        </div>
                        <div className="d-flex align-items-center gap-3">
                            <div className="flex-grow-1 position-relative">
                                <InputField
                                    label="Nombre"
                                    name="fullName"
                                    placeholder="Ejemplo: Juan"
                                    value={fullName}
                                    onChange={(e) => setFullName(e.target.value)}
                                    className="form-control form-control-sm shadow-sm rounded-2"
                                />
                            </div>
                            <div className="flex-grow-1 position-relative">
                                <InputField
                                    label="Correo Electrónico"
                                    name="email"
                                    placeholder="Ejemplo: juan@gmail.com"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="form-control form-control-sm shadow-sm rounded-2"
                                />
                            </div>
                        </div>
                    </div>

                )}

                <DataTableWrapper
                    data={data}
                    columns={columns}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    loading={loading}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalUsers}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron suscripciones."
                />
                <SuscriptionsModal selectedUser={selectedUser} onUpdateSuccess={fetchUsers} />
                <DeleteUserModal selectedUserId={selectedUserId} onDeleteSuccess={fetchUsers} />
            </div>
        </div>
    );
};

export default UserSuscriptions;