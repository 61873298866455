import { InputField, CalendarField, DropdownField } from '../../../admin/common/input/inputField';
import { validateUser, ValidationError } from '../../../../core/form/validationForm';
import { getLocationCode, handleFormSubmit } from '../../../../core/services/formService';
import { TYPE_IDENTIFIER } from '../../../../core/data/options/options';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { AuthContext } from '../../../../core/context/AuthContext';
import React, { useState, useEffect, useContext } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import AlertMessage from '../../../components/alertMessage';
import DashWidget from '../../common/dashWidget/dashWidget';
import { useOutletContext } from 'react-router-dom';
import LoadingButton from '../../../components/loadingButton';
import { useUserData } from '../../../../core/hooks/getData/useUserData';
import { useSchedules } from '../../../../core/hooks/getData/useSchedules';
import { convertDateToLocalDateString } from '../../../../core/utils/dateUtils';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';
import { useUbigeo } from '../../../../core/hooks/getData/useUbigeo';

const CustomerDashboard = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const { user, updateUserData } = authContext;
  const { formData, updateFormData, refetchUserData, handleChange, setFormData } = useUserData(Number(user?.userId));
  const { provinces, districts, formattedUbigeoData } = useUbigeo({ formData, handleChange });
  const { totalRecords, classesToday, nextClass } = useSchedules(user?.role, Number(user?.userId));
  const routes = all_routes

  const resetForm = () => {
    setErrors([]);
    refetchUserData();
  }

  useEffect(() => {
    if (formData?.province && formData?.district) {
      setFormData((prevData) => ({
            ...prevData,
            province: formData.province,
            district: formData.district
        }));
    }
}, [formData?.province, formData?.district]);

  const updatePerfil = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setErrors([]);
    if (!formData) return;

    const locationId = await getLocationCode(formData?.district, apiRepository.getLocations);
    const errors = await handleFormSubmit(
      Number(user?.userId),
      formData,
      validateUser,
      {
        create: apiRepository.updateUser,
        update: apiRepository.updateUser,
      },
      () => {
        refetchUserData();
        updateUserData({
          username: formData.name,
        });
      },
      (data) => ({
        ...data,
        birthdate: convertDateToLocalDateString(data.birthdate),
        locationId: locationId,
      }),
      "Ocurrió un error al intentar actualizar las datos del usuario. Inténtalo de nuevo."
    );

    if (errors) {
      setErrors(errors);
    } else {
      const successMessage: ValidationError = {
        id: "success",
        field: "success",
        message: "Datos actualizados exitosamente!",
      };
      setErrors([successMessage]);
    }

    setLoading(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    updateFormData({ [name]: value });
  };

  useEffect(() => {
    setBreadcrumbData({ title: "Editar Perfil", item2: "Editar Perfil" });
  }, [setBreadcrumbData]);


  const widgets = [
    {
      iconClass: 'ti ti-chart-line',
      bgClass: 'bg-primary-transparent',
      title: 'Clases Totales de Mes',
      value: totalRecords
        ? `${totalRecords}`
        : 'Sin clases agendadas',
      duration: 1,
      link: `${routes.customerCalendar}?type=dayGridMonth`
    },
    {
      iconClass: 'ti ti-calendar',
      bgClass: 'bg-secondary-transparent',
      title: 'Clases de hoy',
      value: classesToday
        ? `${classesToday}`
        : 'Sin clases hoy',
      duration: 2,
      link: `${routes.customerCalendar}?type=timeGridWeek`
    },
    {
      iconClass: 'ti ti-alarm',
      bgClass: 'bg-success-transparent',
      title: 'Siguiente Clase',
      value: nextClass
        ? nextClass.dateFormatted
        : 'Sin siguiente clase',
      duration: 2,
      hourStart: nextClass ? nextClass.hourStartFormatted : undefined,
      hourEnd: nextClass ? nextClass.hourEndFormatted : undefined,
      link: `${routes.customerCalendar}?type=timeGridDay`

    },
  ];

  return (
    <>
      <h4 className="mb-3">Dashboard</h4>
      <div className="row mb-2">
        {widgets.map((widget, index) => (
          <DashWidget key={index} {...widget} />
        ))}
      </div>
      <div className="row">
        <h4 className="mb-3">Información</h4>
        <form onSubmit={updatePerfil}>
          <div className="row mb-4">
            <div className="col-md-6">
              <InputField
                label="Nombre"
                name="name"
                value={formData?.name || ''}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'name')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="Apellido"
                name="lastname"
                value={formData?.lastname || ''}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'lastname')?.message}
              />
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-3 pe-0">
                  <DropdownField
                    label="Tipo de Documento"
                    name="typeDocument"
                    options={TYPE_IDENTIFIER}
                    value={formData?.typeDocument}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-9">
                  <InputField
                    label="Numero de Identidad"
                    name="numberDocument"
                    value={formData?.numberDocument || ''}
                    onChange={handleInputChange}
                    error={errors.find((err) => err.field === 'numberDocument')?.message}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <InputField
                label="Telefono"
                name="phone"
                value={formData?.phone || ''}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'phone')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                label="Correo"
                name="email"
                value={formData?.email || ''}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'email')?.message}
              />
            </div>
            <div className="col-md-6">
              <CalendarField
                label="Fecha de nacimiento"
                value={formData.birthdate || ''}
                onChange={(e) => updateFormData({ ...formData, birthdate: e.value })}
                error={errors.find((err) => err.field === 'birthdate')?.message}
              />
            </div>
            {
              user?.role !== "trainer" && (
                <div className="col-md-6">
                  <InputField
                    label="Condición Médica / Embarazo"
                    name="conditionMedical"
                    required={false}
                    value={formData?.conditionMedical || ''}
                    onChange={handleInputChange}
                    error={errors.find((err) => err.field === 'conditionMedical')?.message}
                  />
                </div>
              )
            }
            <div className={user?.role === "trainer" ? "col-md-12" : "col-md-6"}>
              <InputField
                label="Dirección"
                name="address"
                value={formData?.address || ''}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'address')?.message}
              />
            </div>
            <div className="row">
              <div className="col-md-4">
                <DropdownField
                  label="Departamento"
                  name="department"
                  placeholder="Seleccione un departamento"
                  onChange={handleInputChange}
                  options={formattedUbigeoData}
                  value={formData?.department}
                  error={errors.find((err) => err.field === 'department')?.message}
                />
              </div>
              <div className="col-md-4">
                <DropdownField
                  label="Provincia"
                  name="province"
                  placeholder="Seleccione una provincia"
                  onChange={handleInputChange}
                  options={provinces || []}
                  value={formData?.province}
                  disabled={!formData?.department}
                  error={errors.find((err) => err.field === 'province')?.message}
                />
              </div>
              <div className="col-md-4">
                <DropdownField
                  label="Distrito"
                  name="district"
                  placeholder="Seleccione un distrito"
                  onChange={handleInputChange}
                  options={districts || []}
                  value={formData?.district}
                  disabled={!formData?.province}
                  error={errors.find((err) => err.field === 'district')?.message}
                />
              </div>
            </div>
            <div className="col-md-6">
              <InputField
                type='password'
                required={false}
                label="Nueva Contraseña"
                name='password'
                value={formData?.password || ''}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'password')?.message}
              />
            </div>
            <div className="col-md-6">
              <InputField
                type='password'
                required={false}
                label="Confirmar Contraseña"
                name='newPassword'
                value={formData?.newPassword || ''}
                onChange={handleInputChange}
                error={errors.find((err) => err.field === 'newPassword')?.message}
              />
            </div>
            <div className="justify-content-end align-items-center">
              <AlertMessage errors={errors} field="server" type="danger" />
              <AlertMessage errors={errors} field="success" type="success" />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <LoadingButton type="button" customText="Cancelar" loadingText="Cancelando" className="btn-outline-secondary me-3" onClick={resetForm} />
            <LoadingButton loading={loading} />
          </div>
        </form>
      </div>
    </>
  );
};

export default CustomerDashboard;