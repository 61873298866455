import React, { useEffect, useState, useContext } from 'react';
import AdminRoutes from './admin.routes';
import AdminHeader from './common/header';
import AdminSidebar from './common/sidebar';
import { useLocation } from 'react-router-dom';
import PageLoader from '../../core/loader';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../core/context/AuthContext';

const Admin = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { user, isAuthenticated } = authContext;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const delay = 2000; // Define cuánto tiempo se mostrará el loader
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, delay);

    return () => clearTimeout(timeout); // Limpia el timeout si el componente se desmonta
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthenticated || user?.role !== 'admin') {
      navigate('/'); // Redirige si el usuario no está autenticado o no es admin
      return;
    }

    if (window.location.pathname.includes('/admin')) {
      import('../../style/admin/css/admin.css');
    } else {
      import('../../style/scss/main.scss');
    }
  }, [location.pathname]);

  if (isLoading) {
    return <PageLoader />;
  }

  return (
    <>
      {location.pathname === '/admin/signin' ||
      location.pathname === '/admin/signup' ||
      location.pathname === '/admin/forget-password' ||
      location.pathname === '/admin/wallet-history' ? (
        <></>
      ) : (
        <>
          <AdminHeader />
          <AdminSidebar />
        </>
      )}
      <AdminRoutes />
    </>
  );
};

export default Admin;