export const membershipTransaction = [
  {
    id: 1,
    providername: 'John Smith',
    subscription: 'Enterprice',
    amount: '$650',
    duration: 'One Month',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
  {
    id: 2,
    providername: 'Johnny',
    subscription: 'Standard',
    amount: '$350',
    duration: '6 Month',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
  {
    id: 3,
    providername: 'Amanda',
    subscription: 'Basic',
    amount: '$100',
    duration: 'One Year',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
  {
    id: 4,
    providername: 'James',
    subscription: 'Enterprice',
    amount: '$150',
    duration: 'One Year',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
  {
    id: 5,
    providername: 'Jerry',
    subscription: 'Standard',
    amount: '$350',
    duration: 'One Month',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
  {
    id: 6,
    providername: 'Pauline',
    subscription: 'Basic',
    amount: '$100',
    duration: 'One Month',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
  {
    id: 7,
    providername: 'Kim',
    subscription: 'Enterprice',
    amount: '$150',
    duration: 'One Year',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
  {
    id: 8,
    providername: 'Robert',
    subscription: 'Enterprice',
    amount: '$650',
    duration: 'One Year',
    startdate: '06 Nov 2023 10:43 PM',
    enddate: '06 Dec 2023 10:43 PM',
  },
];
