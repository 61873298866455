import { useCallback } from 'react';
import { UseSearchHandlersProps } from '../../Interface/hooksInterface';

export const useSearchHandlers = ({ subTypeClassesData, trainersData, setClassSuggestions, setTrainerSuggestions, setClassId, setTrainerId, setSelectedClassLabel, setSelectedTrainerLabel }: UseSearchHandlersProps) => {
    const handleClassSearch = useCallback((query: string) => {
        const filtered = subTypeClassesData
            .filter((option: any) => option.label.toLowerCase().includes(query.toLowerCase()))
            .sort((a: { label: string }, b: { label: string }) => {
                const aIndex = a.label.toLowerCase().indexOf(query.toLowerCase());
                const bIndex = b.label.toLowerCase().indexOf(query.toLowerCase());
                return aIndex - bIndex;
            });
        setClassSuggestions(filtered);
    }, [subTypeClassesData, setClassSuggestions]);

    const handleClassChange = useCallback((value: string) => {
        const selectedClass = subTypeClassesData.find(option => option.label === value);
        setClassId(selectedClass ? selectedClass.value : '');
        setSelectedClassLabel(value);
    }, [subTypeClassesData, setClassId, setSelectedClassLabel]);

    const handleTrainerSearch = useCallback((query: string) => {
        const filtered = trainersData
            .filter((option) => option.label.toLowerCase().includes(query.toLowerCase()))
            .sort((a, b) => {
                const aIndex = a.label.toLowerCase().indexOf(query.toLowerCase());
                const bIndex = b.label.toLowerCase().indexOf(query.toLowerCase());
                return aIndex - bIndex;
            });
        setTrainerSuggestions(filtered);
    }, [trainersData, setTrainerSuggestions]);

    const handleTrainerChange = useCallback((value: string) => {
        const selectedTrainer = trainersData.find(option => option.label === value);
        setTrainerId(selectedTrainer ? selectedTrainer.value : '');
        setSelectedTrainerLabel(value);
    }, [trainersData, setTrainerId, setSelectedTrainerLabel]);

    return { handleClassSearch, handleClassChange, handleTrainerSearch, handleTrainerChange };
};
