import { buildItems } from "../utils/cartUtils";

const getBackUrls = (cart: any, user: any) => {
  const baseParams = `itemIds=${encodeURIComponent(cart.items.map((item: any) => item.id).join(','))}&quantity=${encodeURIComponent(cart.items.map((item: any) => item.quantity).join(','))}&codesCoupons=${encodeURIComponent(cart.items.map((item: any) => item.codesCoupons).join(','))}&amountRaw=${encodeURIComponent(cart.items.map((item: any) => item.amount).join(','))}&discountValue=${encodeURIComponent(cart.items.map((item: any) => item.discount_value).join(','))}&discount_general_value=${cart.discount_value}&amount_total_raw=${cart.amount_raw}&codesCouponsGeneral=${encodeURIComponent(cart.codesCoupons)}&userId=${user.userId}`;
  const baseUrl = window.location.origin;

  return {
    success: `${baseUrl}/perfil/cart?status=success&${baseParams}`,
    failure: `${baseUrl}/perfil/cart?status=cancelled&${baseParams}`,
    pending: `${baseUrl}/perfil/cart?status=success&${baseParams}`,
  };
};

export const sendPreferenceToMercadoPago = async (preferenceData: any, token: string) => {
  try {
    const response = await fetch('https://api.mercadopago.com/checkout/preferences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(preferenceData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.init_point;
  } catch (error) {
    console.error('Error al crear la preferencia en Mercado Pago:', error);
    throw error;
  }
};

export const getPaymentDetails = async (paymentId: any, token: string) => {
  try {
    const response = await fetch(`https://api.mercadopago.com/v1/payments/${paymentId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
      }
    });

    const data = await response.json();

    return data

  } catch (error) {
    console.error('Error al obtener detalles del pago:', error);
  }
};

export const createPreference = async (cart: any, couponDiscount: any, token: string, user: any) => {
  try {
    const items = buildItems(cart, couponDiscount);
    const backUrls = getBackUrls(cart, user);

    const preferenceData = {
      items,
      back_urls: backUrls,
      currency_id: 'PEN',
      auto_return: 'approved',
    };

    if (!token) {
      throw new Error('Token not available');
    }

    const initPoint = await sendPreferenceToMercadoPago(preferenceData, token);
    return initPoint;
  } catch (error) {
    console.error('Error al crear la preferencia:', error);
    throw error;
  }
};