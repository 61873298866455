import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumns';
import { useGetPurchaseReport } from '../../../core/hooks/getReports/useGetPurchaseReport';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import ShowDetailPurchaseModal from '../common/modals/show-detail-purchase-modal';
import { useGetPurchaseReportExelFile } from '../../../core/hooks/getReports/useGetPurchaseReportExelFile';
import { downloadExcelFile } from '../../../core/utils/fileUtils';

const PurchaseReport = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange());
  const [selectedPurchase, setSelectedPurchase] = useState<any>();
  const { purchaseReport } = useGetPurchaseReport(dateRange);
  const { refetchPurchaseReport } = useGetPurchaseReportExelFile(dateRange);

  const columns = [
    { field: "fullName", header: "Cliente" },
    { field: "dni", header: "DNI" },
    { field: "email", header: "Correo Electrónico" },
    { field: "purchaseDate", header: "Fecha de Compra", body: (rowData: any) => new Date(rowData.purchaseDate).toLocaleDateString('es-PE') },
    { field: "originalPrice", header: "Precio Original", body: (rowData: any) => `S/. ${rowData.originalPrice}` },
    { field: "finalPrice", header: "Precio Final", body: (rowData: any) => `S/. ${rowData.finalPrice}` },
  ];

  const getColumns = useResponsiveColumns(columns);

  const handleShowInfo = (rowData: any) => {
    setSelectedPurchase(null);
    setTimeout(() => setSelectedPurchase(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchPurchaseReport();
      downloadExcelFile(file, 'purchase-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Informe de Compras</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-secondary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange());
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
                <CalendarRangeField
                  value={dateRange || [new Date(), new Date()]}
                  onChange={(e: any) => setDateRange(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={purchaseReport || []}
            sortField='purchaseDate'
            lazy={false}
            sortOrder={-1}
            columns={getColumns()}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron compras."
          />
        </div>
        <ShowDetailPurchaseModal selectedPurchaseData={selectedPurchase} />
      </div>
    </div>
  );
};

export default PurchaseReport;