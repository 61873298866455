import React, { useState } from 'react';
import Edittaxmodalpopup from '../common/modals/edit-tax-modal';
import { Link, useNavigate } from 'react-router-dom';
import { apiRepository } from '../../../api/apiRepository';
import { getCurrentLocalDate, convertDateToLocalDateString } from '../../../core/utils/dateUtils';
import { closeModal } from '../../../core/utils/modalsUtils';
import { handleError } from '../../../core/utils/errorUtils';
import { ValidationError } from '../../../core/form/validationForm';
import { useLocation } from 'react-router-dom';
import { all_routes } from '../../../core/data/routes/all_routes';
import * as Icon from 'react-feather';
import SuccessEmailModal from '../common/modals/success-email-modal';

type Props = {
  prevTab: CallableFunction;
  selectedUsers: any[];
  emailData: any[];
};
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const EmailSummary: React.FC<Props> = ({ prevTab, selectedUsers, emailData }) => {
  const [viewMode, setViewMode] = useState<'mobile' | 'pc'>('pc');
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);
  const query = useQuery();
  const id = query.get('id');
  const navigate = useNavigate();
  const routes = all_routes

  const toggleViewMode = () => {
    setViewMode(viewMode === 'pc' ? 'mobile' : 'pc');
  };

  const sendMail = async () => {
    const selectedDetails = selectedUsers.map(user => ({
      email: user.email,
      fullName: user.fullName,
    }));

    const requestBody = {
      name: emailData.subject,
      subject: emailData.description,
      description: emailData.description,
      content: emailData.content,
      date: convertDateToLocalDateString(getCurrentLocalDate()),
      status: true,
      details: selectedDetails,
    };

    try {
      setLoading(true);
      if (id) {
        await apiRepository.updateCampaignss(id, requestBody);
      } else {
        await apiRepository.createCampaignss(requestBody);
      }
      closeModal("confirmEmailModal");
      setShowSuccessModal(true);
    } catch (error) {
      handleError(error, setErrors, "confirm-email");
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessConfirm = () => {
    setShowSuccessModal(false);
    navigate(routes.listEmail);
  };

  return (
    <>
      <div className="addition-service card-section space-service">
        <div className="row">
          <div className="col-md-12">
            <div className="heading-service">
              <h4 className="text-primary mb-2">Resumen del Email</h4>
            </div>
          </div>
          <div className="col-md-7">
            <div className="form-group">
              <label>Cantidad de personas a las que se enviará</label>
              <input
                type="text"
                className="form-control"
                placeholder="Ejemplo: 150"
                value={selectedUsers.length}
                readOnly
              />
            </div>

            <div className="form-group">
              <label>Asunto del Correo</label>
              <input
                type="text"
                className="form-control"
                placeholder="Ejemplo: Oferta Especial"
                value={emailData.description}
                readOnly
              />
            </div>
          </div>

          <div className="col-md-5">
            <div className="form-group pc-view">
              <div className="d-flex justify-content-between align-items-center">
                <h5>Vista previa del contenido</h5>
                <button
                  className="btn btn-secondary mb-3"
                  onClick={toggleViewMode}
                >
                  <i className={`fas ${viewMode === 'pc' ? 'fa-desktop' : 'fa-mobile-alt'}`} />
                  <span className="ms-2">Ver en {viewMode === 'pc' ? 'Móvil' : 'PC'}</span>
                </button>
              </div>

              <div className="email-header mb-4 p-3 border rounded shadow-sm pc-view" style={{ backgroundColor: '#f8f9fa' }}>
                <h5 className="text-secondary mb-1" style={{ fontSize: '1rem' }}>Asunto: {emailData.description}</h5>
              </div>

              <div className={`email-preview p-3 border rounded shadow-sm ${viewMode === 'mobile' ? 'mobile-view' : 'pc-view'}`}>
                <div dangerouslySetInnerHTML={{ __html: emailData.content }} />
              </div>

            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="bottom-btn">
            <div className="field-btns">
              <button
                className="btn btn-prev prev_btn"
                type="button"
                onClick={prevTab}
              >
                <i className="fas fa-arrow-left" /> Anterior
              </button>
              <Link
                to="/services"
                className="btn btn-primary-admin next_btn"
                data-bs-toggle="modal"
                data-bs-target="#confirmEmailModal"
              >
                Enviar Correos
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Edittaxmodalpopup error={errors} loading={loading} onConfirm={sendMail} />
      <SuccessEmailModal show={showSuccessModal} onClose={() => setShowSuccessModal(false)} onConfirm={handleSuccessConfirm} />
    </>
  );
};

export default EmailSummary;