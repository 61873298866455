import { apiRepository } from "../../api/apiRepository";
import { getPaymentDetails } from "./mercadoPagoService";

export const fetchPaymentDetails = async (paymentId: string, token: string) => {
  try {
    return await getPaymentDetails(paymentId, token);
  } catch (error) {
    console.error('Error al obtener los detalles del pago:', error);
    return null;
  }
};

export const handlePaymentSuccess = async (paymentInfo: any, cartContext: any, setCart: any, openModal: any) => {
  try {
    const response =  await apiRepository.createPayments(paymentInfo);
      cartContext.clearCart(setCart);
      openModal("payment-success");
    if (response.data.pathPdf) {
      return response.data.pathPdf;
    }
    return null;
  } catch (error) {
    openModal("payment-error");
    console.error('Error al registrar el pago o limpiar el carrito:', error);
  }
};

export const handlePaymentFailed = async (paymentInfo: any, openModal: any) => {
  try {
    await apiRepository.createPayments(paymentInfo);
    openModal("payment-error");
    return null;
  } catch (error) {
    openModal("payment-error");
  }
};

export const createPaymentInfo = (user: any, paymentDetails: any, codesCoupons: any) => {
  return {
    userId: user?.userId || "Usuario no logeado",
    jsonResponse: JSON.stringify({ status: 'success' }),
    status: 'COMPLETED',
    paymentDetails,
    codesCoupons,
  };
};

export const parseBackUrlParams = (urlParams: any) => {
  const itemIds = urlParams.itemIds.split(',');
  const quantities = urlParams.quantity.split(',');
  const amountRaw = urlParams.amountRaw.split(',');
  const discountValue = urlParams.discountValue.split(',');
  const codesCoupons = urlParams.codesCoupons ? urlParams.codesCoupons.split(',') : [];
  const amount_total_raw = urlParams.amount_total_raw;
  const discount_general_value = urlParams.discount_general_value;
  const codesCouponsGeneral = urlParams.codesCouponsGeneral ? urlParams.codesCouponsGeneral.split(',') : [];
  const userId = urlParams.userId;
  const maxLength = Math.max(itemIds.length, quantities.length, amountRaw.length, discountValue.length);
  const amount_with_discount = parseFloat(amount_total_raw) - parseFloat(discount_general_value);

  const paymentDetails = [];
  for (let i = 0; i < maxLength; i++) {
    paymentDetails.push({
      packetId: itemIds[i] || null,
      quantity: quantities[i] || 1,
      amount: amountRaw[i] || 0,
      amount_discount: discountValue[i] || 0,
      coupon: codesCoupons[i] || "",
    });
  }


  const jsonResponse = {
    userId: userId || "Usuario no logeado",
    jsonResponse: JSON.stringify({ status: "success" }),
    status: "COMPLETED",
    amount: amount_with_discount || 0,
    amount_discount: parseFloat(discount_general_value) || 0,
    paymentDetails,
    codesCoupons: codesCouponsGeneral[0] ? [codesCouponsGeneral[0]] : [],
  };

  return jsonResponse;
};

export const preparePaymentInfo = (paymentData: any, couponDiscount: any, user: any) => {
  const paymentDetails = paymentData.additional_info.items.map((item: any) => {
    const specificDiscount = couponDiscount.specificDiscount?.find(
      (discount: any) => discount.packageId === item.id
    );

    return {
      packetId: Number(item.id),
      quantity: Number(item.quantity),
      amount: parseFloat(item.unit_price * item.quantity),
      amount_discount: parseFloat(item.discount || 0),
      coupon: specificDiscount ? specificDiscount.couponName : "",
    };
  });

  const totalAmount = paymentDetails.reduce((sum: number, item: any) => sum + item.amount, 0);
  const totalDiscount = paymentDetails.reduce((sum: number, item: any) => sum + item.amount_discount, 0);

  const generalCouponCode = couponDiscount.generalDiscount?.couponName || "";

  const codesCoupons = [
    generalCouponCode,
    ...paymentDetails
      .filter((item: any) => item.coupon)
      .map((item: any) => item.coupon)
  ];

  return {
    userId: user?.userId || "Usuario no logeado",
    jsonResponse: JSON.stringify({ status: paymentData.status }),
    status: paymentData.status.toUpperCase(),
    amount: totalAmount,
    amount_discount: totalDiscount,
    paymentDetails: paymentDetails,
    codesCoupons: codesCoupons,
  };
};
