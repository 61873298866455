import React, { createContext, useState, useEffect, ReactNode } from 'react';
import {
  getCart,
  addToCart,
  removeFromCart,
  clearCart,
  Cart,
  removeProductFromCart
} from '../services/cartService';

interface CartContextType {
  cart: Cart;
  setCart: React.Dispatch<React.SetStateAction<Cart>>;
  addToCart: (productId: string) => void;
  removeFromCart: (productId: string) => void;
  removeProductFromCart: (productId: string) => void;
  clearCart: () => void;
}

export const CartContext = createContext<CartContextType | undefined>(undefined);

interface CartProviderProps {
  children: ReactNode;
  userId: string | null;
}

export const CartProvider: React.FC<CartProviderProps> = ({ children, userId }) => {
  const [cart, setCart] = useState<Cart>(getCart(userId));

  useEffect(() => {
    setCart(getCart(userId)); // Sincroniza el carrito al cambiar el userId
  }, [userId]);

  const handleAddToCart = (productId: string) => addToCart(userId, productId, setCart);
  const handleRemoveFromCart = (productId: string) => removeFromCart(userId, productId, setCart);
  const handleRemoveProductFromCart = (productId: string) =>
    removeProductFromCart(userId, productId, setCart);
  const handleClearCart = () => clearCart(userId, setCart);

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        addToCart: handleAddToCart,
        removeFromCart: handleRemoveFromCart,
        removeProductFromCart: handleRemoveProductFromCart,
        clearCart: handleClearCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
