import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import StickyBox from 'react-sticky-box';
import ConfirmClassModal from '../../common/modals/confirm-class-modal';
import { DropdownField, CalendarRangeField } from '../../../admin/common/input/inputField';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment-timezone';
import { getDefaultWeekRange } from '../../../../core/utils/dateUtils';
import { useClasses } from '../../../../core/hooks/getData/useClasses';
import { useHeadquarters } from '../../../../core/hooks/getData/useHeadquarters';
import { useDateSchedules } from '../../../../core/hooks/getData/useRangeDateSchedules';
import { groupSchedulesByClassAndDay, sortGroupedSchedules } from '../../../../core/services/scheduleReservationService';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';

const CustomerBookingCalendar = () => {
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const [updateDays, setUpdateDays] = useState<boolean>();

  useEffect(() => {
    setBreadcrumbData({ title: "Reserva tu Clase", item2: `${updateDays} test`, updateDays: updateDays });
  }, [setBreadcrumbData, updateDays]);

  const [selectedClassItem, setSelectedClassItem] = useState<any>();
  const [groupedSchedules, setGroupedSchedules] = useState<Record<string, Record<string, any[]>>>();
  const [selectedHeadquarters, setSelectedHeadquarters] = useState<string>();
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>();
  const { classesData } = useClasses({ shouldTransform: true });
  const { headquartersData } = useHeadquarters({ shouldTransform: true });
  const { schedules, refetchSchedules } = useDateSchedules(dateRange);

  useEffect(() => {
    setDateRange(getDefaultWeekRange());
    setUpdateDays(true);
  }, []);

  useEffect(() => {
    formatSchedules();
  }, [dateRange, schedules]);

  const handleDateChange = (e: any) => {
    setDateRange(e.value);
  };

  const resetFilters = () => {
    setDateRange(getDefaultWeekRange());
    setSelectedHeadquarters('');
    formatSchedules();
  };

  const handleHeadquartersChange = (e: any) => {
    setSelectedHeadquarters(e.value);
  };

  const formatSchedules = async () => {
    try {
      if (!schedules) return;
      const groupedSchedules = groupSchedulesByClassAndDay(schedules);
      const sortedGroupedSchedules = sortGroupedSchedules(groupedSchedules);
      setGroupedSchedules(sortedGroupedSchedules);
    } catch (error) {
      console.error("Error al obtener los datos de horarios:", error);
    }
  };

  const onUpdateSchedules = () => {
    setUpdateDays(!updateDays)
    refetchSchedules();
  };

  const filteredClasses = selectedHeadquarters
    ? classesData.filter(classItem => classItem.headquartersId.toString() === selectedHeadquarters)
    : classesData;

  return (
    <>
      <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3 mb-4">
        <h4>Reservas</h4>
      </div>
      <StickyBox>
        <div className="card card-shadow shadow-none">
          <div className="card-body">
            <div className="d-flex justify-content-betwee">
              <h5 className="col mb-3">
                <i className="ti ti-filter-check me-2" />
                Filtros
              </h5>

              <div className="d-flex align-items-center justify-content-end mb-1">
                <button
                  type="button"
                  className="btn btn-light bg-transparent border-0 text-danger"
                  onClick={resetFilters}
                >
                  Limpiar Filtros
                </button>
              </div>
            </div>
            <form>
              <div className="row mb-3 pb-1 border-bottom">
              <div className="col-md-6 col-sm-12 mb-1">
                  <DropdownField
                    label="Selecciona Establecimiento"
                    options={headquartersData}
                    required={false}
                    value={selectedHeadquarters}
                    onChange={handleHeadquartersChange}
                    placeholder="Selecciona el establecimiento"
                  />
                </div>

                <div className="col-md-6 col-sm-12 mb-1">
                  <CalendarRangeField
                    label="Selecciona Rango de Fechas"
                    value={dateRange}
                    onChange={handleDateChange}
                  />
                </div>
              </div>
              {filteredClasses.map((classItem) => {
                const schedules = groupedSchedules[classItem.id] || {};
                const now = moment.tz('America/Lima');
                const availableClassesCount = Object.values(schedules).flat().filter((schedule) => {
                  const classStart = moment.tz(`${schedule.date} ${schedule.hourStart}`, 'YYYY-MM-DD HH:mm', 'America/Lima'); // Combina fecha y hora
                  return classStart.isAfter(now) || now.diff(classStart, 'minutes') <= 5;
                }).length;
                return (
                  <div className="accordion border-bottom mb-3" key={classItem.id}>
                    <div className="accordion-item">
                      <div
                        className="accordion-header"
                        id={`accordion-heading-${classItem.id}`}
                      >
                        <div
                          className="accordion-button p-0 mb-3"
                          data-bs-toggle="collapse"
                          data-bs-target={`#accordion-collapse-${classItem.id}`}
                          aria-expanded="true"
                          aria-controls={`accordion-collapse-${classItem.id}`}
                          role="button"
                        >
                          <div className="row w-100">
                            <div className="col-12">
                              <div className="service-cont">
                                <div className="service-cont-img class-images">
                                  <ImageWithBaseApi
                                    loadingIcon={
                                      <i className="fa fa-spinner fa-spin" style={{ fontSize: '54px', color: '#b0b0b0' }}></i>
                                    }
                                    routeName="class"
                                    src={classItem.image}
                                    alt={classItem.title}
                                  />
                                </div>
                                <div className="service-cont-info">
                                  <span className="badge bg-light fs-14 mb-2 d-flex align-items-center">
                                    {classItem.headquartersName}
                                    <span className="ms-2 badge bg-success">
                                      {availableClassesCount} clases disponibles
                                    </span>
                                  </span>
                                  <h3 className="title d-flex align-items-center">
                                    <Link to="#">{classItem.title}</Link>
                                    {classItem.isReformer == "SI" && (
                                      <i
                                        className="fa fa-crown text-warning ms-2"
                                        title="Clase Premium"
                                        style={{ fontSize: '1.2rem' }}
                                      ></i>
                                    )}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        id={`accordion-collapse-${classItem.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`accordion-heading-${classItem.id}`}
                      >
                        <div className="table-responsive reservation">
                          <table className="table table-reservation">
                            <thead>
                              <tr>
                                {Object.keys(schedules).map((day) => (
                                  <th key={day}>{day}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(schedules).length > 0 ? (
                                <tr>
                                  {Object.entries(schedules).map(([day, classes]) => (
                                    <td key={day}>
                                      {classes.length > 0 ? (
                                        classes
                                          .sort((a, b) => moment(a.hourStart, 'HH:mm').diff(moment(b.hourStart, 'HH:mm')))
                                          .map((schedule, index) => {
                                            const classStart = moment.tz(`${schedule.date} ${schedule.hourStart}`, 'YYYY-MM-DD HH:mm', 'America/Lima');
                                            const now = moment.tz('America/Lima');
                                            const isClickable = classStart.isAfter(now) || now.diff(classStart, 'minutes') <= 5;

                                            return (
                                              <div
                                                key={index}
                                                data-bs-toggle={isClickable ? "modal" : ""}
                                                data-bs-target={isClickable ? "#confirm-class" : ""}
                                                className={`class-item ${isClickable ? "" : "disabled"} ${isClickable ? "" : "bg-disabled"}`}
                                                onClick={isClickable ? () => setSelectedClassItem(schedule) : undefined}
                                                title={isClickable ? "" : "Clase no disponible"}
                                              >
                                                <h6>{schedule.classTypeDescription || schedule.title}</h6>
                                                <p>
                                                  {schedule.hourStart} - {schedule.hourEnd}
                                                </p>
                                                {schedule.trainerName || "Entrenador sin asignar"}
                                                {!isClickable && (
                                                  <i className="fa fa-clock text-warning ms-2" title="Clase no disponible" style={{ fontSize: '1.1rem' }}></i>
                                                )}
                                              </div>
                                            );
                                          })
                                      ) : (
                                        <div className="class-item">
                                          <p>No hay clases</p>
                                        </div>
                                      )}
                                    </td>
                                  ))}
                                </tr>
                              ) : (
                                <tr>
                                  <td colSpan={Object.keys(schedules).length}>No hay clases disponibles</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </form>
          </div>
        </div>
      </StickyBox>
      <ConfirmClassModal selectedClassData={selectedClassItem} onConfirmSuccess={onUpdateSchedules} />
    </>
  );
};

export default CustomerBookingCalendar;