import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetProfitabilityClasses = (dateRange?: [Date, Date], customerId?: string, minAge?: string, maxAge?: string) => {
    const [profitabilityClasses, setProfitabilityClasses] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchProfitabilityClasses = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getClassProfitabilityReport(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
                customerId,
                minAge,
                maxAge
            );
            const profitabilityClasses = response.data.data;
            setProfitabilityClasses(profitabilityClasses);
        } catch (err) {
            console.error("Error al obtener los datos de reservas:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const isAgeRangeValid = (minAge && maxAge) || (!minAge && !maxAge);
        if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) && isAgeRangeValid) {
            fetchProfitabilityClasses();
        }
    }, [dateRange, customerId, minAge, maxAge]);

    return {
        profitabilityClasses,
        isLoading,
        error,
        refetchProfitabilityClasses: fetchProfitabilityClasses,
    };
};