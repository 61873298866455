import React, { useState, useEffect, useContext } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { validateSchedule, ValidationError } from '../../../../core/form/validationForm';
import { InputField, CalendarField, TimePickerField, DropdownField, AutoCompleteDropdown } from '../../../admin/common/input/inputField';
import { handleChange,  formatDatetoString } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import { CLASS_STATUS, MINUTES_SUGGESTINOS, TYPE_OF_RECURRENCI } from '../../../../core/data/options/options';
import BaseModal from './baseModal';
import { convertStringToLocalDate, convertDateToLocalDateString } from '../../../../core/utils/dateUtils';
import { AuthContext } from '../../../../core/context/AuthContext';
import moment from 'moment-timezone';
import { UpdateScheduleModalProps } from '../../../../core/Interface/modalInterface';
import { useSubTypeByClassId } from '../../../../core/hooks/getData/useSubTypeByClassId';

const SchedulesModal: React.FC<UpdateScheduleModalProps> = ({ selectedSchedule, onUpdateSuccess, trainerList }) => {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        return <div>Error: Auth context not found!</div>;
    }
    const { user } = authContext;
    const [formData, setFormData] = useState<any>({});
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [classes, setClasses] = useState([]);
    const [headquarters, setHeadquarters] = useState([]);
    const [trainerWarning, setTrainerWarning] = useState(false);

    const [filteredSuggestions, setFilteredSuggestions] = useState(MINUTES_SUGGESTINOS);
    const handleSearch = (query: string) => {
        setFilteredSuggestions(
            MINUTES_SUGGESTINOS.filter((option) =>
                option.label.toLowerCase().includes(query.toLowerCase())
            )
        );
    };

    const loadClasses = async () => {
        try {
            const classData = await apiRepository.getClasses();
            setClasses(classData.data.data.map((classItem: { id: string, title: string, headquartersId: string }) => ({
                label: classItem.title,
                value: classItem.id,
                headquartersId: classItem.headquartersId,
            })));
        } catch (error) {
            console.error('Error al obtener las clases:', error);
        }
    };

    const loadHeadquarters = async () => {
        try {
            const headquarterData = await apiRepository.getHeadquarters();
            setHeadquarters(headquarterData.data.data.map((headquarterItem: { id: string, name: string }) => ({
                label: headquarterItem.name,
                value: headquarterItem.id,
            })));
        } catch (error) {
            console.error('Error al obtener las clases:', error);
        }
    };

    const filteredClasses = classes.filter((classItem) =>
        !formData?.headquarterId || classItem.headquartersId === formData?.headquarterId
    );

    useEffect(() => {
        loadHeadquarters();
        loadClasses();
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        handleChange(e, setFormData);
        if (e.target.name === 'trainerId') {
            setTrainerWarning(true);
        }
        if (e.target.name === 'classId' && !formData.title) {
            const selectedClass = classes.find(classItem => classItem.value === e.target.value);
            if (selectedClass) {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    title: selectedClass.label,
                }));
            }
        }
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setFormData([]);
        setLoading(false);
        setTrainerWarning(false);
    };

    useEffect(() => {
        if (selectedSchedule) {
            const start = moment(selectedSchedule.hourStart, "HH:mm");
            const end = moment(selectedSchedule.hourEnd, "HH:mm");
            const minutesDifference = end.diff(start, 'minutes');

            setFormData({
                id: selectedSchedule.id,
                hourEnd: selectedSchedule.hourEnd,
                hourStart: selectedSchedule.hourStart || "07:00",
                quantity: selectedSchedule.quantity,
                status: selectedSchedule.status || CLASS_STATUS[0].value,
                title: selectedSchedule.title,
                trainerId: selectedSchedule.trainerId,
                classId: selectedSchedule.classId,
                classTypeId: selectedSchedule.classTypeId,
                typeRecurrent: selectedSchedule.typeRecurrent || '',
                headquarterId: selectedSchedule.headquarterId || '',
                quantityRecurrent: selectedSchedule.quantityRecurrent || 0,
                minutes: minutesDifference,
                date: selectedSchedule.date && !isNaN(new Date(selectedSchedule.date).getTime())
                    ? convertStringToLocalDate(selectedSchedule.date)
                    : convertStringToLocalDate(formatDatetoString(new Date())),
            });
            setShow(true);
        }
    }, [selectedSchedule]);

    const { subTypeClassesData } = useSubTypeByClassId(formData.classId);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const start = moment(formData.hourStart, "HH:mm");
        const end = start.clone().add(Number(formData.minutes), 'minutes');

        const errors = await handleFormSubmit(
            formData.id,
            formData,
            validateSchedule,
            {
                create: apiRepository.createSchedule,
                update: apiRepository.updateSchedule,
            },
            () => {
                onUpdateSuccess?.();
                handleClose();
            },
            (data) => {
                const { minutes, classId, ...filteredData } = data;
                return {
                    ...filteredData,
                    date: convertDateToLocalDateString(data.date),
                    hourEnd: end.format("HH:mm"),
                };
            },
            "Error al procesar la solicitud. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <BaseModal
            show={show || false}
            title={formData.id
                ? user?.role === 'admin'
                    ? "Editar Horario"
                    : "Editar Clase"
                : user?.role === 'admin'
                    ? "Agregar Horario"
                    : "Agregar Clase"
            }
            onClose={handleClose}
            onConfirm={handleConfirmCreate}
            loading={loading}
        >
<form onSubmit={handleConfirmCreate}>
    <div className="row">
        <div className="col-md-6">
            <DropdownField
                label="Sede"
                name="headquarterId"
                value={formData.headquarterId || '' }
                options={headquarters}
                onChange={handleInputChange}
                placeholder="Selecciona una sede de entrenamiento"
                error={getErrorMessage(errors, 'headquarterId')}
            />
        </div>
        <div className="col-md-6">
            <DropdownField
                label="Clase"
                name="classId"
                value={formData.classId || ''}
                options={filteredClasses}
                onChange={handleInputChange}
                placeholder="Selecciona la clase del horario"
                error={getErrorMessage(errors, 'classId')}
            />
        </div>
    </div>
    {user?.role === 'admin' && (
        <>
            <div className="row">
                <div className="col-md-6">
                    <DropdownField
                        label="Selecciona al entrenador"
                        name="trainerId"
                        options={trainerList}
                        value={formData.trainerId}
                        onChange={handleInputChange}
                        placeholder="Selecciona al entrenador para la clase"
                        error={getErrorMessage(errors, 'trainerId')}
                    />
                </div>
                <div className="col-md-6">
                    <DropdownField
                        label="Tipo de Clase"
                        value={formData?.classTypeId}
                        options={subTypeClassesData}
                        name="classTypeId"
                        required={true}
                        onChange={handleInputChange}
                        placeholder="Selecciona el tipo de clase"
                        error={getErrorMessage(errors, 'classTypeId')}
                    />
                </div>
            </div>
            {trainerWarning && formData.id && (
                <div className="alert alert-warning" role="alert">
                    ¿Estás seguro de que deseas cambiar al entrenador?
                </div>
            )}
        </>
    )}

    <div className="row">
        <div className="col-md-6">
            <InputField
                label="Título de la clase"
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                placeholder="Ingresa el título de la clase"
                error={getErrorMessage(errors, 'title')}
            />
        </div>
        <div className="col-md-6">
            <InputField
                label="Límite de cupos"
                name="quantity"
                value={formData.quantity || ''}
                onChange={handleInputChange}
                placeholder="Indica el límite de cupos"
                error={getErrorMessage(errors, 'quantity')}
            />
        </div>
    </div>
    <div className="row">
        <div className="col-md-6">
            <CalendarField
                label="Fecha"
                name="date"
                value={formData.date || ''}
                onChange={(e) => {
                    setFormData((prevFormData) => ({
                        ...prevFormData,
                        date: convertStringToLocalDate(e.value),
                    }));
                }}
                placeholder="Selecciona la fecha de la clase"
                error={getErrorMessage(errors, 'date')}
            />
        </div>
        <div className="col-md-6">
            <DropdownField
                label="Estado de la clase"
                name="status"
                options={CLASS_STATUS}
                value={formData.status}
                onChange={handleInputChange}
                placeholder="Selecciona el estado de la clase"
                error={getErrorMessage(errors, 'status')}
            />
        </div>
    </div>
    <div className="row">
        <div className="col-md-6">
            <TimePickerField
                label="Hora de Inicio"
                value={formData.hourStart || ''}
                format="hh:mm a"
                onChange={(newValue) => setFormData(prevFormData => ({
                    ...prevFormData,
                    hourStart: newValue,
                }))}
                placeholder="Selecciona la hora de inicio"
                error={getErrorMessage(errors, 'hourStart')}
            />
        </div>
        <div className="col-md-6">
            <AutoCompleteDropdown
                label="Duración de la clase en minutos"
                value={formData.minutes || ''}
                suggestions={filteredSuggestions}
                name="minutes"
                onChange={(val) => setFormData(prevFormData => ({
                    ...prevFormData,
                    minutes: val,
                }))}
                onSearch={handleSearch}
                placeholder="Selecciona la duración en minutos"
                error={getErrorMessage(errors, 'minutes')}
            />
        </div>
    </div>
    {!formData.id && (
        <>
            <div className="row">
                <div className="col">
                    <DropdownField
                        label="Tipo de Recurrencia"
                        name="typeRecurrent"
                        required={false}
                        options={TYPE_OF_RECURRENCI}
                        value={formData.typeRecurrent}
                        onChange={handleInputChange}
                        placeholder="Selecciona el tipo de recurrencia (si aplica)"
                        error={getErrorMessage(errors, 'typeRecurrent')}
                    />
                </div>
                {formData.typeRecurrent && (
                    <div className="col-md-6">
                        <InputField
                            label="Número de recurrencia"
                            name="quantityRecurrent"
                            value={formData.quantityRecurrent || ''}
                            onChange={handleInputChange}
                            placeholder="Indica la cantidad de recurrencia"
                            error={getErrorMessage(errors, 'quantityRecurrent')}
                        />
                    </div>
                )}
            </div>
            {formData.typeRecurrent && (
                <div className="alert alert-warning mt-2" role="alert">
                    <i className="fa fa-exclamation-triangle me-2"></i>
                    Verifica los campos de recurrencia, esta acción no es reversible.
                </div>
            )}
        </>
    )}

    {errors.find(error => error.field === 'server') && (
        <div className="alert alert-danger" role="alert">
            {getErrorMessage(errors, "server")}
        </div>
    )}
</form>

        </BaseModal>
    );
};

export default SchedulesModal;