import React from 'react';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';

interface DashWidgetProps {
  iconClass: string;
  bgClass: string;
  title: string;
  value: string | number;
  duration: number;
  hourStart?: string;
  hourEnd?: string;
  link?: string;
}

const DashWidget: React.FC<DashWidgetProps> = ({
    iconClass,
    bgClass,
    title,
    value,
    duration,
    hourStart,
    hourEnd,
    link,
  }) => {
    const content = (
      <div className="card card-shadow dash-widget">
        <div className="card-body d-flex justify-content-between align-items-start">
          <div className="d-flex w-100">
            <div className="d-flex w-100 align-items-center">
              <span
                className={`dash-icon ${bgClass} d-flex justify-content-center align-items-center rounded-circle`}
                style={{minWidth:"35px", minHeight: "35px"}}
              >
                <i className={iconClass} />
              </span>
              <div className="ms-2 w-100 truncate-text">
                <span className="fs-14">{title}</span>
                <h5 className="text-capitalize">
                  {' '}
                  <span className="counter">
                    {typeof value === 'number' ? (
                      <CountUp end={value} duration={duration} />
                    ) : (
                      value
                    )}
                  </span>
                </h5>
              </div>
              {hourStart && hourEnd && (
                <div className="d-flex justify-content-between mt-1">
                  <div className="text-end">
                    <span className="fs-12 d-block">{hourStart}</span>
                    <span className="fs-12 d-block">{hourEnd}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="col-xxl-4 col-md-4">
        {link ? (
          <Link to={link}>
            {content}
          </Link>
        ) : (
          content
        )}
      </div>
    );
  };

  export default DashWidget;