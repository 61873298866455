import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { getStoredCarts, saveStoredCarts } from '../services/cartService';
import { AuthUser } from '../Interface/contextInterface';
import { AuthContextType } from '../Interface/contextInterface';
import { closeAllModals } from '../utils/modalsUtils';

// Define constants
const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes

export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<AuthUser | null>(() => {
    const token = Cookies.get('token');
    if (token) {
      const username = localStorage.getItem('username');
      const userId = localStorage.getItem('userId');
      const role = localStorage.getItem('role');
      return token && username && userId && role
        ? { token, username, userId: Number(userId), role }
        : null;
    }
    return null;
  });

  const navigate = useNavigate();
  const location = useLocation();
  const inactivityTimer = useRef<NodeJS.Timeout | null>(null);

  const logout = useCallback(() => {
    setUser(null);
    Cookies.remove('token');
    localStorage.clear();
    closeAllModals();
    navigate('/authentication/login');
  }, [navigate]);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimer.current) {
      clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = setTimeout(() => {
      logout();
    }, INACTIVITY_TIMEOUT);
  }, [logout]);

  useEffect(() => {
    if (user && user.role !== 'admin') {
      resetInactivityTimer();

      const events = ['mousemove', 'keydown', 'scroll'];
      events.forEach(event => window.addEventListener(event, resetInactivityTimer));

      return () => {
        if (inactivityTimer.current) {
          clearTimeout(inactivityTimer.current);
        }
        events.forEach(event => window.removeEventListener(event, resetInactivityTimer));
      };
    }
  }, [user, resetInactivityTimer]);

  const login = (userData: AuthUser) => {
    setUser(userData);
    Cookies.set('token', userData.token, {
      expires: 7,
      secure: false,
      sameSite: 'Lax',
    });
    localStorage.setItem('username', userData.username);
    localStorage.setItem('userId', userData.userId.toString());
    localStorage.setItem('role', userData.role);

    if (userData.role !== 'admin') {
      resetInactivityTimer();
    }

    mergeCartWithUser(userData.userId);
    if (location.pathname === '/perfil/cart') {
      return;
    }

    switch (userData.role) {
      case 'admin':
        navigate('/admin');
        break;
      case 'trainer':
        navigate('/perfil/customer-dashboard');
        break;
      default:
        navigate('/');
    }
  };

  const mergeCartWithUser = (userId: number) => {
    const carts = getStoredCarts();
    const guestCart = carts['general'] || [];
    const userCart = carts[userId.toString()] || [];

    const mergedCart = [...userCart];

    guestCart.forEach((guestProduct) => {
      const existingProduct = mergedCart.find((product) => product.id === guestProduct.id);
      if (existingProduct) {
        existingProduct.quantity += guestProduct.quantity;
      } else {
        mergedCart.push(guestProduct);
      }
    });

    carts[userId.toString()] = mergedCart;
    delete carts['general'];
    saveStoredCarts(carts);
  };

  const updateUserData = (updates: Partial<AuthUser>) => {
    setUser((prevUser) => {
      if (prevUser) {
        const updatedUser = { ...prevUser, ...updates };
        if (updates.username) {
          localStorage.setItem('username', updates.username);
        }
        return updatedUser;
      }
      return prevUser;
    });
  };

  const isAuthenticated = !!user;

  return (
    <AuthContext.Provider value={{ user, login, logout, updateUserData, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};