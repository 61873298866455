import { useState, useEffect, useCallback } from "react";
import { apiRepository } from "../../../api/apiRepository";

export const usePaymentDays = (userId: number) => {
  const [paymentDays, setPaymentDays] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchPaymentDays = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiRepository.getPaymentDayByUserId(userId);
      setPaymentDays(response.data.data.content);
    } catch (error) {
      console.error('Error al obtener los datos del usuario:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchPaymentDays();
    }
  }, [userId, fetchPaymentDays]);

  return {
    paymentDays,
    isLoading,
    refetch: fetchPaymentDays,
  };
};
