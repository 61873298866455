import { useState, useEffect } from 'react';
import { AuthContextType } from '../../Interface/contextInterface';
import { useLocation } from 'react-router-dom';
import { fetchPaymentDetails, handlePaymentSuccess, handlePaymentFailed, parseBackUrlParams } from '../../services/paymentService';
import { getQueryParams } from '../../utils/utils';
import { isPaymentSuccessful } from '../../utils/paymentUtils';
import { openModal } from '../../utils/modalsUtils';

export const useCartLogic = (cartContext: any, authContext: AuthContextType, token: string) => {
  const { user } = authContext;
  const { setCart } = cartContext;
  const location = useLocation();
  const [pathPdf, setPathPdf] = useState();
  const [hasExecuted, setHasExecuted] = useState<boolean>();
  const [isProcessing, setIsProcessing] = useState<boolean>(true);

  const clearCartIfSuccess = async () => {
    if (hasExecuted) return;
    const { success, paymentId, ...rest } = getQueryParams(location.search);
    const formattedPaymentDetails = parseBackUrlParams({ ...rest, paymentId });

    if (!isPaymentSuccessful(success, paymentId)) {
      setIsProcessing(false);
      await handlePaymentFailed({
        ...formattedPaymentDetails,
        jsonResponse: "{\"status\":\"failed\"}",
        status: "FAILED",
      }, openModal);
      return;
    }

    try {
      const paymentData = await fetchPaymentDetails(paymentId, token);
      if (!paymentData) {
        setIsProcessing(false);
        return;
      }

      const pathPdf = await handlePaymentSuccess(
        { ...formattedPaymentDetails, paymentId:paymentData.id, jsonResponse: JSON.stringify(paymentData) },
        cartContext,
        setCart,
        openModal
      );
      if (pathPdf) setPathPdf(pathPdf);
    } catch (error) {
      openModal("payment-error");
    }
    setHasExecuted(true);
    setIsProcessing(false);
  };

  useEffect(() => {
    const { success, paymentId } = getQueryParams(location.search);
    if (!success && !paymentId) {
      setIsProcessing(false);
      return;
    }

    if (token) {
      clearCartIfSuccess();
    };
  }, [location.search, token]);

  return { pathPdf, isProcessing };
};
