import React from 'react';
import { Link } from 'react-router-dom';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { all_routes } from '../../../../core/data/routes/all_routes';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import FloatingWhatsAppButton from '../../common/floatingWhatsAppButton/floatingWhatsAppButton';
import { useEnterpriseData } from '../../../../core/hooks/getData/useEnterpriseData';
import { useCreateSubscription } from '../../../../core/hooks/form/useCreateSuscription';

const FooterOne = () => {
  const routes = all_routes
  const enterpriseData = useEnterpriseData();
  const { formData, handleChange, handleCreateSubscription, loading, serverError } = useCreateSubscription();

  return (
    <footer className="footer footer-second ">
      {/* Footer Top */}
      <div className="footer-top" >
        <div className="container col-11">
          <div className="row flex-lg-row flex-md-column">
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <div className="footer-logo">
                  {enterpriseData?.image && (
                    <Link to={routes.index}>
                    <ImageWithBaseApi
                      src={`${enterpriseData?.image}`}
                      routeName="enterprise"
                      className="img-fluid"
                      alt="logo"
                    />
                  </Link>
                  )}
                </div>
                <div className="footer-content">
                  { enterpriseData?.phone && (
                    <div>
                      <p className="fs-16">
                        <span className="ti ti-device-mobile me-2" />
                        { enterpriseData?.phone }
                      </p>
                    </div>
                  )}
                  { enterpriseData?.phone && (
                    <div>
                      <p className="fs-16">
                        <span className="ti ti-device-mobile me-2" />
                        { enterpriseData?.email }
                        </p>
                    </div>
                  )}
                </div>

              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
                <h2 className="footer-title">Paginas</h2>
                <ul className="m-0 p-0">
                  <li>
                    <Link to={routes.index}>Home</Link>
                  </li>
                  <li>
                    <Link to={routes.customerBooking}>Agenda</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
                <h2 className="footer-title">Ayuda</h2>
                <ul className="m-0 p-0">
                  <li>
                    <Link to={routes.faq}>FAQS</Link>
                  </li>
                  <li>
                    <Link to={routes.complaintBook}>Libro de reclamaciones</Link>
                  </li>
                </ul>
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-2 col-md-4">
              {/* Footer Widget */}
              <div className="footer-widget footer-menus">
              <h2 className="footer-title">Siguenos en:</h2>
                <div className="social-icon">
                  <ul className="mb-0">
                    { enterpriseData?.facebook && (
                      <li>
                        <a href={enterpriseData.facebook} target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-facebook" style={{marginLeft: "2px", marginTop: "2px"}} />
                        </a>
                      </li>
                    )}
                    { enterpriseData?.instagram && (
                      <li>
                        <a href={enterpriseData.instagram} target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-instagram" />
                        </a>
                      </li>
                    )}
                    { enterpriseData?.youtube && (
                      <li>
                        <a href={enterpriseData.youtube} target="_blank" rel="noopener noreferrer">
                          <i className="fa-brands fa-youtube" />
                        </a>
                      </li>
                    )}
                  </ul>
                </div>
                <FloatingWhatsAppButton
                  numberWhatsApp={enterpriseData?.whatsapp ? Number(enterpriseData.whatsapp) : 0} 
                />
              </div>
              {/* /Footer Widget */}
            </div>
            <div className="col-lg-3 col-md-6">
              {/* Footer Widget */}
              <div className="footer-widget">
                <h2 className="footer-subtitle">Suscribete</h2>
                <div className="subscribe-form mb-4">
                  <div className="row">
                    <div className="col-12">
                      <input
                        type="text"
                        value={formData?.fullName || ''}
                        onChange={handleChange}
                        name="fullName"
                        className="form-control"
                        placeholder="Ingresa tu nombre completo"
                      />
                      {serverError?.find(error => error.field === 'fullName') && (
                        <small className="text-danger mt-2">{getErrorMessage(serverError, "fullName")}</small>
                      )}
                    </div>
                    <div className="col">
                      <input
                        type="email"
                        value={formData?.email || ''}
                        onChange={handleChange}
                        name="email"
                        className="form-control mt-3"
                        placeholder="Ingresa tu correo"
                      />
                    </div>
                    <div className="col-2 justify-content-end d-flex">
                      <button onClick={handleCreateSubscription} className="btn btn-primary-user mt-3">
                      {loading ? (
                      <>
                        <i className="fa fa-spinner fa-spin" />
                      </>
                    ) : (
                      <i className="fa fa-paper-plane" />
                    )}
                      </button>
                    </div>
                    {serverError?.find(error => error.field === 'email') && (
                        <small className="text-danger mt-2">{getErrorMessage(serverError, "email")}</small>
                    )}
                    {serverError?.find(error => error.field === 'server') && (
                      <small className="text-danger mt-2">{getErrorMessage(serverError, "server")}</small>
                    )}
                    {serverError?.find(error => error.field === 'success') && (
                      <small className="text-success mt-2">{getErrorMessage(serverError, "success")}</small>
                    )}
                  </div>
                </div>
              </div>
              {/* /Footer Widget */}
            </div>
          </div>
        </div>
      </div>
      {/* /Footer Top */}
      {/* Footer Bottom */}
      <div className="footer-bottoms">
        <div className="container col-11">
          {/* Copyright */}
          <div className="copyright">
            <div className="row align-items-center justify-content-between">
              <div className="col-md-6">
                <div className="copyright-text">
                  <p className="mb-0 fs-14">
                    © 2024 Lift. Todos los derechos reservados.
                  </p>
                </div>
              </div>
              <div className="col-md-6">
                {/* Copyright Menu */}
                <div className="copyright-menu">
                  <ul className="policy-menu">
                    <li>
                      <Link to={routes.privacyPolicy}>Politica de Privacidad</Link>
                    </li>
                    <li>
                      <Link to={routes.termsCondition}>Terminos y Condiciones</Link>
                    </li>
                  </ul>
                </div>
                {/* /Copyright Menu */}
              </div>
            </div>
          </div>
          {/* /Copyright */}
        </div>
      </div>
      {/* /Footer Bottom */}
    </footer>

  );
};

export default FooterOne;
