export const inActiveServicesData = [
  {
    '#': '1',
    service: 'Car Repair Services',
    category: 'Automobile',
    subCategory: 'Other',
    price: '$50',
    duration: '10:00',
    status: 'Inactive',
    createdBy: 'Admin',
    img: 'assets/admin/img/services/service-02.jpg',
  },
  {
    '#': '2',
    service: 'Computer Repair',
    category: 'Computer',
    subCategory: 'Other',
    price: '$80',
    duration: '15:00',
    status: 'Inactive',
    createdBy: 'Provider',
    img: 'assets/admin/img/services/service-03.jpg',
  },
  {
    '#': '3',
    service: 'Steam Car Wash',
    category: 'Car Wash',
    subCategory: 'Other',
    price: '$14',
    duration: '17:00',
    status: 'Inactive',
    createdBy: 'Provider',
    img: 'assets/admin/img/services/service-04.jpg',
  },
  {
    '#': '4',
    service: 'House Cleaning',
    category: 'Cleaning',
    subCategory: 'Other',
    price: '$100',
    duration: '20:00',
    status: 'Inactive',
    createdBy: 'Admin',
    img: 'assets/admin/img/services/service-05.jpg',
  },
]