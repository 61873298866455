import {useState, useEffect, useCallback} from "react";
import { apiRepository } from "../../../api/apiRepository";
import { TYPE_IDENTIFIER } from '../../../core/data/options/options';
import { convertStringToLocalDate } from "../../utils/dateUtils";
import { Users } from "../../Interface/repositoryInterfaces";

export const useUserData = (userId: number) => {
    const [formData, setFormData] = useState<Users>({} as Users);

    const fetchUserData = async () => {
        try {
            const response = await apiRepository.getIdUsers(userId);
            const userData = response.data.data;
            let department = '', province = '', district = '';
            if (userData.locationName) {
                try {
                    const decodedLocationName = decodeURIComponent(escape(userData.locationName));
                    [department, province, district] = decodedLocationName.split(' - ').map((part: string) => part.toUpperCase());
                } catch (e) {
                    [department, province, district] = userData.locationName.split(' - ').map((part: string) => part.toUpperCase());
                }
            }
            setFormData({
                ...userData,
                department: department || '',
                province: province || '',
                district: district || '',
                birthdate: userData.birthdate ? convertStringToLocalDate(userData.birthdate) : null,
                typeDocument: userData.documentType || TYPE_IDENTIFIER[0].value,
                password: ''
            });
        } catch (error) {
            console.error('Error al cargar los datos del usuario:', error);
        }
    };

    const updateFormData = (newData: Partial<typeof formData>) => {
        setFormData((prevData) => ({
          ...prevData,
          ...newData,
        }));
      };


    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLInputElement>) => {
        const { name, type, checked, value } = e.target;
        const fieldValue = type === 'checkbox' ? checked : value;
        setFormData(prev => ({
        ...prev,
        [name]: fieldValue,
        }));
    }, []);

    useEffect(() => {
        fetchUserData();
    }, [userId]);

    return { formData, handleChange, updateFormData, refetchUserData: fetchUserData, setFormData};
};