import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Faq } from '../../../../core/Interface/repositoryInterfaces';
import { validateFaq, ValidationError } from '../../../../core/form/validationForm';
import { InputField, SwitchField } from '../input/inputField';
import BaseModal from './baseModal';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleChange } from '../../../../core/utils/utils';
import { handleFormSubmit } from '../../../../core/services/formService';
import DefaultEditor from 'react-simple-wysiwyg';
import { UpdateFaqModalProps } from '../../../../core/Interface/modalInterface';

const FaqModal: React.FC<UpdateFaqModalProps> = ({ selectedFaq, onUpdateSuccess }) => {
    const [faqData, setFaqData] = useState<Faq>();
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>();

    useEffect(() => {
        if (selectedFaq) {
                setFaqData({
                    id: selectedFaq.id,
                    title: selectedFaq.title,
                    description: selectedFaq.description,
                    status: selectedFaq.status !== undefined && selectedFaq.status !== null ? selectedFaq.status : true,
                });
            setShow(true);
        }
    }, [selectedFaq]);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setFaqData);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setFaqData([]);
    };

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const errors = await handleFormSubmit(
            faqData?.id || -1,
            faqData,
            validateFaq,
            {
                create: apiRepository.createFaq,
                update: apiRepository.updateFaq,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            undefined,
            "Ocurrió un error al intentar guardar la FAQ. Inténtalo de nuevo."
        );

        if (errors) setErrors(errors);
        setLoading(false);
    };

    return (
        <BaseModal
            show={show || false}
            title="FAQ"
            onClose={handleClose}
            onConfirm={handleConfirmCreate}
            loading={loading}
        >
            <form onSubmit={handleConfirmCreate}>
                <InputField
                    label="Título"
                    name="title"
                    value={faqData?.title || ''}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, "title")}
                />
                <div className="mb-3">
                    <div className="form-group">
                        <label> Respuesta <span className="text-danger"> *</span></label>
                        <div id="editor">
                            <DefaultEditor
                                name="description"
                                value={faqData?.description || ''}
                                onChange={handleInputChange}
                            />
                            {errors.find(error => error.field === 'description') && (
                                <small className="text-danger">
                                    {getErrorMessage(errors, "description")}
                                </small>
                            )}
                        </div>
                    </div>
                </div>
                <SwitchField
                    label="Estado"
                    name="status"
                    checked={faqData?.status}
                    onChange={handleInputChange}
                    disabled={!faqData?.id}
                />
                {errors.find(error => error.field === 'server') && (
                    <div className="alert alert-danger" role="alert">
                        {getErrorMessage(errors, "server")}
                    </div>
                )}
            </form>
        </BaseModal>
    );
};

export default FaqModal;