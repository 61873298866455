import { useEffect } from 'react';

export const useQueryParams = ({
    setDateRange,
    setClassId,
    setTrainerId,
    setSelectedClassLabel,
    setSelectedTrainerLabel,
    setCurrentPage,
    setRows
}) => {
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const dateRangeParam = params.get('dateRange');
        const classIdParam = params.get('classId');
        const trainerIdParam = params.get('trainerId');
        const selectedClassLabelParam = params.get('selectedClassLabel');
        const selectedTrainerLabelParam = params.get('selectedTrainerLabel');
        const currentPageParam = params.get('currentPage');
        const rowsParam = params.get('rows');

        if (dateRangeParam) {
            const parsedDateRange = JSON.parse(dateRangeParam).map((date: string) => new Date(date));
            setDateRange(parsedDateRange);
        }
        if (classIdParam) setClassId(classIdParam);
        if (trainerIdParam) setTrainerId(trainerIdParam);
        if (selectedClassLabelParam) setSelectedClassLabel(selectedClassLabelParam);
        if (selectedTrainerLabelParam) setSelectedTrainerLabel(selectedTrainerLabelParam);
        if (currentPageParam) setCurrentPage(Number(currentPageParam));
        if (rowsParam) setRows(Number(rowsParam));
    }, [
        setDateRange,
        setClassId,
        setTrainerId,
        setSelectedClassLabel,
        setSelectedTrainerLabel,
        setCurrentPage,
        setRows
    ]);
};
