import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface UpdatePhotoModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
  loading: boolean;
}

const UpdatePhotoModal: React.FC<UpdatePhotoModalProps> = ({ show, onClose, onConfirm, loading }) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      <Modal.Title>Confirmar actualización</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>¿Estás seguro de que deseas actualizar tu foto de perfil?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onClose}>
        Cancelar
      </Button>
      <Button
        variant="primary-user"
        onClick={onConfirm}
        disabled={loading}
      >
        {loading ? (
          <>
            <span
              className="spinner-border spinner-border-sm me-2"
              role="status"
              aria-hidden="true"
            ></span>
            Guardando...
          </>
        ) : (
          'Confirmar'
        )}
      </Button>
    </Modal.Footer>
  </Modal>
);

export default UpdatePhotoModal;