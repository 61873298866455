export const DataBackupdata = [
  {
    id: 1,
    name: 'Sed perspiciatis omni_File.sql',
    date: '27 Sep 2022 10:11AM',
    class: 'action-language',
  },
  {
    id: 2,
    name: 'Sed perspiciatis omni_File.sql',
    date: '27 Sep 2022 10:11AM',
    class: 'action-language',
  },
  {
    id: 3,
    name: 'Sed perspiciatis omni_File.sql',
    date: '27 Sep 2022 10:11AM',
    class: 'action-language',
  },
  {
    id: 4,
    name: 'Sed perspiciatis omni_File.sql',
    date: '27 Sep 2022 10:11AM',
    class: 'action-language',
  },
  {
    id: 5,
    name: 'Sed perspiciatis omni_File.sql',
    date: '27 Sep 2022 10:11AM',
    class: 'action-language',
  },
];
