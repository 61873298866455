import React from "react";
import { Link } from "react-router-dom";

interface SuccessModalProps {
  onContinue?: () => void;
}

const SuccessRecoverModal: React.FC<SuccessModalProps> = ({ onContinue }) => {
  return (
    <div
      className="modal fade"
      id="success-modal"
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
            <Link to="#" data-bs-dismiss="modal" aria-label="Close">
              <i className="ti ti-circle-x-filled fs-20" />
            </Link>
          </div>
          <div className="modal-body p-4">
            <div className="text-center">
              <span className="success-check mb-3 mx-auto">
                <i className="ti ti-check" />
              </span>
              <h4 className="mb-2">Éxito</h4>
              <p>Tu nueva contraseña ha sido guardada con éxito</p>
              <div>
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  className="btn btn-lg btn-linear-primary w-100"
                  onClick={onContinue}
                >
                  Continuar con mi sesión
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessRecoverModal;