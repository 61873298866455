import { validateLogin } from "../../form/validationForm";
import { useState } from "react";
import { useForm } from "../../hooks/utils/useForm";
import { ValidationError } from "../../form/validationForm";
import { apiRepository } from "../../../api/apiRepository";
import { closeModal } from "../../utils/modalsUtils";
import { handleError } from "../../utils/errorUtils";

export const useLogin = (authLoginContext: any, modalId: string) => {
    const initialFormData = { email: "", password: "" };
    const { formData, handleChange, resetForm: originalResetForm } = useForm(initialFormData);
    const [loading, setLoading] = useState(false);
    const [serverError, setServerError] = useState<ValidationError[]>([]);

    const resetForm = () => {
        originalResetForm();
        setServerError([]);
    };
    const handleLogin = async (event: React.FormEvent, loginData?: { email: string, password: string }) => {
        event.preventDefault();
        setServerError([]);

        const dataToUse = loginData || formData;
        if (!dataToUse) return;

        const validationErrors = validateLogin(dataToUse);
        if (validationErrors.length > 0) {
            setServerError(validationErrors);
            return;
        }

        try {
            setLoading(true);
            const response = await apiRepository.authUser(dataToUse);
            const { success, data } = response.data;

            if (success) {
                closeModal(modalId);
                authLoginContext({
                    token: data.authCode,
                    username: data.name,
                    userId: data.id,
                    role: data.profile,
                });
            }
        } catch (error: any) {
            handleError(error, setServerError);
        } finally {
            setLoading(false);
        }
    };


    return { formData, handleChange, resetForm, handleLogin, loading, serverError };
};