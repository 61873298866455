import React, { useContext } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AuthContext } from '../../../../core/context/AuthContext';
import { CartContext } from '../../../../core/context/CartContext';
import { usePackages } from "../../../../core/hooks/getData/usePackages";
import PricingPlans from "../../common/pricingPlans/pricingPlans";
import PackageSlider from "../../common/packageSlider/packageSlider";
import SectionHeader from "../../common/sectionHeader/sectionHeader";
import ProductCarousel from "../../common/productCarousel/productCarousel";

const PreferredSection = () => {
  const authContext = useContext(AuthContext);
  const cartContext = useContext(CartContext);
  const { sortedCategories = [], productIds, packages } = usePackages(true);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }
  if (!cartContext) {
    return <div>Error: Cart context not found!</div>;
  }

  return (
    <section className="section">
      <div className="container col-11">
        <div className="row justify-content-center">
          {packages?.map((group, index) => {
            const [groupKey, groupPackages] = Object.entries(group)[0];
            if (!Array.isArray(groupPackages) || groupPackages.length === 0) {
              return null;
            }

            if (productIds?.includes(Number(groupKey))) {
              return (
                <div key={index} className="col-12 my-4">
                  <SectionHeader
                    title={sortedCategories[index]?.title || `Paquetes tipo ${groupKey}`}
                    subtitle={sortedCategories[index]?.subtitle || ""}
                  />
                  <ProductCarousel
                    products={groupPackages}
                    cartContext={cartContext}
                    authContext={authContext}
                  />
                </div>
              );
            }

            return (
              <div key={index} className="col-12 my-4">
                <SectionHeader
                  title={sortedCategories[index]?.title || `Paquetes tipo ${groupKey}`}
                  subtitle={sortedCategories[index]?.subtitle || ""}
                />
                {groupPackages.length === 3 ? (
                  <PricingPlans
                    packages={groupPackages}
                    cartContext={cartContext}
                    authContext={authContext}
                  />
                ) : (
                  <div className="home-package">
                    <PackageSlider
                      packages={groupPackages}
                      cartContext={cartContext}
                      authContext={authContext}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default PreferredSection;