import { PromotionSection } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect, useCallback } from "react";
import { handleChange } from "../../utils/utils";

export const usePromotionSection = () => {
  const [promotionSectionData, setPromotionSectionData] = useState<PromotionSection>([]);
  const [isLoading, setIsLoading] = useState<boolean>();

  const fetchPromotionSection = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiRepository.getPromotionSection();
      const rawData = response.data.data;
      setPromotionSectionData(rawData);
    } catch (error) {
      console.error("Error al obtener los datos de la sección de promoción:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchPromotionSection();
  }, [fetchPromotionSection]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    handleChange(e, setPromotionSectionData);
  };

  return {
    promotionSectionData,
    isLoading,
    setPromotionSectionData,
    refetch: fetchPromotionSection,
    handleInputChange,
  };
};