import { PackageTypes } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect } from "react";

export const useCategoriesList = (order: boolean) => {
    const [sortedCategories, setSortedCategories] = useState<PackageTypes[]>([]);
    const [productIds, setProductIds] = useState<number[]>([]);

    useEffect(() => {
        const fetchPackagesCategories = async () => {
            try {
                const response = await apiRepository.getPackageTypes();
                const filteredCategories = response.data.data.filter(
                    (item) => item.status === true
                );

                const categoriesToSet = order
                    ? filteredCategories.sort((a, b) => a.position - b.position)
                    : filteredCategories;

                setSortedCategories(categoriesToSet);

                const productIds = categoriesToSet
                    .filter((item) => item.type === 2 || item.type === 3)
                    .map((item) => item.id);
                setProductIds(productIds);

            } catch (error) {
                console.error("Error fetching packages:", error);
                setSortedCategories([]);
            }
        };

        fetchPackagesCategories();
    }, [order]);

    return { sortedCategories, productIds };
};