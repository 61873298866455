import { useState } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { ValidationError } from '../../form/validationForm';

export const useHandleStatusUpdate = ({ scheduleId, schedules, setLoading, setErrors }) => {
    return async (message = true) => {
        try {
            setLoading(true);
            setErrors([]);
            await apiRepository.updateSchedule(scheduleId, schedules);
            if (message) {
                const validationErrors: ValidationError[] = [];
                validationErrors.push({ field: 'succesStatus', message: '¡Estado actualizado con exito!' });
                setErrors(validationErrors);
            }
        } catch (error: any) {
            const validationErrors: ValidationError[] = [];
            console.error('Error al actualizar el estado:', error);
            if (error.response && error.response.data && error.response.data.message) {
                const cleanedMessage = error.response.data.message.replace(/^Error interno del servidor: /, '');
                validationErrors.push({ field: 'status', message: cleanedMessage });
            } else {
                validationErrors.push({
                    field: 'status',
                    message: 'Ocurrió un error al intentar iniciar sesión. Inténtalo de nuevo.',
                });
            }
            setErrors(validationErrors);
        } finally {
            setLoading(false);
        }
    };
};

export const useHandleSubmit = ({ scheduleId, classes, schedules, setErrors, setLoading, handleStatusUpdate }) => {
    return async () => {
        setErrors([]);
        const isValidStatus = schedules.status === 2 || schedules.status === 3;
        if (!isValidStatus) {
            setErrors([{ field: 'assist', message: 'Para confirmar la asistencia, el estado debe ser "Atención" o "Finalizada".' }]);
            return;
        }

        const attendedCustomers = classes
            .filter(classItem => classItem.attended)
            .map(classItem => classItem.customerId);

        if (attendedCustomers.length === 0) {
            setErrors([{ field: 'assist', message: 'Debe seleccionar al menos un usuario como asistido.' }]);
            return;
        }

        const payload = {
            scheduleId,
            customerIds: attendedCustomers
        };

        try {
            setLoading(true);
            await handleStatusUpdate(false);
            await apiRepository.assistanceReservation(payload);
            const validationErrors: ValidationError[] = [];
            validationErrors.push({ field: 'assistSuccess', message: '¡Asistencia enviada con exito!' });
            setErrors(validationErrors);
        } catch (error) {
            console.error('Error al enviar los datos:', error);
        } finally {
            setLoading(false);
        }
    };
};
