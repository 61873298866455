import React, { useState, useEffect, useCallback } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Users } from '../../../../core/Interface/repositoryInterfaces';
import { validateUser, ValidationError } from '../../../../core/form/validationForm';
import { InputField, CalendarField, DropdownField, SwitchField, ImageUploadField } from '../input/inputField';
import { handleChange as handleChangeInput, dateStandard, formatDatetoString } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { getLocationCode, handleFormSubmit } from '../../../../core/services/formService';
import { TYPE_ROLES_USERS, TYPE_IDENTIFIER, TYPE_ACTIONS_USERS } from '../../../../core/data/options/options';
import BaseModal from './baseModal';
import { useUbigeo } from '../../../../core/hooks/getData/useUbigeo';
import { UpdateUserModalProps } from '../../../../core/Interface/modalInterface';

const UsersModal: React.FC<UpdateUserModalProps> = ({ selectedUser, onUpdateSuccess }) => {
  const [userData, setUserData] = useState<Users>({} as Users);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, type, checked, value } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setUserData(prev => ({
      ...prev,
      [name]: fieldValue,
    }));
  }, []);

  const { provinces, districts, formattedUbigeoData } = useUbigeo({ formData: userData, handleChange });

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    handleChangeInput(e, setUserData);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
    setErrors([]);
    setUserData({} as Users);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (selectedUser) {
      let department = '', province = '', district = '';
      if (selectedUser.locationName) {
        try {
          const decodedLocationName = decodeURIComponent(escape(selectedUser.locationName));
          [department, province, district] = decodedLocationName.split(' - ').map(part => part.toUpperCase());
        } catch (e) {
          [department, province, district] = selectedUser.locationName.split(' - ').map(part => part.toUpperCase());
        }
      }

      setUserData({
        id: selectedUser.id,
        name: selectedUser.name,
        lastname: selectedUser.lastname,
        typeDocument: selectedUser.typeDocument || 'DNI',
        numberDocument: selectedUser.numberDocument,
        phone: selectedUser.phone,
        conditionMedical: selectedUser.conditionMedical,
        email: selectedUser.email,
        address: selectedUser.address,
        locationId: selectedUser.locationId,
        department: department,
        province: province,
        district: district,
        isAccepted: true,
        password: '',
        profile: selectedUser.profile || TYPE_ROLES_USERS[0].value,
        active: selectedUser.active ?? true,
        image: selectedUser.image || null,
        classFree: selectedUser.classFree || false,
        birthdate: selectedUser.birthdate && !isNaN(new Date(selectedUser.birthdate).getTime())
          ? dateStandard(selectedUser.birthdate)
          : dateStandard(formatDatetoString(new Date())),
        activationDateTime: selectedUser.activationDateTime || '',
        activationUser: selectedUser.activationUser || '',
      });
      setShow(true);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (userData.province && userData.district) {
      setUserData(prevData => ({
        ...prevData,
        province: userData.province,
        district: userData.district,
      }));
    }
  }, [userData.province, userData.district]);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setUserData(prevData => ({ ...prevData, image: file }));
  }, []);

  const handleRemoveImage = useCallback(() => {
    setUserData(prevData => ({ ...prevData, image: null }));
    const fileInput = document.getElementById('imgInp') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }, []);

  const createOrUpdateUser = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    const locationId = await getLocationCode(userData.district, apiRepository.getLocations);
    const errors = await handleFormSubmit(
      userData.id || -1,
      userData,
      validateUser,
      {
        create: apiRepository.createUser,
        update: apiRepository.updateUser,
      },
      () => {
        if (onUpdateSuccess) onUpdateSuccess();
        handleClose();
      },
      data => ({
        ...data,
        birthdate: formatDatetoString(data.birthdate),
        locationId: locationId,
      }),
      'Ocurrió un error al intentar guardar el usuario. Inténtalo de nuevo.',
      true
    );

    if (errors) {
      setErrors(errors);
    }

    setLoading(false);
  };

  return (
    <BaseModal
      show={show}
      title={userData.id ? 'Editar Usuario' : 'Agregar Usuario'}
      onClose={handleClose}
      onConfirm={createOrUpdateUser}
      loading={loading}
    >
      <form onSubmit={createOrUpdateUser}>
        <ImageUploadField
          formData={userData}
          onFileChange={handleFileChange}
          onRemoveImage={handleRemoveImage}
          routeName="user"
        />
        <div className="row">
          <div className="col-md-4">
            <InputField
              label="Nombre"
              name="name"
              value={userData?.name || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'name')}
            />
          </div>
          <div className="col-md-4">
            <InputField
              label="Apellido"
              name="lastname"
              value={userData?.lastname || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'lastname')}
            />
          </div>
          <div className="col-md-4">
            <InputField
              label="Correo"
              name="email"
              value={userData?.email || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'email')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <InputField
              label="Telefono"
              name="phone"
              value={userData?.phone || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'phone')}
            />
          </div>
          <div className="col">
            <CalendarField
              label="Fecha de nacimiento"
              name="birthdate"
              value={userData?.birthdate || ''}
              onChange={e => setUserData(prevFormData => ({
                ...prevFormData,
                birthdate: e.value as Date,
              }))}
              error={getErrorMessage(errors, 'birthdate')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="row">
              <div className="col-4 pe-0">
                <DropdownField
                  label="Tipo"
                  name="typeDocument"
                  options={TYPE_IDENTIFIER}
                  value={userData?.typeDocument}
                  onChange={handleInputChange}
                />
              </div>
              <div className="col">
                <InputField
                  label="Numero de Identidad"
                  name="numberDocument"
                  placeholder="82143841"
                  value={userData?.numberDocument || ''}
                  onChange={handleInputChange}
                  error={getErrorMessage(errors, 'numberDocument')}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <DropdownField
              label="Rol"
              name="profile"
              value={userData?.profile || ''}
              onChange={handleInputChange}
              options={TYPE_ROLES_USERS}
              error={getErrorMessage(errors, 'profile')}
              disabled={selectedUser?.profile === 'trainer' && userData.profile === 'trainer'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <InputField
              label="Dirección"
              name="address"
              value={userData?.address || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'address')}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <DropdownField
              label="Departamento"
              name="department"
              placeholder="Seleccione un departamento"
              onChange={e => handleChange({
                target: {
                  name: 'department',
                  value: e.value,
                },
              } as React.ChangeEvent<HTMLInputElement>)}
              options={formattedUbigeoData}
              value={userData?.department}
              error={getErrorMessage(errors, 'department')}
            />
          </div>
          <div className="col-md-4">
            <DropdownField
              label="Provincia"
              name="province"
              placeholder="Seleccione una provincia"
              onChange={e => handleChange({
                target: {
                  name: 'province',
                  value: e.value,
                },
              } as React.ChangeEvent<HTMLInputElement>)}
              options={provinces || []}
              value={userData?.province}
              disabled={!provinces}
              error={getErrorMessage(errors, 'province')}
            />
          </div>
          <div className="col-md-4">
            <DropdownField
              label="Distrito"
              name="district"
              placeholder="Seleccione un distrito"
              onChange={e => handleChange({
                target: {
                  name: 'district',
                  value: e.value,
                },
              } as React.ChangeEvent<HTMLInputElement>)}
              options={districts || []}
              value={userData?.district}
              disabled={!userData.province}
              error={getErrorMessage(errors, 'district')}
            />
          </div>
        </div>
        {userData?.profile === TYPE_ROLES_USERS[0].value && (
          <>
            <InputField
              label="Condición Medica / Embarazo"
              required={false}
              name="conditionMedical"
              value={userData?.conditionMedical || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'conditionMedical')}
            />
            <DropdownField
              label="Prueba gratuita"
              name="classFree"
              value={userData?.classFree}
              onChange={handleInputChange}
              options={TYPE_ACTIONS_USERS}
              error={getErrorMessage(errors, 'classFree')}
              disabled={userData.id}
            />
            {userData.activationDateTime && userData.activationUser && (
              <div className="row">
                <div className="col-md-6">
                  <InputField
                    label="Fecha de Activación"
                    name="activationDateTime"
                    value={userData?.activationDateTime || ''}
                    required={false}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    label="Usuario de Activación"
                    name="activationUser"
                    value={userData?.activationUser || ''}
                    required={false}
                    disabled
                  />
                </div>
              </div>
            )}
          </>
        )}
        <div className="row">
          <div className="col-md-6">
            <InputField
              label="Contraseña"
              name="password"
              value={userData?.password || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'password')}
            />
          </div>
          <div className="col-md-6">
            <InputField
              label="Confirmar Contraseña"
              name="newPassword"
              value={userData?.newPassword || ''}
              onChange={handleInputChange}
              error={getErrorMessage(errors, 'newPassword')}
            />
          </div>
        </div>

        <SwitchField
          label="Estado"
          checked={userData.active || false}
          onChange={handleInputChange}
          name="active"
          disabled={!userData.id}
        />
        {errors?.find(error => error.field === 'server') && (
          <div className="alert alert-danger" role="alert">
            {getErrorMessage(errors, 'server')}
          </div>
        )}
      </form>
    </BaseModal>
  );
};

export default UsersModal;