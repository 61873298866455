import { Users } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect } from "react";
import { UseCustomerUsersOptions } from "../../Interface/hooksInterface";

export const useCustomerUsers = ({ shouldOptions = false }: UseCustomerUsersOptions) => {
    const [customerUsersData, setCustomerUsersData] = useState(shouldOptions ? [] as { label: string; value: any }[] : [] as Users[]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchCustomerUsers = async () => {
        try {
            const response = await apiRepository.getUsers(0, 1000, { fullName:'', profile: 'customer', email:'' });
            const rawData = response.data.data.content;

            if (shouldOptions) {
                const optionsData = rawData.map((item: any) => ({
                    label: item.name + ' ' + item.lastname,
                    value: item.id,
                }));
                setCustomerUsersData(optionsData);
            } else {
                setCustomerUsersData(rawData);
            }
        } catch (error) {
            console.error("Error al obtener los datos de las clases:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchCustomerUsers();
    }, [shouldOptions]);

    return { customerUsersData, isLoading, fetchCustomerUsers: fetchCustomerUsers };
};