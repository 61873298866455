import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect, useCallback } from "react";
import { handleChange } from "../../utils/utils";
import { Complaint } from "../../Interface/repositoryInterfaces";

export const useComplaint = () => {
  const [complaintData, setComplaintData] = useState<Complaint[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchComplaint = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiRepository.getComplaints();
      const rawData = response.data.data;
      setComplaintData(rawData);
    } catch (error) {
      console.error("Error al obtener los datos de la sección de Reclamos:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchComplaint();
  }, [fetchComplaint]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    handleChange(e, setComplaintData);
  };

  return {
    complaintData,
    isLoading,
    setComplaintData,
    refetch: fetchComplaint,
    handleInputChange,
  };
};