import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Coupon, PackageTypes } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import CouponsModal from '../common/modals/coupons-modal';
import DeleteCouponsModal from '../common/modals/delete-coupons-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsCoupons';

const Coupons = () => {
    const [data, setData] = useState<Coupon[]>([]);
    const [categories, setCategories] = useState<PackageTypes[]>([]);
    const [selectedCouponId, setSelectedCouponId] = useState<any>(null);
    const [selectedCoupon, setSelectedCoupon] = useState<any>(null);
    const [totalCoupons, setTotalCoupons] = useState<number>(-1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const fetchCoupon = async (pageNumber = currentPage, pageSize = rows) => {
        try {
            setLoading(true);
            const response = await apiRepository.getCoupons(pageNumber, pageSize);
            const totalRecords = response.data.data.totalElements;
            setData(response.data.data.content);
            setTotalCoupons(totalRecords);
        } catch (error) {
            console.error("Error al obtener los datos de cupones:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const response = await apiRepository.getPackage(0, 1000);
            const activeCategories = response.data.data.content.filter((category: any) => category.status === true);
            const formattedCategories = activeCategories.map((category: any) => ({
                label: category.title,
                value: category.id,
                type: category.type,
            }));
            setCategories(formattedCategories);
        } catch (error) {
            console.error("Error al obtener los datos de categorías:", error);
        }
    };

    useEffect(() => {
        fetchCoupon();
        fetchCategories();
    }, []);

    const handleEditClick = (rowData: any) => {
        setSelectedCoupon(null);
        setTimeout(() => setSelectedCoupon(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedCouponId(null);
        setTimeout(() => setSelectedCouponId(rowData.id), 0);
    };

    const columns = [
        { field: "name", header: "Nombre" },
        { field: "code", header: "Código" },
        { field: "typeDiscount", header: "Tipo de Descuento", body: (rowData) => rowData.typeDiscount ? "Porcentaje %" : "Monto fijo S/." },
        { field: "amount", header: "Monto" },
        { field: "packetName", header: "Paquete" },
        { field: "dateExpired", header: "Fecha Válida", body: (rowData) => new Date(rowData.dateExpired).toLocaleDateString('es-PE') },
        { field: "status", header: "Estado", body:  (rowData) => (
            <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
              {rowData.status ? 'Activo' : 'Inactivo'}
            </span>
        )},
    ];

    const getColumns = useResponsiveColumns(columns);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        const pageNumber = event.first / event.rows
        setCurrentPage(pageNumber);
        fetchCoupon(pageNumber, event.rows);
    };

    return (
        <div className="page-wrapper page-settings">
            <div className="content mb-4">
                <div className="content-page-header content-page-headersplit">
                    <h5>Cupones</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar Cupón
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    loading={loading}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalCoupons}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron cupones."
                />
            </div>
            <CouponsModal selectedCoupon={selectedCoupon} onUpdateSuccess={fetchCoupon} categoriesPackage={categories} />
            <DeleteCouponsModal selectedCouponId={selectedCouponId} onDeleteSuccess={fetchCoupon} />
        </div>
    );
};

export default Coupons;