import React from 'react';
import * as Icon from 'react-feather';
import BaseModal from './baseModal';

interface SuccessEmailModalProps {
  show: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const SuccessEmailModal: React.FC<SuccessEmailModalProps> = ({ show, onClose, onConfirm }) => {
  return (
    <BaseModal
      show={show}
      title="Envío Exitoso"
      onClose={onClose}
      onConfirm={onConfirm}
      showButtons={false}
      size="md"
    >
      <div className="text-center pt-1">
        <Icon.CheckCircle size={95} color='#28a745' className="text-success fs-1" />
        <p className="mt-3">Los correos electrónicos se han enviado con éxito.</p>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <button
          type="button"
          className="btn btn-primary-admin"
          onClick={onConfirm}
        >
          Ir a la lista de emails
        </button>
      </div>
    </BaseModal>
  );
};

export default SuccessEmailModal;
