export const extractUsedCoupons = (cart) => {
    return cart
        .filter(item => item.couponCode)
        .map(item => item.couponCode);
};

export const isUserLoggedIn = (user, informationSucces) => {
    if (!user?.userId) {
        informationSucces.push({
            field: 'error',
            message: 'Debe iniciar sesión para continuar.'
        });
        return false;
    }
    return true;
};

export const validateCartCombinations = (cart, informationSucces) => {
    const subTypesInCart = cart.map(item => item.subType);

    const invalidCombinations = [
        {
            condition: subTypesInCart.includes("Membresias") && subTypesInCart.includes("Clases"),
            message: 'No se puede combinar Membresias con Clases.'
        },
        {
            condition: subTypesInCart.includes("Clases Reformer") && subTypesInCart.includes("Membresias Premium"),
            message: 'No se puede combinar Clases Reformer con Membresias Premium.'
        },
        {
            condition: subTypesInCart.includes("Membresias") && subTypesInCart.includes("Membresias Premium"),
            message: 'No se puede combinar Membresias con Membresias Premium.'
        }
    ];

    invalidCombinations.forEach(({ condition, message }) => {
        if (condition) {
            informationSucces.push({ field: 'error', message });
        }
    });
};

export const buildItems = (cart: any) => {
    return cart.items.map((product: any, index: number) => {
        const isFirstItem = index === 0;
        const hasDiscount = cart.discount_general_value + product.discount_value > 0;
        if (hasDiscount) {
            const discount = isFirstItem ? cart.discount_general_value : 0;
            const unitPrice = product.subtotal - discount;

            return {
                title: product.title,
                id: product.id,
                subtitle: product.subtitle,
                quantity: 1,
                currency_id: 'PEN',
                unit_price: unitPrice,
                amount: unitPrice,
            };
        } else {
            return {
                title: product.title,
                id: product.id,
                subtitle: product.subtitle,
                quantity: product.quantity,
                currency_id: 'PEN',
                unit_price: product.price,
                amount: product.price,
            };
        }
    });
};