import { useState } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetClassFreePurchaseExelFile = (dateRange?: [Date, Date]) => {
    const [classFreePurchaseExelFile, setClassFreePurchaseExelFile] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchClassFreePurchase = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getClassFreePurchaseReportExcelFile(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate)
            );
            const classFreePurchaseExelFile = response.data;
            setClassFreePurchaseExelFile(classFreePurchaseExelFile);
            return classFreePurchaseExelFile;
        } catch (err) {
            console.error("Error al obtener los datos de compras:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        classFreePurchaseExelFile,
        isLoading,
        error,
        refetchClassFreePurchase: fetchClassFreePurchase,
    };
};
