import React, { useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import TimePicker from 'react-time-picker';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { AutoComplete } from 'primereact/autocomplete';
import { MultiSelect } from 'primereact/multiselect';

addLocale('es', {
    firstDayOfWeek: 1,
    dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
    dayNamesShort: ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb'],
    dayNamesMin: ['Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa'],
    monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
    monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
    today: 'Hoy',
    clear: 'Limpiar',
    dateFormat: 'yy/mm/dd',
});

type FieldProps = {
    label?: string;
    error?: string | null;
    defaultValue?: string;
    required?: boolean;
};

type InputFieldProps = FieldProps & {
    value: string;
    type?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
};

type CalendarFieldProps = FieldProps & {
    value: Date | [Date, Date];
    dateFormat?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
    disabled?: boolean;
};

type DropdownFieldProps = FieldProps & {
    value: any;
    options: { label: string; value: any }[];
    onChange?: (event: { value: any }) => void;
    optionLabel?: string;
    name?: string;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
};

type TimePickerFieldProps = {
    label?: string;
    value: string | undefined;
    onChange?: (value: moment.Moment | null) => void;
    error?: string | null;
    name?: string;
    format?: string;
    required?: boolean
};

type SwitchFieldProps = {
    label: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    name?: string;
    error?: string;
    className?: string;
    defaultChecked?: boolean;
    disabled?: boolean;
};

type ImageUploadFieldProps = {
    formData: Record<string, any>;
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveImage: () => void;
    routeName?: string;
    imageKey?: string;
    label?: string;
    recommendedSize?: string;
    required?: boolean;
    error?: string | null;
};

type AutoCompleteDropdownProps = {
    label?: string;
    value: string | null;
    suggestions: { label: string; value: string }[];
    onChange: (value: string | null) => void;
    onSearch: (event: string) => void;
    placeholder?: string;
    error?: string | null;
    name?: string;
    disabled?: boolean;
    required?: boolean;
};

type BooleanFieldProps = FieldProps & {
    value: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void | null;
    name?: string;
    disabled?: boolean;
};

export const InputField: React.FC<InputFieldProps> = ({
    label,
    value,
    onChange,
    error,
    name,
    defaultValue,
    placeholder,
    disabled = false,
    type = 'text',
    required = true
}) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
        setIsPasswordVisible(prevState => !prevState);
    };

    return (
        <div className="mb-3">
            {label && (
                <label className="form-label w-100">
                    <span>{label}{required && <span className="text-danger"> *</span>}</span>
                </label>
            )}
            {type === "password" ? (
                <div className="pass-group">
                    <input
                        type={isPasswordVisible ? 'text' : 'password'}
                        className="form-control pass-input"
                        value={value}
                        name={name}
                        defaultValue={defaultValue}
                        onChange={onChange}
                        autoComplete="off"
                        disabled={disabled}
                    />
                    <span
                        className={`fas toggle-password ${isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}
                        onClick={togglePasswordVisibility}
                    />
                </div>
            ) : (
                <input
                    type={type}
                    className="form-control"
                    value={value}
                    name={name}
                    onChange={onChange}
                    autoComplete={name}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            )}
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

interface InputWithDropdownProps {
  label: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onDropdownChange: React.ChangeEventHandler<HTMLSelectElement>;
  error?: string;
  name: string;
  dropdownValue: string;
  dropdownOptions: { label: string; value: string }[];
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  className?: string;
}

export const InputWithDropdown: React.FC<InputWithDropdownProps> = ({
  label,
  value,
  onChange,
  onDropdownChange,
  error,
  name,
  dropdownValue,
  dropdownOptions,
  disabled = false,
  placeholder,
  required = true,
  className = "mb-3"
}) => {
  return (
    <div className={className}>
      {label && (
        <label className="form-label w-100">
          <span>
            {label}
            {required && <span className="text-danger"> *</span>}
          </span>
        </label>
      )}
      <div className="d-flex">
        <Dropdown
          value={dropdownValue}
          onChange={onDropdownChange}
          className="select w-30 me-2"
          disabled={disabled}
        options={dropdownOptions}
        >
        </Dropdown>
        <input
          type="text"
          className="form-control"
          value={value}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {error && <small className="text-danger">{error}</small>}
    </div>
  );
};

interface MultiSelectFieldProps {
    label?: string;
    value: any[];
    options: { label: string; value: any }[];
    onChange: (value: any[]) => void;
    error?: string;
    name?: string;
    disabled?: boolean;
    placeholder?: string;
    required?: boolean;
    className?: string;
}

export const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
    label,
    value,
    options,
    onChange,
    error,
    name,
    disabled = false,
    placeholder = 'Selecciona...',
    required = true,
    className = 'mb-3'
}) => {
    return (
        <div className={className}>
            {label && (
                <label className="form-label w-100">
                    <span>{label}{required && <span className="text-danger"> *</span>}</span>
                </label>
            )}
            <MultiSelect
                name={name}
                value={value}
                options={options}
                onChange={(e) => onChange(e.value)}
                className="select w-100"
                disabled={disabled}
                placeholder={placeholder}
                optionLabel="label"
                display="chip"
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const CalendarRangeField: React.FC<CalendarFieldProps> = ({ label, value, onChange, error, dateFormat = "yy/mm/dd" }) => {
    return (
        <div className="mb-3">
            {label &&
                <label className="form-label w-100">
                    <span>{label}</span>
                </label>
            }
            <Calendar
                value={value}
                onChange={onChange}
                dateFormat={dateFormat}
                showIcon
                selectionMode="range"
                readOnlyInput
                locale="es"
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const CalendarField: React.FC<CalendarFieldProps> = ({ label, value, onChange, error, required = true, dateFormat = "yy/mm/dd", disabled = false }) => {
    return (
        <div className="mb-3">
            {label && (
                <label className="form-label w-100">
                    <span>{label}{required && <span className="text-danger"> *</span>}</span>
                </label>
            )}
            <Calendar
                value={value}
                onChange={onChange}
                dateFormat={dateFormat}
                showIcon
                locale="es"
                disabled={disabled}
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const DropdownField: React.FC<DropdownFieldProps> = ({ label, value, options, onChange, error, name, disabled, placeholder, required = true, optionLabel = "label", className = "mb-3" }) => {
    return (
        <div className={className}>
            {label && (
                <label className="form-label w-100">
                    <span>{label}{required && <span className="text-danger"> *</span>}</span>
                </label>
            )}
            <Dropdown
                name={name}
                value={value}
                onChange={onChange}
                options={options}
                optionLabel={optionLabel}
                className="select w-100"
                disabled={disabled}
                placeholder={placeholder}
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const AutoCompleteDropdown: React.FC<AutoCompleteDropdownProps> = ({
    label,
    value,
    suggestions,
    onChange,
    onSearch,
    placeholder = 'Selecciona o escribe...',
    error,
    name,
    required = true,
    disabled = false
}) => {
    const filteredSuggestions = suggestions.map((option) => option.label);

    return (
        <div className="mb-3">
            {label && (
                <label className="form-label w-100">
                    <span>{label}{required && <span className="text-danger"> *</span>}</span>
                </label>
            )}
            <AutoComplete
                value={value}
                name={name}
                suggestions={filteredSuggestions}
                completeMethod={(e) => onSearch(e.query)}
                onChange={(e) => onChange(e.value)}
                placeholder={placeholder}
                dropdown
                appendTo="self"
                className="h-input w-100"
                disabled={disabled}
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const TimePickerField: React.FC<TimePickerFieldProps> = ({ label, value, onChange, error, required = true, format = "h:mm A" }) => {
    return (
        <div className="mb-3">
            {label && (
                <label className="form-label w-100">
                    <span>{label}{required && <span className="text-danger"> *</span>}</span>
                </label>
            )}
            <TimePicker
                className="timepicker input-group-text form-control custom-timepicker"
                name={name}
                onChange={onChange}
                bordered={false}
                value={value}
                format={format}
                disableClock={true}
                clearable={true}
            />
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const SwitchField: React.FC<SwitchFieldProps> = ({ label, checked, onChange, name, error, defaultChecked, disabled = false, className = "mb-4" }) => {
    return (
        <div className={`form-groupheads d-flex justify-content-between d-flex align-items-center ${className}`}>
            <h2>{label}</h2>
            <div className="active-switch">
                <label className="switch">
                    <input
                        className="switch"
                        type="checkbox"
                        checked={checked}
                        name={name}
                        defaultChecked={defaultChecked}
                        onChange={onChange}
                        autoComplete="off"
                        disabled={disabled}
                    />
                    <span className="sliders round" />
                </label>
            </div>
            {error && <small className="text-danger">{error}</small>}
        </div>
    );
};

export const ImageUploadField: React.FC<ImageUploadFieldProps> = ({
    formData,
    onFileChange,
    onRemoveImage,
    routeName,
    error,
    imageKey = 'image',
    label = 'Imagen',
    required= false,
    recommendedSize = '320 x 320 píxeles',
}) => {
    const imageData = formData[imageKey];

    return (
        <div className="profile-upload">
            {imageData && (
                <div className="profile-upload-img">
                    {imageData instanceof File ? (
                        <img
                            className="img-fluid shadow rounded"
                            src={URL.createObjectURL(imageData)}
                            style={{ width: '120px', height: 'auto' }}
                            alt="Vista previa"
                        />
                    ) : (
                        <ImageWithBaseApi
                            loadingIcon={
                                <i className="fa fa-spinner fa-spin" style={{ fontSize: '60px', color: '#5c5c5c', margin: "20px"}}></i>
                            }
                            className="img-fluid shadow rounded"
                            src={imageData}
                            style={{ width: '120px', height: 'auto' }}
                            alt="Vista previa"
                            routeName={routeName}
                        />
                    )}
                </div>
            )}
            <div className="profile-upload-content">
                <div className="form-groupheads d-flex justify-content-between mb-1 d-flex align-items-center">
                    <h2 className="form-label">{label}{required && <span className="text-danger"> *</span>}</h2>  <br />
                </div>
                <div className="profile-upload-btn">
                    <div className="profile-upload-file btn btn-upload">
                        <input
                            type="file"
                            accept="image/*"
                            onChange={onFileChange}
                            id="imgInp"
                            autoComplete="off"
                        />
                        <Link to="#">
                            Subir
                        </Link>
                    </div>
                    <Link to="#" onClick={onRemoveImage} className="btn btn-remove">
                        Eliminar
                    </Link>
                </div>
                {error && <small className="text-danger">{error}</small>}
                <div className="profile-upload-para">
                    <p>* Recomendamos un tamaño mínimo de {recommendedSize}.</p>
                </div>
            </div>
        </div>
    );
};

export const BooleanField: React.FC<BooleanFieldProps> = ({
    label,
    value,
    onChange,
    error,
    name,
    disabled = false,
    required = true
}) => {
    return (
        <div className="mb-3">
            {label && (
                <label className="form-label w-100">
                    <span>{label}{required && <span className="text-danger"> *</span>}</span>
                </label>
            )}
            <div className="d-flex align-items-center gap-3">
                <button
                    type="button"
                    className={`btn ${value ? 'btn-success' : 'btn-secondary'} px-4 py-2`}
                    onClick={() => onChange({
                        target: { name, value: !value } as unknown as React.ChangeEvent<HTMLInputElement>
                    })}
                    disabled={disabled}
                >
                    {value ? 'Activo' : 'Inactivo'}
                </button>
            </div>
            {error && <small className="text-danger d-block mt-2">{error}</small>}
        </div>
    );
};