import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetClassCapacityReport = (dateRange?: [Date, Date], classId?: string, trainerId?: string, hourStart?: string, hourEnd?: string) => {
    const [classCapacity, setClassCapacity] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchClassCapacity = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getClassCapacityReport(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
                classId,
                trainerId,
                hourStart,
                hourEnd
            );
            const classCapacity = response.data.data;
            setClassCapacity(classCapacity);
        } catch (err) {
            console.error("Error al obtener los datos de horarios:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const isHourRangeValid = (hourStart && hourEnd) || (!hourStart && !hourEnd);
        if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) && isHourRangeValid) {
            fetchClassCapacity();
        }
    }, [dateRange, classId, trainerId, hourStart, hourEnd]);

    return {
        classCapacity,
        isLoading,
        error,
        refetchClassCapacity: fetchClassCapacity,
    };
};