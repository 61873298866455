import { useState, useEffect } from "react";
import { UBIGEO_DATA } from "../../data/json/ubigeo"; // Ajusta la ruta según corresponda

interface UseUbigeoProps {
  formData: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useUbigeo = ({ formData, handleChange }: UseUbigeoProps) => {
  const [provinces, setProvinces] = useState<any[]>([]);
  const [districts, setDistricts] = useState<any[]>([]);

  useEffect(() => {
    if (formData?.department) {
      const departmentData = UBIGEO_DATA.find(
        (d) => d.department === formData.department
      );
      setProvinces(
        departmentData?.provinces.map((p) => ({ label: p.province, value: p.province })) || []
      );
      setDistricts([]);
      handleChange({
        target: {
          name: "province",
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>);
      handleChange({
        target: {
          name: "district",
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [formData?.department, handleChange]);

  useEffect(() => {
    if (formData?.province) {
      const departmentData = UBIGEO_DATA.find(
        (d) => d.department === formData.department
      );
      const provinceData = departmentData?.provinces.find(
        (p) => p.province === formData.province
      );
      setDistricts(
        provinceData?.districts.map((d) => ({ label: d, value: d })) || []
      );
      handleChange({
        target: {
          name: "district",
          value: "",
        },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, [formData?.province, handleChange]);

  const formattedUbigeoData = UBIGEO_DATA.map((d) => ({ label: d.department, value: d.department }));

  return { provinces, districts, formattedUbigeoData };
};