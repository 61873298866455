import { formatDateToDayMonth } from '../utils/dateUtils';
import { formatTo12Hour } from '../utils/utils';
import moment from 'moment';

export const sortClassesByTime = (a: any, b: any) => {
    const dateA = moment.tz(`${a.date}T${a.hourStart}`, 'America/Lima');
    const dateB = moment.tz(`${b.date}T${b.hourStart}`, 'America/Lima');
    return dateA.diff(dateB);
};

export const formatClass = (cls: any) => {
    return {
        ...cls,
        dateFormatted: formatDateToDayMonth(cls.date),
        hourStartFormatted: formatTo12Hour(cls.hourStart),
        hourEndFormatted: formatTo12Hour(cls.hourEnd),
    };
};

export const isFutureClass = (cls: any) => {
    const classDateTime = moment.tz(`${cls.date}T${cls.hourStart}`, 'America/Lima');
    const now = moment.tz('America/Lima');
    return classDateTime.isAfter(now) || classDateTime.isSame(now, 'minute');
};