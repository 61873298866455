import React, { useContext } from "react";
import "../../../../style/css/floatingWhatsAppButton.css";
import { AuthContext } from '../../../../core/context/AuthContext';
import { FloatingWhatsAppButtonProps } from "../../../../core/Interface/modalInterface";

const FloatingWhatsAppButton = ({ numberWhatsApp }: FloatingWhatsAppButtonProps) => {
    const authContext = useContext(AuthContext);
    if (!authContext) {
        return <div>Error: Auth context not found!</div>;
    }
    const { user } = authContext;

    if (user?.role === 'trainer') {
        return null;
    }

    const handleClick = () => {
        const message = "Hola, quisiera más información acerca de sus servicios.";
        const url = `https://wa.me/${numberWhatsApp}?text=${encodeURIComponent(message)}`;
        window.open(url, "_blank");
    };

    return (
        <button
            className="floating-whatsapp-button"
            onClick={handleClick}
            aria-label="Chat with us on WhatsApp"
        >
            <i className="fa-brands fa-whatsapp" style={{ "fontSize": "32px", "marginBottom": "4px" }}></i>
        </button>
    );
};

export default FloatingWhatsAppButton;