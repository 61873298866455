export const testimonialsData = [
    {
        id: 1,
        img: "assets/admin/img/customer/user-03.jpg",
        userName: "John Smith\n                    johnsmith@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "28 Sep 2023 16:43PM",
        status: "Active",
        action: ""
    },
    {
        id: 2,
        img: "assets/admin/img/customer/user-03.jpg",
        userName: "John Smith\n                    johnsmith@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "20 Sep 2023 16:43PM",
        status: "Inactive",
        action: ""
    },
    {
        id: 3,
        img: "assets/admin/img/customer/user-04.jpg",

        userName: "Robert\n                    robert@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "20 Sep 2023 16:43PM",
        status: "Active",
        action: ""
    },
    {
        id: 4,
        img: "assets/admin/img/customer/user-02.jpg",

        userName: "Sharonda\n                    sharonda@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "10 Sep 2023 16:43PM",
        status: "Inactive",
        action: ""
    },
    {
        id: 5,
        img: "assets/admin/img/customer/user-05.jpg",

        userName: "Robert\n                    robert@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "20 Sep 2023 16:43PM",
        status: "Active",
        action: ""
    },
    {
        id: 6,
        img: "assets/admin/img/customer/user-06.jpg",

        userName: "Nicholas\n                    nicholas@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "15 Sep 2023 16:43PM",
        status: "Inactive",
        action: ""
    },
    {
        id: 7,
        img: "assets/admin/img/customer/user-07.jpg",

        userName: "John Smith\n                    johnsmith@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "20 Sep 2023 16:43PM",
        status: "Inactive",
        action: ""
    },
    {
        id: 8,
        img: "assets/admin/img/customer/user-06.jpg",

        userName: "Robert\n                    robert@example.com",
        rating: "",
        content: "Lorem ipsum dolor sit",
        createAt: "20 Sep 2023 16:43PM",
        status: "Active",
        action: ""
    }
]
