import React, { useState } from 'react';
import { img_api } from '../../environment';

interface Image {
  className?: string;
  src: any;
  alt?: string;
  routeName?: string;
  height?: number;
  width?: number;
  id?: string;
  style?: any;
  isExternalLink?: boolean;
  loadingIcon?: JSX.Element;
  ladingClass?: string;
}

const defaultLoadingIcon = <i className="fa fa-spinner fa-spin" style={{ fontSize: '60px', color: '#5c5c5c', margin: "20px"}}></i>;

const ImageWithBaseApi = (props: Image) => {
  const [loading, setLoading] = useState(true);
  const fullSrc = `${img_api}${props.routeName}/${props.src}`;

  const handleLoad = () => {
    setLoading(false);
  };

  const renderImage = (
    <img
      className={props.className}
      src={fullSrc}
      height={props.height}
      alt={props.alt}
      width={props.width}
      id={props.id}
      style={{ ...props.style, display: loading ? 'none' : undefined }} // Oculta la imagen mientras carga
      onLoad={handleLoad}
    />
  );

  if (props.isExternalLink) {
    return (
      <a href={fullSrc} target="_blank" rel="noopener noreferrer">
        {loading && (props.loadingIcon || defaultLoadingIcon)}
        {renderImage}
      </a>
    );
  }

  return (
    <>
      {loading && (
        <div className={props.ladingClass} style={{ position: 'relative' }}>
          {props.loadingIcon || defaultLoadingIcon}
        </div>
      )}
      {renderImage}
    </>
  );
};

export default ImageWithBaseApi;
