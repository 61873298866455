import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumns';
import { useGetCancellationRateReport } from '../../../core/hooks/getReports/useGetCancellationRateReport';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { CalendarRangeField } from '../common/input/inputField';
import ShowDetailCancelationRateModal from '../common/modals/show-detail-cancelation-rate-modal';
import { useGetCancellationRateReportExelFile } from '../../../core/hooks/getReports/useGetCancellationRateReportExelFile'; // Actualiza el hook si es necesario
import { downloadExcelFile } from '../../../core/utils/fileUtils';

const CancellationRate = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange());
  const [selectedClass, setSelectedClass] = useState<any>();
  const { cancellationRateReport } = useGetCancellationRateReport(dateRange);
  const { refetchCancellationRateReport } = useGetCancellationRateReportExelFile(dateRange);

  const columns = [
    { field: "className", header: "Clase" },
    { field: "trainerName", header: "Entrenador" },
    { field: "totalReservations", header: "Reservas Totales" },
    { field: "totalCancellations", header: "Cancelaciones Totales" },
    { field: "cancellationRate", header: "Tasa de Cancelación", body: (rowData: any) => `${rowData.cancellationRate.toFixed(0)}%` },
  ];

  const getColumns = useResponsiveColumns(columns);

  const handleShowInfo = (rowData: any) => {
    setSelectedClass(null);
    setTimeout(() => setSelectedClass(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchCancellationRateReport();
      downloadExcelFile(file, 'cancellation-rate-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Informe de Tasa de Cancelación</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-secondary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange());
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
                <CalendarRangeField
                  value={dateRange || [new Date(), new Date()]}
                  onChange={(e: any) => setDateRange(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={cancellationRateReport || []}
            sortField='className'
            lazy={false}
            sortOrder={-1}
            columns={getColumns()}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron clases."
          />
        </div>
        <ShowDetailCancelationRateModal selectedClassData={selectedClass} />
      </div>
    </div>
  );
};

export default CancellationRate;