export const ContactMessagesViewData = [

    {
        id: 1,
        img: "assets/img/customer/user-01.jpg",
        name: "JohnSmith",
        email: "john@example.com",
        phone: "+1347-679-8275",
        date: "28 Sep 2023",
        message: "Loremipsumdolorsitamet,consecteturadipiscing"
    },

    {
        id: 2,
        img: "assets/img/customer/user-02.jpg",
        name: "Johnny",
        email: "johnny@example.com",
        phone: "+1347-679-8275",
        date: "28 Sep 2023",
        message: "Loremipsumdolorsitamet,consecteturadipiscing"
    }

]
