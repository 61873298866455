export const verficationRequestData = [
    {
        id: 1,
        userName: "Adrrian",
        documentType: "Passport",
        documentName: "Passport.pdf",
        download: "",
        rejectReason: "Just For Another Testing  Purpose..",
        status: "Rejected"
    },
    {
        id: 2,
        userName: "Richard",
        documentType: "Passport",
        documentName: "Passport.pdf",
        download: "",
        rejectReason: "",
        status: "Verified"
    },
    {
        id: 3,
        userName: "Andrew",
        documentType: "Driving Licence",
        documentName: "DrivingLicence.pdf",
        download: "",
        rejectReason: "",
        status: "Select Status\n  Pending\n    Inprogress\n  Completed\n  cancelled"
    },
    {
        id: 4,
        userName: "Richard",
        documentType: "Passport",
        documentName: "Passport.pdf",
        download: "",
        rejectReason: "",
        status: "Select Status\n   Pending\n  Inprogress\n   Completed\n   cancelled"
    }
]
