import { Faq } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect, useCallback } from "react";
import { handleChange } from "../../utils/utils";

export const useFaq = () => {
  const [faqData, setFaqData] = useState<Faq[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchFaq = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await apiRepository.getFaqs();
      const rawData = response.data.data;
      setFaqData(rawData);
    } catch (error) {
      console.error("Error al obtener los datos de la sección de FAQ:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchFaq();
  }, [fetchFaq]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    handleChange(e, setFaqData);
  };

  return {
    faqData,
    isLoading,
    setFaqData,
    refetch: fetchFaq,
    handleInputChange,
  };
};