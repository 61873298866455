import { useState, useCallback } from 'react';

export const useForm = <T extends object>(initialState: T) => {
  const [formData, setFormData] = useState<T>(initialState);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLInputElement>) => {
    const { name, type, checked, value } = e.target;
    const fieldValue = type === 'checkbox' ? checked : value;
    setFormData(prev => ({
      ...prev,
      [name]: fieldValue,
    }));
  }, []);

  const resetForm = useCallback(() => setFormData(initialState), [initialState]);

  return { formData, handleChange, resetForm, setFormData };
};