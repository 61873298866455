import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetPurchaseReport = (dateRange?: [Date, Date]) => {
    const [purchaseReport, setPurchaseReport] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchPurchaseReport = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getPurchaseReport(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
            );
            const purchaseReport = response.data.data;
            setPurchaseReport(purchaseReport);
        } catch (err) {
            console.error("Error al obtener los datos de compras:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
            fetchPurchaseReport();
        }
    }, [dateRange]);

    return {
        purchaseReport,
        isLoading,
        error,
        refetchPurchaseReport: fetchPurchaseReport,
    };
};