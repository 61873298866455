export const closeModal = (modalId: string) => {
    const closeModalButton = document.querySelector(`#${modalId} [data-bs-dismiss="modal"]`);
    closeModalButton?.setAttribute('aria-hidden', 'true');
    closeModalButton?.click();
};

export const openModal = (modalTarget: string) => {
    const openModalButton = document.querySelector(`[data-bs-target="#${modalTarget}"]`);
    openModalButton?.click();
};

export const closeAllModals = () => {
    const modals = document.querySelectorAll('[data-bs-dismiss="modal"]');
    modals.forEach(modal => {
        modal.setAttribute('aria-hidden', 'true');
        (modal as HTMLElement).click();
    });
};