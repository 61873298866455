import { useState, useEffect } from "react";
import { filterClassesToday } from "../../services/classService";
import { getNextClass } from "../../services/classService";
import { getSchedulesByRole } from "../../utils/utils";
import { getDefaultMonthRange } from "../../utils/dateUtils";
import { convertDateToLocalDateString } from "../../utils/dateUtils";


export const useSchedules = (role?: string, userId?: number) => {
  const [schedules, setSchedules] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [classesToday, setClassesToday] = useState(0);
  const [nextClass, setNextClass] = useState(null);

  const fetchSchedules = async () => {
    try {
      const [startDate, endDate] = getDefaultMonthRange();
      const data = await getSchedulesByRole(role, userId, convertDateToLocalDateString(startDate), convertDateToLocalDateString(endDate));
      setSchedules(data);
      setTotalRecords(data.length);

      const todayClasses = filterClassesToday(data);
      setClassesToday(todayClasses.length);

      const upcomingClass = getNextClass(todayClasses);
      setNextClass(upcomingClass);
    } catch (error) {
      console.error("Error al obtener los datos de horarios:", error);
    }
  };

  useEffect(() => {
    if (role && userId) {
      fetchSchedules();
    }
  }, [role, userId]);

  return {
    schedules,
    totalRecords,
    classesToday,
    nextClass,
    refetchSchedules: fetchSchedules,
  };
};