import React, { useState, useEffect } from 'react'
import { apiRepository } from '../../../../api/apiRepository';
import { Package } from '../../../../core/Interface/repositoryInterfaces';
import { validatePackage, ValidationError } from '../../../../core/form/validationForm';
import { InputField, DropdownField, SwitchField, ImageUploadField } from '../input/inputField';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService'
import * as Icon from 'react-feather';
import BaseModal from './baseModal';
import { TYPE_SUB_SERVICE } from '../../../../core/data/options/options';
import { UpdatePackageModalProps } from '../../../../core/Interface/modalInterface';

const PackageModal: React.FC<UpdatePackageModalProps> = ({ selectedPackage, onUpdateSuccess, categoriesPackage }) => {
  const [packageData, setPackageData] = useState<Package>([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [show, setShow] = useState<boolean>();
  const [loading, setLoading] = useState<boolean>();

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    if (name.startsWith('feature') && value.length > 25) {
      return;
    }
    setPackageData(prevState => {
      const updatedData = { ...prevState, [name]: value };
      if (name === 'packageTypeId') {
        const selectedCategory = categoriesPackage.find(category => category.value === Number(value));
        if (selectedCategory) {
          updatedData.typeValidate = selectedCategory.type;
        }
      }
      return updatedData;
    });
  };

  const handleClose = () => {
    setShow(false);
    setErrors([]);
    setPackageData([]);
  };

  useEffect(() => {
    if (selectedPackage) {
      const features = selectedPackage.description ? selectedPackage.description.split('|') : [];
      const selectedCategory = categoriesPackage.find(category => category.value === selectedPackage.packageTypeId);
      setPackageData({
        id: selectedPackage.id,
        subtitle: selectedPackage.subtitle,
        packageTypeId: selectedPackage.packageTypeId || categoriesPackage[0].value,
        title: selectedPackage.title || '',
        description: selectedPackage.description || '',
        price: selectedPackage.price || '',
        status: selectedPackage.status,
        image: selectedPackage.image || null,
        type: selectedPackage.type === 'click' ? '' : (selectedPackage.type || ''),
        typeValidate: selectedCategory?.type || 1,
        quantityDate: selectedPackage.quantityDate || '',
        quantity: selectedPackage.quantity || '',
        feature1: features[0] || '',
        feature2: features[1] || '',
        feature3: features[2] || '',
        feature4: features[3] || '',
      });
      setShow(true);
    }
  }, [selectedPackage]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    setPackageData({ ...packageData, image: file });
  };

  const handleRemoveImage = () => {
    setPackageData({ ...packageData, image: null });
    const fileInput = document.getElementById("imgInp") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleSubmit = () => {
    if (packageData.feature1 || packageData.feature2 || packageData.feature3 || packageData.feature4) {
      const formattedString = [
        packageData.feature1,
        packageData.feature2,
        packageData.feature3,
        packageData.feature4
      ]
        .filter(Boolean)
        .join("|");

      return formattedString
    } else {
      console.log("Por favor, complete al menos uno de los campos.");
    }
  };

  const handleConfirmCreate = async (event: React.FormEvent) => {
    event.preventDefault();
    const formData = true;
    setLoading(true);

    const errors = await handleFormSubmit(
      packageData?.id || -1,
      packageData,
      validatePackage,
      {
        create: apiRepository.createPackage,
        update: apiRepository.updatePackage,
      },
      () => {
        onUpdateSuccess?.();
        handleClose();
      },
      (data) => {
        const formattedString = handleSubmit();
        const updatedData = formattedString !== ''
          ? { ...data, description: formattedString }
          : data;
        if (!updatedData.quantityDate) {
          updatedData.quantityDate = 0;
        }
        return updatedData;
      },
      "Ocurrió un error al intentar con el paquete. Inténtalo de nuevo.",
      formData
    );

    if (errors) {
      setErrors(errors);
    }

    setLoading(false);
  };

  return (
    <>
      <div className="modal fade" id="add-package">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Paquete</h5>
              <button
                type="button"
                className="btn-close close-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <Icon.X className="react-feather-custom"></Icon.X>
              </button>
            </div>
            <BaseModal
              show={show || false}
              title="Paquetes"
              onClose={handleClose}
              onConfirm={handleConfirmCreate}
              loading={loading}
            >
              <form onSubmit={handleConfirmCreate}>
                {categoriesPackage.some(category =>
                  (category.type === 2 || category.type === 3) && category.value === packageData.packageTypeId
                ) && (
                    <ImageUploadField
                      formData={packageData}
                      onFileChange={handleFileChange}
                      onRemoveImage={handleRemoveImage}
                      routeName="packet"
                      required={true}
                      error={getErrorMessage(errors, 'image')}
                    />
                  )}

                <div className="row">
                  <div className="col-md-6">
                    <DropdownField
                      label="Categoria"
                      name="packageTypeId"
                      value={packageData.packageTypeId}
                      options={categoriesPackage}
                      onChange={handleInputChange}
                      error={getErrorMessage(errors, 'packageTypeId')}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      label="Titulo"
                      name="title"
                      value={packageData.title}
                      onChange={handleInputChange}
                      error={getErrorMessage(errors, 'title')}
                    />
                  </div>
                </div>
                {categoriesPackage.some(category => category.type === 1 && category.value === packageData.packageTypeId) && (

                <InputField
                  label="Subtitulo"
                  name="subtitle"
                  value={packageData.subtitle}
                  onChange={handleInputChange}
                  error={getErrorMessage(errors, 'subtitle')}
                  />
                )}
                {categoriesPackage.some(category =>
                  !(category.type === 2) && category.value === packageData.packageTypeId
                ) && (
                  <div className="row">
                    <div
                      className={`col-md-${['Clases', 'Clases Reformer'].includes(packageData.type) ? '6' : '12'
                        }`}
                    >
                      <DropdownField
                        label="Tipo"
                        name="type"
                        value={packageData.type}
                        options={TYPE_SUB_SERVICE}
                        onChange={handleInputChange}
                        placeholder="Seleccione un tipo"
                        error={getErrorMessage(errors, 'type')}
                      />
                    </div>
                    {['Clases', 'Clases Reformer'].includes(packageData.type) && (
                      <div className="col-md-6">
                        <InputField
                          label="Cantidad de clases"
                          name="quantity"
                          value={packageData.quantity}
                          onChange={handleInputChange}
                          error={getErrorMessage(errors, 'quantity')}
                        />
                      </div>
                    )}
                  </div>
                )}
                {categoriesPackage.some(category => category.type === 1 && category.value === packageData.packageTypeId) ? (
                  <div className="row">
                    <div className="col-md-6">
                      <InputField
                        label="Característica 1"
                        name="feature1"
                        value={packageData.feature1}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'feature1')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Característica 2"
                        name="feature2"
                        required={false}
                        value={packageData.feature2}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'feature2')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Característica 3"
                        name="feature3"
                        required={false}
                        value={packageData.feature3}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'feature3')}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        label="Característica 4"
                        name="feature4"
                        required={false}
                        value={packageData.feature4}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'feature4')}
                      />
                    </div>
                  </div>
                ) : (
                  <InputField
                    label="Descripción"
                    name="feature1"
                    value={packageData.feature1}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, 'feature1')}
                  />
                )}
                <div className="row">
                  <div className="col">
                    <InputField
                      label="Precio"
                      name="price"
                      value={packageData.price}
                      onChange={handleInputChange}
                      error={getErrorMessage(errors, 'price')}
                    />
                  </div>
                  {categoriesPackage.some(category =>
                    !(category.type === 2) && category.value === packageData.packageTypeId
                  ) && (
                    <div className="col-md-6">
                      <InputField
                        label="Cantidad de Días Validos"
                        name="quantityDate"
                        value={packageData.quantityDate}
                        onChange={handleInputChange}
                        error={getErrorMessage(errors, 'quantityDate')}
                      />
                    </div>
                  )}
                </div>
                <SwitchField
                  label="Estado"
                  checked={packageData.status}
                  onChange={handleInputChange}
                  defaultChecked={true}
                  disabled={!packageData.id}
                  name="status"
                />
                {errors.find(error => error.field === 'server') && (
                  <div className="alert alert-danger" role="alert">
                    {errors.find(error => error.field === 'server')?.message}
                  </div>
                )}
              </form>
            </BaseModal>
          </div>
        </div>
      </div>
    </>
  )
}

export default PackageModal