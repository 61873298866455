import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { GiftCard } from '../../../core/Interface/repositoryInterfaces'; // Asegúrate de tener la interfaz de GiftCard
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import GiftCardModal from '../common/modals/giftCard-modal'; // Modal para GiftCard
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsPackage';

const GiftCards = () => {
    const [data, setData] = useState<GiftCard[]>([]);
    const [totalGiftCards, setTotalGiftCards] = useState<number>(-1);
    const [first, setFirst] = useState(0);
    const [rows, setRows] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedGiftCard, setSelectedGiftCard] = useState<any>(null);

    const fetchGiftCards = async (pageNumber = currentPage, pageSize = rows) => {
        setLoading(true);
        try {
            const response = await apiRepository.getGiftCards(pageNumber, pageSize);
            const totalRecords = response.data.data.totalElements;
            setData(response.data.data.content);
            setTotalGiftCards(totalRecords);
        } catch (error) {
            console.error("Error al obtener los datos de las GiftCards:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGiftCards();
    }, []);

    const handleEditClick = (rowData: any) => {
        setSelectedGiftCard(null);
        setTimeout(() => setSelectedGiftCard(rowData), 0);
    };


    const columns = [
        { field: "type", header: "Tipo" },
        { field: "quantity", header: "Cantidad" },
        { field: "quantityDate", header: "Días de expiración" },
        { field: "endDate", header: "Fecha Final", body: (rowData: any) => rowData.endDate || 'No especificada' },
        {
            field: "status",
            header: "Estado",
            body: (rowData: any) => (
                <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
                    {rowData.status ? 'Canjeado' : 'No Canjeado'}
                </span>
            )
        },
    ];

    const getColumns = useResponsiveColumns(columns);

    const onPageChange = (event: any) => {
        setFirst(event.first);
        setRows(event.rows);
        const pageNumber = event.first / event.rows;
        setCurrentPage(pageNumber);
        fetchGiftCards(pageNumber, event.rows);
    };

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>GiftCards</h5>
                    <div className="list-btn">
                        <button className="btn btn-primary-admin" onClick={handleEditClick}>
                            <i className="fa fa-plus me-2" /> Agregar GiftCard
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    loading={loading}
                    paginator
                    showGridlines
                    rowsPerPage={rows}
                    totalRecords={totalGiftCards}
                    first={first}
                    onPageChange={onPageChange}
                    emptyMessage="No se encontraron GiftCards."
                />
            </div>
            <GiftCardModal selectedGiftCard={selectedGiftCard} onUpdateSuccess={fetchGiftCards} />
        </div>
    );
};

export default GiftCards;