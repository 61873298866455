import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';
import BaseModal from './baseModalDelete';
import { getErrorMessage  } from '../../../../core/utils/errorUtils';
import { ValidationError } from '../../../../core/form/validationForm';
import { DeleteCustomersModalProps } from '../../../../core/Interface/modalInterface';

const DeleteCustomersModal: React.FC<DeleteCustomersModalProps> = ({ selectedUserId, onDeleteSuccess }) => {
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<ValidationError[]>([]);

    useEffect(() => {
        if (selectedUserId) {
            setShow(true);
        }
    }, [selectedUserId]);

    const handleClose = () => {
        setShow(false);
        setErrors([]);
    };

    const handleConfirmDelete = async () => {
        if (selectedUserId === -1) return;

        try {
            setLoading(true);
            await apiRepository.deleteSubscriptions(selectedUserId);

            if (onDeleteSuccess) {
                onDeleteSuccess();
                setShow(false);
            }
        } catch (error) {
            errors.push({ field: "server", message: "Error al eliminar suscripción." });
        } finally {
            setLoading(false);
        }
    };

    return (
        <BaseModal
            show={show || false}
            title="Suscripción"
            onClose={handleClose}
            onConfirm={handleConfirmDelete}
            loading={loading}
        >
            <div className="text-center">
                <Icon.Trash2 size={45} color="#f05050" className="text-danger fs-1" />
                <div className="mt-4">
                    <h4>¿Eliminar Suscripción?</h4>
                    <p className="text-muted mb-0">
                        ¿Estás seguro de que quieres eliminar esta suscripción?
                    </p>
                    {errors.find(error => error.field === 'server') && (
                        <div className="alert alert-danger mt-3" role="alert">
                            {getErrorMessage(errors, "server")}
                        </div>
                    )}
                </div>
            </div>
        </BaseModal>
    );
};

export default DeleteCustomersModal;