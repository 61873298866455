export const pageData = [
  {
    id: 1,
    title: 'Categories',
    language: 'English',
    location: 'Top Menu',
    date: '28 Sep 2023',
    Action: '',
  },
  {
    id: 2,
    title: 'Contact',
    language: 'English',
    location: 'Quick Links',
    date: '15 Sep 2023',
    Action: '',
  },
  {
    id: 3,
    title: 'Blog',
    language: 'English',
    location: 'Top Menu',
    date: '10 Sep 2023',
    Action: '',
  },
  {
    id: 4,
    title: 'Shops',
    language: 'English',
    location: 'Quick Links',
    date: '30 Sep 2023',
    Action: '',
  },
  {
    id: 5,
    title: 'Services',
    language: 'English',
    location: 'Top Menu',
    date: '23 Sep 2023',
    Action: '',
  },
  {
    id: 6,
    title: 'Services',
    language: 'English',
    location: 'Quick Links',
    date: '04 Sep 2023',
    Action: '',
  },
];
