import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../api/apiRepository';
import { Package } from '../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import ClassesModal from '../common/modals/classes-modal';
import DeleteClassesModal from '../common/modals/delete-classes-modal';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumnsClasses';

const Classes = () => {
    const [data, setData] = useState<Package[]>([]);
    const [headquarters, setHeadquarters] = useState<any[]>([]);
    const [selectedClassId, setSelectedClassId] = useState<any>(null);
    const [selectedClass, setSelectedClass] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const fetchClasses = async () => {
        try {
            setLoading(true);
            const response = await apiRepository.getClasses();
            setData(response.data.data);
        } catch (error) {
            console.error("Error al obtener los datos de clases:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchHeadquarters = async () => {
        try {
            const response = await apiRepository.getHeadquarters();
            const formattedHeadquarters = response.data.data.map((item: any) => ({
                label: item.name,
                value: item.id,
            }));
            setHeadquarters(formattedHeadquarters);
        } catch (error) {
            console.error("Error al obtener los datos de los establecimientos:", error);
        }
    };

    useEffect(() => {
        fetchClasses();
        fetchHeadquarters();
    }, []);

    const handleEditClick = (rowData: any) => {
        setSelectedClass(null);
        setTimeout(() => setSelectedClass(rowData), 0);
    };

    const handleDeleteClick = (rowData: any) => {
        setSelectedClassId(rowData.id);
    };

    const columns = [
        { field: 'title', header: 'Titulo' },
        { field: 'headquartersName', header: 'Nombre del Establecimiento' },
        {
            field: "status",
            header: "Estado",
            body: (rowData: any) => (
                <span className={rowData.status ? 'badge-active' : 'badge-inactive'}>
                    {rowData.status ? 'Activo' : 'Inactivo'}
                </span>
            )
        },
        {
            field: "isReformer",
            header: "Tipo de clase",
            body: (rowData: any) => rowData.isReformer === 'SI' ? 'Clase Reformer' : 'Clase Standard'
        },
        { field: 'path', header: 'Ruta de redirección' },
    ];

    const getColumns = useResponsiveColumns(columns);

    return (
        <div className="page-wrapper page-settings">
            <div className="content">
                <div className="content-page-header content-page-headersplit">
                    <h5>Clases</h5>
                    <div className="list-btn">
                        <button
                            className="btn btn-primary-admin"
                            type="button"
                            onClick={handleEditClick}
                        >
                            <i className="fa fa-plus me-2" />
                            Agregar Clase
                        </button>
                    </div>
                </div>
                <DataTableWrapper
                    data={data}
                    columns={getColumns()}
                    onEdit={handleEditClick}
                    onDelete={handleDeleteClick}
                    loading={loading}
                    showGridlines
                    emptyMessage="No se encontraron clases."
                />
            </div>
            <ClassesModal selectedClasses={selectedClass} onUpdateSuccess={fetchClasses} headquarters={headquarters} />
            <DeleteClassesModal selectedClassesId={selectedClassId} onDeleteSuccess={fetchClasses} />
        </div>
    );
};

export default Classes;