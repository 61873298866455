import React, { useState } from 'react';
import * as Icon from 'react-feather';
import DataTableWrapper from '../common/dataTable/dataTableWrapper';
import { useResponsiveColumns } from '../../../core/hooks/useResponsiveColumns';
import { getDefaultMonthRange } from '../../../core/utils/dateUtils';
import { CalendarRangeField, DropdownField } from '../common/input/inputField';
import ShowDetailClassProfitabilityModal from '../common/modals/show-detail-class-profitability-modal';
import { useGetProfitabilityClassesExelFile } from '../../../core/hooks/getReports/useGetProfitabilityClassesExelFile';
import { downloadExcelFile } from '../../../core/utils/fileUtils';
import { useGetProfitabilityClasses } from '../../../core/hooks/getReports/useGetProfitabilityClasses';
import { useCustomerUsers } from '../../../core/hooks/getData/useCustomerUsers';

const ProfitabilityClasses = () => {
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultMonthRange());
  const [customerId, setCustomerId] = useState<string>();
  const [minAge, setMinAge] = useState<string>();
  const [maxAge, setMaxAge] = useState<string>();
  const [selectedReservation, setSelectedReservation] = useState<any>();
  const { profitabilityClasses } = useGetProfitabilityClasses(dateRange, customerId, minAge, maxAge);
  const { refetchProfitabilityClasses } = useGetProfitabilityClassesExelFile(dateRange, customerId, minAge, maxAge);
  const { customerUsersData } = useCustomerUsers({ shouldOptions: true });

  const columns = [
    { field: "userName", header: "Cliente" },
    { field: "age", header: "Edad" },
    { field: "userEmail", header: "Correo Electrónico" },
    { field: "totalReservations", header: "Reservas Totales" },
  ];

  const getColumns = useResponsiveColumns(columns);

  const handleShowInfo = (rowData: any) => {
    console.log(rowData);
    setSelectedReservation(null);
    setTimeout(() => setSelectedReservation(rowData), 0);
  };

  const handleDownloadExcel = async () => {
    if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined)) {
      const file = await refetchProfitabilityClasses();
      downloadExcelFile(file, 'reservation-report');
    }
  };

  return (
    <div className="page-wrapper page-settings">
      <div className="content">
        <div className="content-page-header content-page-headersplit">
          <h5>Rentabilidad de Clases</h5>
        </div>
        <div className="card card-status mb-3">
          <div className="card-head-blk border-bottom-0 pb-0">
            <div className="row align-items-center">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-outline-secondary rounded-3 mb-3 me-2"
                  onClick={() => {
                    setDateRange(getDefaultMonthRange());
                    setCustomerId('');
                    setMinAge('');
                    setMaxAge('');
                  }}
                >
                  <i className="fa fa-eraser me-2" />
                  Limpiar
                </button>
                <button
                  className="btn btn-primary-admin rounded-3 mb-3 align-items-center d-flex me-2"
                  onClick={handleDownloadExcel}
                >
                  <Icon.FileText className="react-feather-icon me-2" />
                  <span>Descargar como Excel</span>
                </button>
              </div>
              <div className="col-xl-12 col-md-12 d-flex">
                <div className="earning-group gap-3 w-100">
                  <CalendarRangeField
                    value={dateRange || [new Date(), new Date()]}
                    onChange={(e: any) => setDateRange(e.value)}
                  />
                  <DropdownField
                    value={customerId}
                    onChange={(e: any) => setCustomerId(e.value)}
                    options={customerUsersData || []}
                    placeholder="Selecciona un cliente"
                    className="mb-3"
                  />
                  <div className="d-flex gap-3 mb-3">
                    <input
                      type="number"
                      className="form-control"
                      style={{ height: '40px', maxWidth: '200px' }}
                      value={minAge || ''}
                      onChange={(e) => setMinAge(e.target.value)}
                      placeholder="Edad mínima"
                    />
                    <input
                      type="number"
                      className="form-control"
                      style={{ height: '40px', maxWidth: '200px' }}
                      value={maxAge || ''}
                      onChange={(e) => setMaxAge(e.target.value)}
                      placeholder="Edad máxima"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <DataTableWrapper
            data={profitabilityClasses || []}
            sortField='userName'
            lazy={false}
            sortOrder={-1}
            columns={getColumns()}
            customIcon={Icon.Eye}
            onCustomAction={handleShowInfo}
            paginator={false}
            showGridlines
            emptyMessage="No se encontraron reservas."
          />
        </div>
        <ShowDetailClassProfitabilityModal selectedClassData={selectedReservation} />
      </div>
    </div>
  );
};

export default ProfitabilityClasses;