import React, { useState, useEffect } from 'react';
import * as Icon from 'react-feather';
import { apiRepository } from '../../../../api/apiRepository';
import BaseModal from './baseModalDelete';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { ValidationError } from '../../../../core/form/validationForm';
import { DeleteClassesModalProps } from '../../../../core/Interface/modalInterface';

const DeleteClassesModal: React.FC<DeleteClassesModalProps> = ({ selectedClassesId, onDeleteSuccess }) => {
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<ValidationError[]>([]);

    useEffect(() => {
        if (selectedClassesId) {
            setShow(true);
        }
    }, [selectedClassesId]);

    const handleClose = () => {
        setShow(false);
        setErrors([]);
    };

    const handleConfirmDelete = async () => {
        if (selectedClassesId === -1) return;

        try {
            setLoading(true);
            console.log("Intentando eliminar clase con ID:", selectedClassesId);
            await apiRepository.deleteClasses(selectedClassesId);
            console.log("Clase eliminada con éxito");

            if (onDeleteSuccess) {
                setShow(false);
                onDeleteSuccess();
            }
        } catch (error) {
            setErrors([{ field: 'server', message: 'Error al eliminar clase.' }]);
            console.error("Error al eliminar clase:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <BaseModal
                show={show}
                title="Clase"
                onClose={handleClose}
                onConfirm={handleConfirmDelete}
                loading={loading}
            >
                <div className="text-center">
                    <Icon.Trash2 size={45} color='#f05050' className="text-danger fs-1" />
                    <div className="mt-4">
                        <h4>¿Eliminar Clase?</h4>
                        <p className="text-muted mb-0">¿Estás seguro de que quieres eliminar esta clase?</p>
                        {errors.find(error => error.field === 'server') && (
                            <div className="alert alert-danger mt-3" role="alert">
                                {getErrorMessage(errors, "server")}
                            </div>
                        )}
                    </div>
                </div>
            </BaseModal>
        </>
    );
};

export default DeleteClassesModal;