import { EnterpriseInformation } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect } from "react";

export const useEnterpriseData = () => {
    const [enterpriseData, setEnterpriseData] = useState<EnterpriseInformation>([]);

    useEffect(() => {
        const fetchEnterpriseInformation = async () => {
            try {
                const response = await apiRepository.getEnterpriseInformation();
                setEnterpriseData(response.data.data);
            } catch (error) {
                console.error("Error al obtener los datos de la empresa:", error);
            }
        };

        fetchEnterpriseInformation();
    }, []);

    return enterpriseData;
};