import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetCustomerPreferencesReport = (dateRange?: [Date, Date], classId?: string, trainerId?: string, minAge?: number, maxAge?: number) => {
    const [customerPreferences, setCustomerPreferences] = useState([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchCustomerPreferences = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getCustomerPreferenceReport(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
                classId,
                trainerId,
                minAge,
                maxAge
            );
            const customerPreferences = response.data.data;
            setCustomerPreferences(customerPreferences);
        } catch (err) {
            console.error("Error al obtener los datos de preferencias de clientes:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const isAgeRangeValid = (minAge && maxAge) || (!minAge && !maxAge);
        if (dateRange?.length === 2 && dateRange.every(date => date !== null && date !== undefined) && isAgeRangeValid) {
            fetchCustomerPreferences();
        }
    }, [dateRange, classId, trainerId, minAge, maxAge]);

    return {
        customerPreferences,
        isLoading,
        error,
        refetchCustomerPreferences: fetchCustomerPreferences,
    };
};