import { Classes } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect } from "react";
import { UseClassesOptions } from "../../Interface/hooksInterface";

export const useSubTypeClasses = ({ shouldTransform = false, shouldOptions = false }: UseClassesOptions) => {
    const [subTypeClassesData, setSubTypeClassesData] = useState(shouldOptions ? [] as { label: string; value: any }[] : [] as Classes[]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchSubTypeClasses = async () => {
        try {
            const response = await apiRepository.getSubTypeClasses();
            const rawData = response.data.data;

            if (shouldOptions) {
                const optionsData = rawData.map((item: any) => ({
                    label: item.name,
                    value: item.id,
                }));
                setSubTypeClassesData(optionsData);
            } else if (shouldTransform) {
                const transformedData = rawData.map((item: any) => ({
                    id: item.id,
                    name: item.name,
                    description: item.description,
                    classId: item.classId,
                    className: item.className,
                }));
                setSubTypeClassesData(transformedData);
            } else {
                setSubTypeClassesData(rawData);
            }
        } catch (error) {
            console.error("Error al obtener los datos del tipo clases:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchSubTypeClasses();
    }, [shouldTransform, shouldOptions]);

    return { subTypeClassesData, isLoading, fetchSubTypeClasses: fetchSubTypeClasses };
};