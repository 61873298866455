import React, { useState, useContext } from 'react';
import { ValidationError } from '../../../core/form/validationForm';
import { DropdownField } from '../../admin/common/input/inputField';
import { useLocation, useNavigate } from 'react-router-dom';
import { CLASS_STATUS } from '../../../core/data/options/options';
import { AuthContext } from '../../../core/context/AuthContext';
import { all_routes } from '../../../core/data/routes/all_routes';
import { useFetchClasses, useFetchSchedules, useFetchSchedulesList } from '../../../core/hooks/getData/useFetchData';
import { useHandleStatusUpdate, useHandleSubmit } from '../../../core/hooks/utils/useHandleActions';

const ClassAttendanceManagement = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }

  const [classes, setClasses] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedulesClass, setSchedulesClass] = useState([]);
  const [errors, setErrors] = useState<ValidationError[]>([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [scheduleId, setScheduleId] = useState(queryParams.get('id'));
  const { user } = authContext;
  const routes = all_routes;

  const [selectAll, setSelectAll] = useState(false);

  const navigate = useNavigate();

  useFetchSchedulesList({ user, setSchedulesClass });
  useFetchClasses({ scheduleId, setClasses });
  useFetchSchedules({ scheduleId, setSchedules });

  const handleStatusUpdate = useHandleStatusUpdate({ scheduleId, schedules, setLoading, setErrors });
  const handleSubmit = useHandleSubmit({ scheduleId, classes, schedules, setErrors, setLoading, handleStatusUpdate });

  const handleStatusChange = (event) => {
    const newStatus = event.target.value;
    setSchedules({ ...schedules, status: newStatus });
  };

  const handleCheckboxToggle = (id) => {
    setClasses(classes.map(classItem =>
      classItem.id === id ? { ...classItem, attended: !classItem.attended } : classItem
    ));
  };

  const handleSelectAllToggle = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    setClasses(classes.map(classItem => ({
      ...classItem,
      attended: newSelectAll,
    })));
  };

  const handleBackClick = () => {
    const backQueryParams = new URLSearchParams({
        dateRange: queryParams.get('dateRange') || '',
        classId: queryParams.get('classId') || '',
        trainerId: queryParams.get('trainerId') || '',
        selectedClassLabel: queryParams.get('selectedClassLabel') || '',
        selectedTrainerLabel: queryParams.get('selectedTrainerLabel') || '',
        currentPage: queryParams.get('currentPage') || '0',
        rows: queryParams.get('rows') || '10'
    });
    navigate(`${routes.classSchedules}?${backQueryParams.toString()}`);
};

  const isValidStatus = schedules.status === 2 || schedules.status === 3;

  return (
    <>
      <div className="page-wrapper page-settings">
        <div className="content">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h4 className="mb-0">Administrar Clase</h4>
            <button
              className="btn btn-secondary"
              onClick={handleBackClick}
            >
              <i className="fa fa-arrow-left me-2"></i>
              Regresar
            </button>
          </div>

          {scheduleId && (
            <>
              <div className="card review-item mb-3">
                <div className="card-body">
                  <div className="review-info">
                    <div className="row align-items-center justify-content-between row-gap-3">
                      <h6 className="mb-0">Selecciona el estado de la clase {schedules.title}</h6>
                      <div className="col-12 col-md-auto">
                        <DropdownField
                          className={`mb-0 ${schedules.status !== 1 ? "mb-2" : ""}`}
                          options={CLASS_STATUS}
                          value={schedules.status}
                          onChange={handleStatusChange}
                          placeholder="Selecciona el estado de la clase"
                        />
                        {schedules.status !== 1 && (
                          <small className="text-gray mb-2">
                            Al seleccionar esta opción, los clientes ya no podrán inscribirse.
                          </small>
                        )}
                      </div>
                      <div className="col-12 col-md-auto filter-buttons">
                        <button className="btn btn-primary-admin w-100" onClick={handleStatusUpdate}>
                          {loading ? <i className="fa fa-spinner fa-spin me-2"></i> : <i className="fa fa-cog me-2" />}
                          Enviar estado de clase
                        </button>
                      </div>
                    </div>
                    {errors.find((error) => error.field === 'succesStatus') && (
                      <div className="col-12 alert alert-success mb-0 mt-3" role="alert">
                        {errors.find((error) => error.field === 'succesStatus')?.message}
                      </div>
                    )}
                    {errors.find((error) => error.field === 'status') && (
                      <div className="col-12 alert alert-danger mb-0 mt-3" role="alert">
                        {errors.find((error) => error.field === 'status')?.message}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="card review-item mb-3">
                <div className="card-body">
                  <div className="review-info">
                    <div className="row align-items-center justify-content-between row-gap-3">
                      <div className="d-flex justify-content-between align-items-center w-100">
                        <h6 className="mb-0">Completa la lista de asistencia</h6>
                        {!selectAll ? (
                          <>
                            <button
                              className="btn btn-primary-admin d-flex align-items-center"
                              onClick={() => handleSelectAllToggle(true)}
                            >
                              <i className="fa fa-check-square me-2"></i>
                              Seleccionar Todo
                            </button>
                          </>
                        ) : (
                          <button
                            className="btn btn-secondary d-flex align-items-center"
                            onClick={() => handleSelectAllToggle(false)}
                          >
                            <i className="fa fa-minus-square me-2"></i>
                            Desmarcar Todo
                          </button>
                        )}
                      </div>

                      <div className="reservation">
                        <ul className="list-group">
                          {classes.length === 0 ? (
                            <li className="list-group-item p-3 text-center">Sin registros</li>
                          ) : (
                            classes
                              .sort((a: any, b: any) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
                              .slice(0, 20)
                              .map((classItem) => (
                                <li
                                  key={classItem.id}
                                  className="list-group-item item-select p-3"
                                  onClick={() => handleCheckboxToggle(classItem.id)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h6 className="mb-0">{classItem.customerName}</h6>
                                      <p className="mt-0 mb-2 text-muted fs-6">{classItem.titleClass}</p>
                                      <p className="m-0 fw-bold fs-7">{classItem.conditionMedical || "No tiene condición medica"}</p>
                                    </div>
                                    <div>
                                      <input
                                        type="checkbox"
                                        checked={classItem.attended}
                                        onChange={() => handleCheckboxToggle(classItem.id)}
                                        className="form-check-input"
                                      />
                                      <label className="ms-2">
                                        Asistió
                                      </label>
                                    </div>
                                  </div>
                                </li>
                              ))
                          )}
                        </ul>

                        {errors.find((error) => error.field === 'assistSuccess') && (
                          <div className="alert alert-success mt-4" role="alert">
                            {errors.find((error) => error.field === 'assistSuccess')?.message}
                          </div>
                        )}
                        {errors.find((error) => error.field === 'assist') && (
                          <div className="alert alert-danger mt-4" role="alert">
                            {errors.find((error) => error.field === 'assist')?.message}
                          </div>
                        )}
                        <button
                          className="btn btn-success mt-4"
                          onClick={handleSubmit}
                          disabled={loading || classes.length === 0 || !isValidStatus}
                        >
                          {loading ? <i className="fa fa-spinner fa-spin me-2"></i> : <i className="fa fa-list-check  me-2" />}
                          Confirmar asistencia
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ClassAttendanceManagement;