import React from 'react';
import { AlertMessageProps } from '../../core/Interface/interfaceComponents';

const AlertMessage: React.FC<AlertMessageProps> = ({ errors, field, type }) => {
    const error = errors.find((error) => error.field === field);

    if (!error) {
        return null;
    }

    return (
        <div className={`alert alert-${type}`} role="alert">
            {error.message}
        </div>
    );
};

export default AlertMessage;