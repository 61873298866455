import { useState } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetCustomerPreferencesReportExelFile = (dateRange?: [Date, Date], classId?: string, trainerId?: string, minAge?: number, maxAge?: number) => {
    const [customerPreferencesReportExelFile, setCustomerPreferencesReportExelFile] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchCustomerPreferencesReport = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getCustomerPreferenceReportExcelFile(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
                classId,
                trainerId,
                minAge,
                maxAge
            );
            const customerPreferencesReportExelFile = response.data;
            setCustomerPreferencesReportExelFile(customerPreferencesReportExelFile);
            return customerPreferencesReportExelFile;
        } catch (err) {
            console.error("Error al obtener los datos de preferencias de clientes:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        customerPreferencesReportExelFile,
        isLoading,
        error,
        refetchCustomerPreferencesReport: fetchCustomerPreferencesReport,
    };
};