import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ServiceInformation from './serviceInformation';
import Location from './location';
import EditSeo from './seo';
import { apiRepository } from '../../../api/apiRepository';

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AddService = () => {
  const query = useQuery();
  const id = query.get('id');
  const [activeTab, setActiveTab] = useState('information');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [campaignData, setCampaignData] = useState(null); // Datos de la campaña

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const response = await apiRepository.getSubscriptions(0, 10);
      const updatedUsers = response.data.data.content.map((user) => ({
        ...user,
        selected: false,
      }));
      setUsers(updatedUsers);
    } catch (error) {
      console.error('Error al obtener los datos de los usuarios:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCampaignData = async (campaignId) => {
    try {
      setLoading(true);
      const response = await apiRepository.getIdCampaigns(campaignId);
      setCampaignData(response.data.data);
    } catch (error) {
      console.error('Error al obtener los datos de la campaña:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchCampaignData(id); // Cargar datos de la campaña si existe un `id`
    }
    fetchUsers(); // Cargar usuarios
  }, [id]);

  const handleUsersSelection = (selectedUsers) => {
    setSelectedUsers(selectedUsers);
  };

  const handleEmailData = (emailData) => {
    setEmailData(emailData);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content pt-2">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="progressbar-card">
                <ul id="progress-head">
                  <li className={activeTab === 'information' ? 'active' : ''}>
                    Seleccionar Correos y Configuración
                  </li>
                  <li className={activeTab === 'seo' ? 'active' : ''}>
                    Editar Correo - Asunto, Descripción y Cuerpo
                  </li>
                  <li className={activeTab === 'location' ? 'active' : ''}>
                    Resumen y Enviar Correo
                  </li>
                </ul>
                <ul id="progressbar">
                  <li className={activeTab === 'information' ? 'active' : ''}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="far fa-envelope" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Seleccionar Correos</h6>
                    </div>
                  </li>
                  <li className={activeTab === 'seo' ? 'active' : ''}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="far fa-edit" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Editar Correo</h6>
                    </div>
                  </li>
                  <li className={activeTab === 'location' ? 'active' : ''}>
                    <div className="multi-step-icon">
                      <span>
                        <i className="far fa-paper-plane" />
                      </span>
                    </div>
                    <div className="multi-step-info">
                      <h6>Resumen y Enviar</h6>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 m-auto">
              {activeTab === 'information' && (
                <ServiceInformation
                  nextTab={() => handleTabChange('seo')}
                  onUsersSelection={handleUsersSelection}
                  usersSuscription={users}
                  details={campaignData?.details || []} // Pasar detalles al componente
                />
              )}
              {activeTab === 'seo' && (
                <EditSeo
                  prevTab={() => handleTabChange('information')}
                  nextTab={() => handleTabChange('location')}
                  onEmailData={handleEmailData}
                  emailData={emailData}
                  name={campaignData?.name} // Pasar nombre
                  subject={campaignData?.subject} // Pasar asunto
                />
              )}
              {activeTab === 'location' && (
                <Location
                  prevTab={() => handleTabChange('seo')}
                  selectedUsers={selectedUsers}
                  emailData={emailData}
                  campaign={campaignData} // Pasar todos los datos
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddService;