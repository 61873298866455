import { useState } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { convertDateToLocalDateString } from "../../../core/utils/dateUtils";

export const useGetPurchaseReportExelFile = (dateRange?: [Date, Date]) => {
    const [purchaseReportExelFile, setPurchaseReportExelFile] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<Error | null>(null);

    const fetchPurchaseReport = async () => {
        try {
            setIsLoading(true);
            setError(null);

            const [startDate, endDate] = dateRange;
            const response = await apiRepository.getPurchaseReportExcelFile(
                convertDateToLocalDateString(startDate),
                convertDateToLocalDateString(endDate),
            );
            const purchaseReportExelFile = response.data;
            setPurchaseReportExelFile(purchaseReportExelFile);
            return purchaseReportExelFile;
        } catch (err) {
            console.error("Error al obtener los datos de compras:", err);
            setError(err instanceof Error ? err : new Error("Error desconocido"));
        } finally {
            setIsLoading(false);
        }
    };

    return {
        purchaseReportExelFile,
        isLoading,
        error,
        refetchPurchaseReport: fetchPurchaseReport,
    };
};