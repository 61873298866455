import React, { useState, useEffect, useContext } from 'react';
import { apiRepository } from '../../../../api/apiRepository';
import { Schedules } from '../../../../core/Interface/repositoryInterfaces';
import DataTableWrapper from '../../../admin/common/dataTable/dataTableWrapper';
import { useResponsiveColumns } from '../../../../core/hooks/useResponsiveColumnsTrainer';
import { useOutletContext } from 'react-router-dom';
import { AuthContext } from '../../../../core/context/AuthContext';
import { formatTimeTo12Hour } from '../../../../core/utils/utils';
import { getDefaultWeekRange } from '../../../../core/utils/dateUtils';
import { formatToCustomDate } from '../../../../core/utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { Eye } from 'react-feather';
import { all_routes } from '../../../../core/data/routes/all_routes';
import Filters from './schedulesFilters';
import { isFutureClass } from '../../../../core/utils/classUtils';
import { BreadcrumbContext } from '../../../../core/Interface/contextInterface';

const CustomerProfile = () => {
  const authContext = useContext(AuthContext);
  if (!authContext) {
    return <div>Error: Auth context not found!</div>;
  }
  const { user } = authContext;
  const [data, setData] = useState<Schedules[]>([]);
  const { setBreadcrumbData } = useOutletContext<BreadcrumbContext>();
  const [filtersVisible, setFiltersVisible] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { dateRange: any; status: string; filterFuture: boolean; };
  const [filterFuture, setFilterFuture] = useState(state?.filterFuture || false);
  const [status, setStatus] = useState<string>(state?.status || '');
  const [dateRange, setDateRange] = useState(state?.dateRange || getDefaultWeekRange());
  const routes = all_routes;

  useEffect(() => {
    setBreadcrumbData({ title: "Horarios", item2: "Horarios", updateDays: false });
  }, [setBreadcrumbData]);

  const filterSchedules = (schedules: any) => {
    return schedules.filter(schedule => {
      const passesFutureFilter = !filterFuture || isFutureClass(schedule);
      const passesStatusFilter = !status || schedule.status === parseInt(status);

      return passesFutureFilter && passesStatusFilter;
    });
  };

  const fetchSchedules = async () => {
    const [startDate, endDate] = dateRange;

    try {
      let response;

      if (user?.role === "trainer") {
        response = await apiRepository.getTrainerSchedules(user?.userId, formatToCustomDate(startDate), formatToCustomDate(endDate));
      } else {
        response = await apiRepository.getUserSchedules(user?.userId, formatToCustomDate(startDate), formatToCustomDate(endDate));
      }

      const filteredSchedules = filterSchedules(response.data.data);
      setData(filteredSchedules);
    } catch (error) {
      console.error('Error al obtener los datos de usuarios:', error);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, [filterFuture, dateRange, status]);

  const formatReservation = (reservation: number) => {
    return reservation > 0 ? reservation : 'Sin reservas';
  };

  const columns = [
    { field: "title", header: "Nombre" },
    {
      field: "status",
      header: "Estado",
      body: (rowData) => {
        let label = '';
        let className = '';

        switch (rowData.status) {
          case 1:
            label = 'Pendiente';
            className = 'badge-pending';
            break;
          case 2:
            label = 'En Atención';
            className = 'badge-active';
            break;
          case 3:
            label = 'Finalizada';
            className = 'badge-default';
            break;
          case 4:
            label = 'Cancelado';
            className = 'badge-delete';
            break;
          case 5:
            label = 'Finalizada';
            className = 'badge-active';
            break;
          default:
            label = 'Desconocido';
            className = 'badge-default';
        }

        return <span className={className}>{label}</span>;
      }
    },
    {
      field: "date",
      header: "Fecha y Horario",
      sortable: true,
      body: (rowData) => {
        return (
          <span>
            {rowData.date} - {formatTimeTo12Hour(rowData.hourStart)} a {formatTimeTo12Hour(rowData.hourEnd)}
          </span>
        );
      }
    },
    { field: "quantity", header: "Limite de Cupos" },
    {
      field: "reservation",
      header: "Reservas",
      body: (rowData) => formatReservation(rowData.reservation),
    },
  ];

  const handleCustomActionClick = (rowData: any) => {
    navigate(`${routes.schedulesList}?id=${rowData.id}`, { state: { dateRange: dateRange, status: status, filterFuture: filterFuture } });
  };

  const getColumns = useResponsiveColumns(columns);

  return (
    <>
      <h4>Clases</h4>
      <div className="">
        <div className="d-flex justify-content-between mt-3 mb-3">
          <div className="filter-buttons me-1">
            <button
              className={`btn ${filterFuture ? 'btn-primary-solid' : 'btn-light'}`}
              onClick={() => setFilterFuture(prev => !prev)}>
              <i className="fa fa-eye me-2" />
              {filterFuture ? 'Mostrar todas las clases' : 'Mostrar solo clases futuras'}
            </button>
          </div>
          <div className="list-btn">
            <button
              className="btn btn-outline-secondary"
              onClick={() => setFiltersVisible(!filtersVisible)}
            >
              {filtersVisible ? 'Ocultar Filtros' : 'Mostrar Filtros'}
            </button>
          </div>
        </div>
      </div>

      {filtersVisible && (
        <Filters
          status={status}
          setStatus={setStatus}
          dateRange={dateRange}
          setDateRange={setDateRange}
          filterFuture={filterFuture}
          setFilterFuture={setFilterFuture}
        />
      )}

      <div className="row">
        <div className="col-12">
          <DataTableWrapper
            data={data}
            columns={getColumns()}
            onCustomAction={user?.role === "trainer" ? handleCustomActionClick : undefined}
            customIcon={Eye}
            lazy={false}
            paginator={false}
            sortField="date"
            sortOrder={-1}
            showGridlines
            emptyMessage="No se encontraron horarios."
          />
        </div>
      </div>
    </>
  );
};

export default CustomerProfile;