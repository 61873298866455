import { useState, useEffect } from "react";
import { apiRepository } from "../../../api/apiRepository";
import { decryptResponse } from "../../../core/utils/encryptUtils";

export const useSecretKeyInfo = () => {
    const [user, setUser] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchSecretKeyInfo = async () => {
            try {
                setIsLoading(true);
                setError(null);

                const response = await apiRepository.getSecretKeyInformation();
                const encryptedData = response.data.data;
                const secretKey = "encryption-key";
                const decryptedData = decryptResponse(encryptedData, secretKey);

                if (decryptedData.isDev) {
                    setUser(decryptedData.devUser);
                    setToken(decryptedData.devToken);
                } else {
                    setUser(decryptedData.prodUser);
                    setToken(decryptedData.prodToken);
                }
            } catch (error) {
                console.error("Error al obtener la clave secreta:", error);
                setError("Error al obtener la clave secreta.");
            } finally {
                setIsLoading(false);
            }
        };

        fetchSecretKeyInfo();
    }, []);

    return { user, token, isLoading, error };
};