import { Headquarters } from "../../Interface/repositoryInterfaces";
import { apiRepository } from "../../../api/apiRepository";
import { useState, useEffect } from "react";
import { UseHeadquartersOptions } from "../../Interface/hooksInterface";

export const useHeadquarters = ({ shouldTransform = false }: UseHeadquartersOptions) => {
    const [headquartersData, setHeadquartersData] = useState<Headquarters[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchHeadquarters = async () => {
            try {
                const response = await apiRepository.getHeadquarters();
                const rawData = response.data.data;

                if (shouldTransform) {
                    const transformedData = rawData.map((item: any) => ({
                        label: item.name,
                        value: item.id.toString(),
                    }));
                    setHeadquartersData(transformedData);
                } else {
                    setHeadquartersData(rawData);
                }
            } catch (error) {
                console.error("Error al obtener los datos de los headquarters:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchHeadquarters();
    }, []);

    return { headquartersData, isLoading };
};