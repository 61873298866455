import React from 'react';
import { DropdownField, CalendarRangeField } from '../../../admin/common/input/inputField';
import { CLASS_STATUS } from '../../../../core/data/options/options';
import { getDefaultWeekRange } from '../../../../core/utils/dateUtils';

interface FiltersProps {
    status: string;
    setStatus: (value: string) => void;
    dateRange: [Date, Date];
    setDateRange: (value: [Date, Date]) => void;
}

const Filters: React.FC<FiltersProps> = ({
    status,
    setStatus,
    dateRange,
    setDateRange,
}) => (
    <div className="search-filters card p-3 mb-4 shadow-sm">
        <div className="d-flex justify-content-between align-items-center mb-1">
            <h6 className="text-dark">Filtros de búsqueda</h6>
            <button
                className="btn btn-outline-primary btn-sm rounded-3"
                onClick={() => {
                    setDateRange(getDefaultWeekRange());
                    setStatus('');
                }}
                title="Limpiar filtros"
            >
                <i className="fa fa-eraser me-2" />
                Limpiar
            </button>
        </div>
        <div className="d-flex align-items-center gap-3">
            <DropdownField
                label="Estado de la clase"
                required={false}
                placeholder="Selecciona un estado"
                value={status}
                options={CLASS_STATUS}
                onChange={(e) => setStatus(e.target.value)}
            />
            <CalendarRangeField
                label="Selecciona Rango de Fechas"
                value={dateRange}
                onChange={(e) => setDateRange(e.value)}
            />
            {/* <button
        className={`btn ${filterFuture ? 'btn-primary-solid' : 'btn-light'}`}
        onClick={() => setFilterFuture((prev) => !prev)}
      >
        {filterFuture ? 'Mostrar todas las clases' : 'Mostrar solo clases futuras'}
      </button> */}
        </div>
    </div>
);

export default Filters;