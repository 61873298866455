import React from 'react';
import { Link } from 'react-router-dom';
import ImageWithBaseApi from '../../../../core/img/ImageWithBaseApi';
import { ShoppingCartProps } from '../../../../core/Interface/interfaceComponents';

const ShoppingCart: React.FC<ShoppingCartProps> = ({ cart, setCart, removeFromCart, addToCart, removeProductFromCart }) => {
  return (
    <div className="col-xl-8 col-lg-8">
      <h5 className="pay-title">Carrito de Compras</h5>
      {cart.length === 0 ? (
        <div className="empty-cart text-center">
          <i className="fa fa-frown fa-4x mb-2" />
          <p className="lead">¡Tu carrito está vacío!</p>
          <Link to="/" className="btn btn-primary-admin mt-3 mb-3">
            Ver productos
          </Link>
        </div>
      ) : (
        <>
          <div className="row-cols-1 row-cols-md-2 g-3">
            {cart.map((productId) => (
              <div
                className="container card card-shadow shadow-none w-100 mb-3"
                key={productId.id}
              >
                <div className="row card-body justify-content-center">
                  {productId.image && (
                    <div className="p-0 me-sm-3 mb-3 mb-sm-0 image-cart">
                      <ImageWithBaseApi
                        src={productId.image}
                        routeName="packet"
                        alt="User Image"
                      />
                    </div>
                  )}
                  <div className="p-0 mb-0 me-sm-3 mb-3 mb-sm-0 col product-info-cart">
                    <h6 className="mb-3 truncate-text-cart">{productId.title}</h6>
                    <ul className={`booking-details w-100 ${!productId.image ? 'p-0' : ''}`}>
                      <li
                        className="d-flex align-items-center mb-2 truncate-text-cart"
                        style={{ color: '#6c757d', fontSize: '14px' }}
                      >
                        <span className="book-item me-1">Detalles</span>
                        <small className="me-2">: </small>
                        <span
                          className="w-100"
                          style={{ color: '#6c757d', fontSize: '16px' }}
                        >
                          {productId.description.replace(/\|/g, ' • ')}
                        </span>
                      </li>
                      <li
                        className="d-flex align-items-center mb-2 truncate-text-cart"
                        style={{ color: '#6c757d', fontSize: '14px' }}
                      >
                        <span className="book-item me-1">Subtitulo</span>
                        <small className="me-2">: </small>
                        <span
                          className="w-100"
                          style={{ color: '#6c757d', fontSize: '16px' }}
                        >
                          {productId.subtitle}
                        </span>
                      </li>
                      <li
                        className="d-flex align-items-center mb-2 truncate-text-cart"
                        style={{ color: '#6c757d', fontSize: '14px' }}
                      >
                        <span className="book-item me-1">Precio</span>
                        <small className="me-2">: </small> S/{' '}
                        <span
                          className="w-100"
                          style={{ color: '#6c757d', fontSize: '16px' }}
                        >
                          {productId.price}
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div className="p-0 d-flex button-cart flex-sm-column">
                    <div className="buttons-mob d-flex mb-0 mb-sm-2 w-100 justify-content-between">
                      <button
                        className="btn btn-light"
                        onClick={() => removeFromCart(productId.id, setCart)}
                      >
                        <i className="fa fa-minus" />
                      </button>
                      <button className="btn btn-light">{productId.quantity}</button>
                      <button
                        className="btn btn-light me-2 me-sm-0"
                        onClick={() => addToCart(productId.id, setCart)}
                      >
                        <i className="fa fa-plus" />
                      </button>
                    </div>

                    <button
                      className="btn btn-danger w-100"
                      onClick={() => removeProductFromCart(productId.id, setCart)}
                    >
                      <i className="fa fa-trash me-2" />
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ShoppingCart;