import React, { useEffect, useState } from 'react';
import { formatDateToFull, capitalizeFirstLetter } from '../../../../core/utils/utils'
import * as Icon from 'react-feather';
import BaseModal from './baseModal';
import { ShowDetailClassModalProps } from '../../../../core/Interface/modalInterface';

const ShowDetailClassModal: React.FC<ShowDetailClassModalProps> = ({ selectedClassData }) => {
    const [show, setShow] = useState<boolean>();
    const [classData, setClassData] = useState<any>();

    useEffect(() => {
        if (selectedClassData) {
            setClassData(selectedClassData);
            setShow(true);
        }
    }, [selectedClassData]);

    const handleClose = () => {
        setShow(false);
    };

    return (
        <BaseModal
            show={show || false}
            title="Detalle de Clase"
            onClose={handleClose}
            showButtons={false}
        >
            <div className="modal-body pt-0">
                <div className="text-center col-md-12 m-auto">
                    <Icon.CheckCircle size={45} color='#28a745' className="text-success fs-1" />
                    <div className="mt-4">
                        <h4>{classData?.className}</h4>
                        {classData?.date && (
                            <table className="table table-bordered mt-2">
                                <tbody>
                                    <tr>
                                        <th>Fecha</th>
                                        <td>{capitalizeFirstLetter(formatDateToFull(classData.date))}</td>
                                    </tr>
                                    <tr>
                                        <th>Entrenador</th>
                                        <td>{classData.trainerName || 'No asignado'}</td>
                                    </tr>
                                    <tr>
                                        <th>Horario</th>
                                        <td>{classData.hour}</td>
                                    </tr>
                                    <tr>
                                        <th>Cupos Reservados</th>
                                        <td>{classData?.reservationCount || 0} de {classData?.totalCapacity || 0}</td>
                                    </tr>
                                    <tr>
                                        <th>Asistencia</th>
                                        <td>{classData?.attendanceCount || 0} de {classData?.totalCapacity || 0}</td>
                                    </tr>
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4">
                    <button
                        type="button"
                        className="btn w-sm btn-secondary"
                        data-bs-dismiss="modal"
                        onClick={handleClose}
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </BaseModal>
    );
}

export default ShowDetailClassModal;