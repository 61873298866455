import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { InputField } from "../../../admin/common/input/inputField";
import { getErrorForField } from "../../../../core/utils/utils";
import { ForgotPasswordModalProps } from "../../../../core/Interface/modalInterface";

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = ({
  modalId = "forgot-modal",
  formData,
  resetForm,
  handleChange,
  forgotPassword,
  loading,
  serverError,
}) => {

  useEffect(() => {
    const modalElement = document.getElementById(modalId);

    const handleModalClose = () => {
      resetForm();
    };

    if (modalElement) {
      modalElement.addEventListener("hide.bs.modal", handleModalClose);
    }

    return () => {
      if (modalElement) {
        modalElement.removeEventListener("hide.bs.modal", handleModalClose);
      }
    };
  }, [modalId, resetForm]);


  return (
    <div
      className="modal fade"
      id={modalId}
      tabIndex={-1}
      data-bs-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <button
            type="button"
            className="d-none"
            data-bs-toggle="modal"
            data-bs-target="#otp-email-modal"
          ></button>
          <div className="modal-header d-flex align-items-center justify-content-end pb-0 border-0">
            <Link to="#" data-bs-dismiss="modal" aria-label="Cerrar">
              <i className="ti ti-circle-x-filled fs-20" />
            </Link>
          </div>
          <div className="modal-body p-4 pt-0">
            <form onSubmit={forgotPassword}>
              <div className="text-center mb-3">
                <h3 className="mb-2">¿Olvidaste tu contraseña?</h3>
                <p>
                  Ingresa tu correo electrónico, te enviaremos un código para
                  restablecer tu contraseña.
                </p>
              </div>
              <InputField
                label="Correo electrónico"
                name="email"
                value={formData?.email || ""}
                onChange={handleChange}
                error={getErrorForField("email-recover", serverError || [])}
              />
              <div className="mb-3">
                {serverError?.find((error) => error.field === "server") && (
                  <div className="mb-3 m-0">
                    <small className="text-danger">
                      {serverError?.find((error) => error.field === "server")?.message}
                    </small>
                  </div>
                )}

                <button
                  type="submit"
                  className="btn btn-lg btn-linear-primary w-100"
                >
                  {loading ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="ms-2">Enviando...</span>
                    </>
                  ) : (
                    "Enviar"
                  )}
                </button>
              </div>
              <div className="d-flex justify-content-center">
                <p>
                  ¿Recuperaste tu contraseña?{" "}
                  <Link
                    to="#"
                    className="text-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#login-modal"
                  >
                    Iniciar sesión
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordModal;