import React, { useState, useEffect } from 'react';
import { apiRepository } from '../../../../api/apiRepository'; // Asegúrate de tener el tipo Headquarters
import { Headquarters } from '../../../../core/Interface/repositoryInterfaces';
import { validateHeadquarters, ValidationError } from '../../../../core/form/validationForm';
import { InputField } from '../input/inputField';
import { handleChange } from '../../../../core/utils/utils';
import { getErrorMessage } from '../../../../core/utils/errorUtils';
import { handleFormSubmit } from '../../../../core/services/formService';
import BaseModal from './baseModal';

export interface UpdateHeadquartersModalProps {
    selectedHeadquarters: Headquarters;
    onUpdateSuccess?: () => void;
}

const HeadquartersModal: React.FC<UpdateHeadquartersModalProps> = ({ selectedHeadquarters, onUpdateSuccess }) => {
    const [id, setId] = useState<number>(selectedHeadquarters ? selectedHeadquarters.id : -1);
    const [headquartersData, setHeadquartersData] = useState<Headquarters>([]);
    const [errors, setErrors] = useState<ValidationError[]>([]);
    const [show, setShow] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
    ) => {
        handleChange(e, setHeadquartersData);
    };

    const handleClose = () => {
        setShow(false);
        setErrors([]);
        setHeadquartersData([]);
        setId(-1);
    };

    useEffect(() => {
        if (selectedHeadquarters) {
            setHeadquartersData({
                name: selectedHeadquarters.name,
                address: selectedHeadquarters.address,
                phone: selectedHeadquarters.phone,
            });
            setId(selectedHeadquarters.id);
            setShow(true);
        }
    }, [selectedHeadquarters]);

    const handleConfirmCreate = async (event: React.FormEvent) => {
        event.preventDefault();
        setLoading(true);

        const errors = await handleFormSubmit(
            id,
            headquartersData,
            validateHeadquarters,
            {
                create: apiRepository.createHeadquarters,
                update: apiRepository.updateHeadquarters,
            },
            () => {
                if (onUpdateSuccess) onUpdateSuccess();
                handleClose();
            },
            undefined,
            "Ocurrió un error al intentar guardar el establecimiento. Inténtalo de nuevo."
        );

        if (errors) {
            setErrors(errors);
        }

        setLoading(false);
    };

    return (
        <BaseModal
            show={show}
            title="Establecimiento"
            onClose={handleClose}
            onConfirm={handleConfirmCreate}
            loading={loading}
        >
            <form onSubmit={handleConfirmCreate}>
                <InputField
                    label="Nombre"
                    name="name"
                    value={headquartersData.name || ''}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, "name")}
                />
                <InputField
                    label="Dirección"
                    name="address"
                    value={headquartersData.address || ''}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, "address")}
                />
                <InputField
                    label="Teléfono"
                    name="phone"
                    value={headquartersData.phone || ''}
                    onChange={handleInputChange}
                    error={getErrorMessage(errors, "phone")}
                />
                {errors.find(error => error.field === 'server') && (
                    <div className="alert alert-danger" role="alert">
                        {getErrorMessage(errors, "server")}
                    </div>
                )}
            </form>
        </BaseModal>
    );
};

export default HeadquartersModal;