import React, { useEffect } from 'react';
import PagesRoutes from './pages.routes';
import { useLocation } from 'react-router-dom';
import DemoUserHeader from './common/demo-user-header';
import BookingFooter from './common/booking-footer';
import MainFooter from '../home/footer/main-footer';
import HomeHeader from '../home/header/main-header';
import AuthModals from '../home/header/authModals';

const Pages = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      {location?.pathname == '/pages/booking'?
       (
        <DemoUserHeader />
      ) : (location?.pathname == '/pages/coming-soon' ||
      location?.pathname == '/pages/installer'  ||
      location?.pathname == '/pages/installer-new' ||
      location?.pathname == '/pages/session-expired' ||
      location?.pathname == '/pages/maintenance') ? (
        <></>
      ):<HomeHeader type={2}/>}
      <PagesRoutes />
      {location?.pathname == '/pages/coming-soon' ||
      location?.pathname == '/pages/installer'  ||
      location?.pathname == '/pages/installer-new' ||
      location?.pathname == '/pages/session-expired' ||
      location?.pathname == '/pages/maintenance' ?

      (
        <></>
      ) :location?.pathname == '/pages/booking' ?
      (
        <BookingFooter/>
      )
      : (
      <MainFooter />


      )}
      <AuthModals/>
    </>
  );
};

export default Pages;
