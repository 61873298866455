import moment from 'moment-timezone';

export const groupSchedulesByClassAndDay = (schedules: any[]) => {
    return schedules.reduce((acc, schedule) => {
        const { id, classId, date, title, classTypeDescription, hourStart, hourEnd, classDescription, quantity, reservation, trainerName, isReformer } = schedule;

        const day = moment.tz(date, 'YYYY-MM-DD', 'America/Lima');
        const dayName = day.format('dddd');
        const dayNumber = day.date();
        const dayWithDate = `${dayName} ${dayNumber}`;

        if (!acc[classId]) acc[classId] = {};
        if (!acc[classId][dayWithDate]) acc[classId][dayWithDate] = [];

        acc[classId][dayWithDate].push({
            id,
            title,
            classTypeDescription,
            hourStart,
            hourEnd,
            isReformer,
            date,
            classDescription,
            reservation,
            trainerName,
            quantity,
        });

        return acc;
    }, {});
};


export const sortGroupedSchedules = (groupedSchedules: Record<string, Record<string, any[]>>) => {
    return Object.fromEntries(
        Object.entries(groupedSchedules).map(([classId, days]) => {
            const sortedDays = Object.entries(days as Record<string, any[]>)
                .sort(([dayA, dayAData], [dayB, dayBData]) => {
                    const dateA = new Date(dayAData[0].date);
                    const dateB = new Date(dayBData[0].date);
                    return dateA.getTime() - dateB.getTime();
                });
            return [classId, Object.fromEntries(sortedDays)];
        })
    );
};